import { Trans } from 'next-i18next';
import React, { useEffect } from 'react';
import { useUser } from '@web/shop-logic';
import Alert from '@/components/atoms/Alert';
import { Button } from '@/components/atoms/Button';
import ModalContext from '@/components/modal/ModalContext';
import useContextSelector from '@/hooks/useContextSelector';

interface RegistrationSuccessBoxProps {
  password: string;
}

const RegistrationSuccessBox: React.FC<RegistrationSuccessBoxProps> = ({ password }) => {
  const { user } = useUser();
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);

  useEffect(() => {
    if ('PasswordCredential' in window && 'credentials' in navigator && user.user?.accountCode) {
      // @ts-expect-error Missing PasswordCredential types
      const credentials = new PasswordCredential({ id: user.user?.accountCode, password });
      navigator.credentials.store(credentials);
    }
  }, [user.user?.accountCode]);

  return (
    <div>
      <Alert variant="confirmed" className="mb-3">
        <Trans i18nKey="registration.success.alert" />
      </Alert>
      <p className="mb-4">
        <Trans
          i18nKey="registration.success.paragraph"
          values={{ accountNumber: user?.user?.accountCode }}
          components={{ bold: <strong /> }}
        />
      </p>
      <Button className="w-full" onClick={hideAllModals}>
        <Trans i18nKey="button.next" />
      </Button>
    </div>
  );
};

export default RegistrationSuccessBox;
