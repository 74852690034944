import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { useTariffs } from '@web/shop-logic';
import FormCheckbox from '@/components/atoms/FormCheckbox';
import FormSelect from '@/components/atoms/FormSelect';
import TermsAndConditions from '@/components/i18n/TermsAndConditions';
import currencyOptions from '@/components/registration/currencyOptions';

interface SettingsStepProps {
  hidden: boolean;
  /**
   * Uživatel s otevřenou jízdenkou má ve formuláři méně polí
   */
  userIsOpenTicket: boolean;
}

const SettingsStep: React.FC<SettingsStepProps> = ({ hidden, userIsOpenTicket }) => {
  const { t } = useTranslation();
  const { data = [] } = useTariffs();

  const tariffOptions = data?.map(({ key }) => ({
    value: key,
    label: t(`tariff.${key}`),
  }));

  return (
    <div className={classNames(hidden && 'hidden')}>
      {!userIsOpenTicket && (
        <>
          <h3 className="h3 mb-2 -mt-1">
            <Trans i18nKey="registration.title.settings" />
          </h3>

          <div className="w-full mb-2">
            <FormSelect
              name="defaultTariff"
              className="w-full"
              label={t('icons.popover.user')}
              options={tariffOptions}
            />
          </div>

          <div className="w-full mb-2">
            <FormSelect
              name="currency"
              className="w-full"
              label={t('registration.currency')}
              options={currencyOptions}
            />
          </div>
        </>
      )}

      <div className="w-full">
        <FormCheckbox
          name="notifications.newsletter"
          label={t('registration.checkbox.sendEmails')}
        />
      </div>

      <div className="w-full mb-2">
        <FormCheckbox name="agreeWithTerms" label={<TermsAndConditions />} />
      </div>
    </div>
  );
};

export default SettingsStep;
