import { Trans } from 'next-i18next';
import Link from 'next/link';
import React, { useMemo } from 'react';
import URLS from '@/constants/url';
import { Warning } from '@/icons';
import { ComponentExclusionsVisibility, type ExclusionEntity } from '@/models/types';
import {
  formatExclusions,
  isExclusionNow,
  isExclusionVisible,
  sortExclusions,
} from '@/utils/exclusions/utils';

interface MenuExclusionsProps {
  exclusionsData: ExclusionEntity[];
}

interface MenuExclusionsItemProps {
  title: string;
  visibilities: ComponentExclusionsVisibility[];
  slug: string;
}

const sortMultipleExclusions = (values: ExclusionEntity[]) => {
  const happening: ExclusionEntity[] = [];
  const notHappening: ExclusionEntity[] = [];

  values.forEach((value) => {
    if (isExclusionNow(value.visibility)) {
      happening.push(value);
    } else {
      notHappening.push(value);
    }
  });

  return [
    ...happening.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()),
    ...sortExclusions(notHappening),
  ];
};

const ExclusionItem: React.FC<MenuExclusionsItemProps> = ({ title, visibilities, slug }) => {
  const formatted = formatExclusions(title, visibilities);

  return (
    <Link href={`${URLS.EXCLUSIONS.INDEX}/${slug}`}>
      <a className="flex items-start gap-1 py-1 text-secondary-redwarn hover:underline">
        <Warning className="sm:hidden w-3 h-3 flex-shrink-0 fill-current" />

        <span className="inline-flex flex-wrap items-baseline">
          <span className="mr-0.5">{formatted.title}</span>
          {formatted.visibilities.map((visibility, index) => (
            <React.Fragment key={visibility}>
              {index > 0 && <span className="mr-1">,</span>}
              <span className="whitespace-nowrap">
                {!formatted.includesEndDate && (
                  <>
                    <Trans
                      i18nKey="exclusions.visibleFromOnly"
                      values={{ visibility: formatted.title }}
                    />
                    &nbsp;
                  </>
                )}
                {visibility}
              </span>
            </React.Fragment>
          ))}
        </span>
      </a>
    </Link>
  );
};

const MenuExclusions: React.FC<MenuExclusionsProps> = ({ exclusionsData }) => {
  const sliceExclusionsData = useMemo(
    () =>
      sortMultipleExclusions(
        exclusionsData.filter(({ visibility }) => isExclusionVisible(visibility)),
      ).slice(0, 3),
    [exclusionsData],
  );

  if (!exclusionsData?.length) {
    return null;
  }

  return (
    <div className="flex flex-col text-14 sm:whitespace-normal">
      <h4 className="font-bold py-1">
        <Trans i18nKey="exclusions.menuHeader" />
      </h4>
      {sliceExclusionsData.map((exclusion) => (
        <ExclusionItem key={exclusion.title} visibilities={exclusion.visibility} {...exclusion} />
      ))}
      <Link href={URLS.EXCLUSIONS.INDEX}>
        <a className="py-1 text-neutral-gray hover:underline">
          <Trans i18nKey="show.more" />
        </a>
      </Link>
    </div>
  );
};

export default MenuExclusions;
