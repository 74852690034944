import { i18n } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { ignoreReservationPathnames } from '@/../config/seo';
import SeoHead from '@/components/templates/SeoHead';
import env from '@/constants/env';
import { Enum_Componentpageseo_Robots, ShopSeo } from '@/models/types';
import { isQueryValid } from '@/utils/routerUtils';

interface HtmlHeadProps {
  generalSeo: ShopSeo['seo'];
  canonical: string;
}

const HtmlHead: React.FC<HtmlHeadProps> = ({ generalSeo, canonical }) => {
  const { pathname, query } = useRouter();
  const { description, ogDescription, ogImage, robots, ogTitle } = generalSeo || {};

  const isInReservationProcess =
    ignoreReservationPathnames.includes(pathname) || isQueryValid(query, 'search');

  /**
   * Použité hodnoty podle priority:
   *  1. Strapi: Shop-seo (obecné nastavení pro všechny stránky)
   *  2. Fallback překlady
   *
   *  Seo pro kolekce ze strapi (blog/pages...) se nastavuje samostatně v jejich route
   *
   */
  const mergedSeo = {
    canonical,
    title: ogTitle || i18n.t('page.title'),
    description: description || i18n.t('page.og.description'),
    ogDescription: ogDescription || i18n.t('page.og.description'),
    imageUrl: ogImage?.url ? `${env.STRAPI_URL}${ogImage?.url}` : i18n.t('page.og.image'),
    robots:
      (isInReservationProcess && Enum_Componentpageseo_Robots.NoindexNofollow) ||
      robots ||
      Enum_Componentpageseo_Robots.IndexFollow,
  };

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://brn-web-strapi.sa.cz" />
        <link rel="preconnect" href="https://brn-ybus-pubapi.sa.cz" />
        {/* Favicon */}
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" crossOrigin="use-credentials" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        {/* TODO update when dark mode is implemented */}
        <meta
          name="theme-color"
          content="#FABB00"
          media="(prefers-color-scheme: dark)"
          key="theme-color-dark"
        />
        <meta
          name="theme-color"
          content="#FABB00"
          media="(prefers-color-scheme: light)"
          key="theme-color-light"
        />
        <meta name="theme-color" content="#FABB00" key="theme-color-fallback" />
        {/* GA site ownership verification:https://support.google.com/webmasters/answer/9008080 */}
        <meta
          name="google-site-verification"
          content="MmhACR7vhyzX2eLF0b7qWLQJgLZN3EY8NDSULyOfxVU"
        />

        {/* Preload */}
        <link
          rel="preload"
          href="/fonts/Inter-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Inter-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>

      {/* Title + SEO */}
      <SeoHead {...mergedSeo} />
    </>
  );
};

export default HtmlHead;
