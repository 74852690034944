import { useTranslation } from 'next-i18next';
import * as yup from 'yup';

const useFieldValidation = () => {
  const { t } = useTranslation();

  const yupFieldEnum = (Enum: Record<string, string>) =>
    yup
      .mixed<keyof typeof Enum>()
      .oneOf(Object.values(Enum), t('validation.oneOfOptions'))
      .nullable()
      .required(t('validation.required'));

  // TODO - Remove yupField v1 and refactor to optional/required

  const yupField = {
    checkbox: yup.bool().oneOf([true], t('validation.required')),
    string: yup.string().nullable().required(t('validation.required')),
    date: yup.date().required(t('validation.required')),
    password: yup
      .string()
      .nullable()
      .min(5, t('validation.minLength', { minLength: 5 }))
      .required(t('validation.required')),
    accountCode: yup
      .number()
      .typeError(t('validation.number'))
      .nullable()
      .required(t('validation.required')),
    email: yup
      .string()
      .email(t('validation.email.invalidFormat'))
      .nullable()
      .required(t('validation.required')),
    positiveNumber: yup
      .number()
      .typeError(t('validation.number'))
      .nullable()
      .positive(t('validation.minNumber', { minNumber: 0 })),
    autoComplete: yup.mixed().nullable().required(t('validation.required')),
    autoCompleteNotReq: yup.mixed().nullable(),
  };

  const yupFieldV2 = {
    string: {
      required: yup.string().nullable().required(t('validation.required')),
      optional: yup.string().nullable(),
    },
    date: {
      required: yup
        .date()
        .required(t('validation.required'))
        .typeError(t('validation.date.invalidDate')),
      optional: yup.date().nullable().typeError(t('validation.date.invalidDate')),
    },
    email: {
      required: yup
        .string()
        .email(t('validation.email.invalidFormat'))
        .nullable()
        .required(t('validation.required')),
      optional: yup.string().email(t('validation.email.invalidFormat')).nullable(),
    },
    checkbox: {
      required: yup.bool().oneOf([true], t('validation.required')),
      optional: yup.bool().nullable(),
    },
    files: {
      required: yup
        .mixed()
        .test(
          'non-empty-array',
          t('validation.required'),
          (value) => Array.isArray(value) && !!value.length,
        ),
      optional: yup.mixed(),
    },
    select: {
      required: (options) =>
        yup.string().oneOf(options).required(t('validation.required')).nullable(),
      optional: yup.mixed(),
    },
  };

  return { yupField, yupFieldV2, yupFieldEnum };
};

export default useFieldValidation;
