import { Context, useContextSelector as useContextSelectorOrig } from 'use-context-selector';

const useContextSelector = <Value, Selected>(
  context: Context<Value>,
  selector: (value: Value) => Selected,
  defaultValue?: Selected,
) => {
  try {
    return useContextSelectorOrig(context, selector) ?? defaultValue;
  } catch {
    return defaultValue;
  }
};

export default useContextSelector;
