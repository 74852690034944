import { Trans } from 'next-i18next';
import * as React from 'react';
import { Button } from '@/components/atoms/Button';
import { useIdentity } from '@/components/identity/IdentityProvider';

const IdentityMobile = () => {
  const { session, loading, register, handleSignIn, handleSignOut } = useIdentity();

  if (loading) return null;

  return (
    <div className="p-2 bg-primary-yellow">
      <span className="text-h1 font-bold block">
        <Trans i18nKey="settings.identity.mobileAccount" />
      </span>

      {session ? (
        <div className="flex flex-col p-2 items-center gap-1">
          {/* Todo: temporary sing out */}
          <Button onClick={handleSignOut} disabled={loading}>
            Odhlášení z ID
          </Button>

          <span>{session.user.name}</span>
        </div>
      ) : (
        <div className="flex flex-col p-2 my-2.5 bg-white rounded-sm break-words whitespace-pre-wrap">
          <span className="h2 font-bold block">
            <Trans i18nKey="settings.identity.mobileNoRegiojetId" />
          </span>

          <span className="text-14 mt-1.5">
            <Trans i18nKey="settings.identity.mobileNoRegiojetId" />
          </span>

          <Button onClick={register} disabled={loading} className="mt-2">
            <Trans i18nKey="settings.identity.mobileCreateRegiojetId" />
          </Button>

          <div className="flex gap-1 items-center mt-2 text-14">
            <span>
              <Trans i18nKey="settings.identity.mobileHaveAccount" />
            </span>

            <button
              onClick={handleSignIn}
              disabled={loading}
              role="button"
              className="text-primary-blue underline"
            >
              <Trans i18nKey="settings.identity.mobileSignIn" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IdentityMobile;
