import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { ResponseStateContext, useSetResponseState } from '@web/shop-logic';
import Modal from '@/components/modal/Modal';
import ModalContext from '@/components/modal/ModalContext';
import modalReducer, { initialState, Types } from '@/components/modal/modalReducer';
import { DestroyFn, HideAllFn, HideFn, ShowFn, UpdateFn } from '@/components/modal/modalTypes';
import useContextSelector from '@/hooks/useContextSelector';

/**
 * Context provider for UI modals
 * Use MaterialUI Modal component
 * Always show only 1 modal in screen (currently showing modal rewrites the previsous one, but props (e.g. onClose) are merged)
 * Based on: https://github.com/Quernest/mui-modal-provider
 * Changes:
 *   - Changed case (Types.SHOW) in reducer
 *   - Replaced uid() by Math.random()
 *   - Removed destroyModalsByRootId() feature
 *   - Removed destroyOnClose + onExited feature
 * Example:
 *  const { showModal } = useModal();
 *
 *  showModal(<LoginBox />);
 *
 *  showModal(<LoginBox />, {
 *    size: 'big',
 *    onClose: () => {
 *      console.log('CLOSE');
 *    },
 *  });
 */
const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  const { asPath } = useRouter();
  const setState = useSetResponseState(13);
  const categoryState = useContextSelector(ResponseStateContext, (c) => c.state[13]);

  /**
   * Refreshing page when 401 error is invoked
   */
  useEffect(() => {
    if (categoryState?.error?.message) {
      setState('', false);
    }
  }, [categoryState]);

  const update = React.useCallback<UpdateFn>(
    (id, props) => dispatch({ type: Types.UPDATE, payload: { id, props } }),
    [dispatch],
  );

  const hide = React.useCallback<HideFn>(
    (id) => dispatch({ type: Types.HIDE, payload: { id } }),
    [dispatch],
  );

  const hideAll = React.useCallback<HideAllFn>(() => dispatch({ type: Types.HIDEALL }), [dispatch]);

  const destroy = React.useCallback<DestroyFn>(
    (id) => dispatch({ type: Types.DESTROY, payload: { id } }),
    [dispatch],
  );

  const show = React.useCallback<ShowFn>(
    (component, props, options) => {
      let id = Math.random().toString().slice(2, 10);

      if (options && options.rootId) {
        id = `${options.rootId}.${id}`;
      }

      dispatch({ type: Types.SHOW, payload: { id, component, props, options } });

      return {
        id,
        hide: () => hide(id),
        destroy: () => destroy(id),
        update: (newProps) => update(id, newProps),
      };
    },
    [dispatch, hide, destroy, update],
  );

  const renderState = useCallback(
    () =>
      Object.keys(state).map((id) => {
        const { component: Component, props } = state[id];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClose = (...args: never[]) => {
          hide(id);
          props?.onClose?.(...args);
        };

        /**
         * onClose is called on hideAll. hide(id) is called to clear modal state.
         */
        if (props.onClose && props.open === false) {
          props.onClose();
          hide(id);
        }

        return (
          <Modal {...props} key={id} onClose={handleClose}>
            {!(props.unmountOnClose && !props.open) && Component}
          </Modal>
        );
      }),
    [state],
  );

  useEffect(() => hideAll(), [asPath]);

  return (
    <ModalContext.Provider
      value={{
        state,
        updateModal: update,
        hideModal: hide,
        hideAllModals: hideAll,
        destroyModal: destroy,
        showModal: show,
      }}
    >
      {children}
      {renderState()}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
