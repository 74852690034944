import { Trans } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useUser } from '@web/shop-logic';
import { Button, ButtonLink } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import Price from '@/components/atoms/Price';
import useModal from '@/components/modal/useModal';
import RegistrationBox from '@/components/registration/RegistrationBox';
import URLS from '@/constants/url';
import { Power, Settings, Receipt, Money1, RocketSm } from '@/icons';

const iconClasses = 'w-2.5 h-2.5 fill-current';

interface UserAccountPopperProps {
  accountTitleLong: string;
  creditPrice: boolean;
  accountCode: string;
  employeeNumber: string;
}

const UserAccountPopper: React.FC<UserAccountPopperProps> = ({
  accountTitleLong,
  creditPrice,
  accountCode,
  employeeNumber,
}) => {
  const { push } = useRouter();
  const { showModal } = useModal();
  const { user } = useUser();

  const openRegisterModal = () => {
    // Trigger "click away" to close Popper
    document.body.click();
    showModal(<RegistrationBox />, { removePadding: true });
  };

  return (
    <div className="p-2.5 min-w-8">
      {/* Jméno uživatele */}
      <div className="font-bold">
        {!employeeNumber ? (
          <Trans i18nKey={creditPrice ? 'creditAccountInfo.title' : 'tabs.open'} />
        ) : (
          <Trans i18nKey={creditPrice ? 'account.employeeAccountInfo.title' : 'tabs.open'} />
        )}
      </div>

      {/* Otevřená/Kreditová jízdenka + číslo jízdenky */}
      <div className="text-14 mb-1.5">
        {!!accountTitleLong && <p>{accountTitleLong}</p>}
        <div className="font-bold">{accountCode}</div>
      </div>

      {creditPrice && (
        /* Kreditová jízdenka */
        <>
          <div className="mb-1.5 border-b border-neutral-graylight3" />

          {/* LINK: Dobití kreditu */}
          <div className="flex justify-between items-center text-14">
            <div className="flex text-14 flex-wrap mr-3">
              <span className="mr-0.5 flex items-center gap-1 text-14">
                <Money1 className={iconClasses} />
                <Trans i18nKey="header.title.credit" />
              </span>
              <span>
                <Price price={user.user?.credit} />
              </span>
            </div>

            <ButtonLink href={URLS.CREDIT}>
              <Trans i18nKey="payments.chargeAccount.short" />
            </ButtonLink>
          </div>

          {/* LINK: Historie plateb */}
          <Link href={URLS.PAYMENTS}>
            <a className="flex gap-1 mb-1.5 text-14">
              <Receipt className={iconClasses} />
              <Trans i18nKey="tickets.button.paymentHistory.mobile" />
            </a>
          </Link>
        </>
      )}

      {/* LINK: Nastavení účtu pouze pro Kreditovou jízdenku */}
      {user.user?.creditPrice && (
        <Link href={URLS.ACCOUNT}>
          <a className="flex gap-1 mb-1.5 text-14">
            <Settings className={iconClasses} />
            <Trans i18nKey="account.title" />
          </a>
        </Link>
      )}

      {/* LINK: Odhlásit se */}
      <ClickableDiv
        className="flex gap-1 text-14"
        onClick={async () => push(URLS.LOGOUT)}
        data-id="header-user-logout-button"
      >
        <Power className={iconClasses} />
        <Trans i18nKey="account.button.logout" />
      </ClickableDiv>

      {/* Otevřená jízdenka */}
      {!creditPrice && (
        <div className="w-60 grid grid-cols-4 gap-2 items-center bg-primary-yellowlight3 -mx-2.5 -my-2.5 mt-2.5 rounded-b-sm p-2.5">
          <RocketSm className="w-full" />
          <p className="col-span-3 text-14 font-bold">
            <Trans i18nKey="loginBox.registerColumn.paragraph" />
          </p>
          <Button variant="primary" className="col-span-4" onClick={openRegisterModal}>
            <Trans i18nKey="loginBox.registerColumn.button" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserAccountPopper;
