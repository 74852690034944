module.exports = {
  SM: {
    MIN: 0,
    MAX: 1024,
  },
  LG: {
    MIN: 1025,
    MAX: 1920,
  },
  FULLHD: {
    MIN: 1921,
    MAX: 4000,
  },
};
