import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import StrapiImage from '@/components/content/StrapiImage';
import { UploadFile } from '@/models/types';

interface LineMapProps {
  image: UploadFile;
  title: string;
  url: string;
}
const LineMap: React.FC<LineMapProps> = ({ image, title, url }) => {
  const { t } = useTranslation();

  if (!image || !title || !url) return null;

  return (
    <Link href={url}>
      {/* Padding-top podle položky menu v `SubmenuColumn.tsx` */}
      <a className="pt-1 hover:underline">
        <h3 className="lg:text-14 sm:font-normal lg:mb-2">{title}</h3>
        {image && (
          <StrapiImage
            className="sm:hidden w-full rounded-sm h-auto"
            image={image}
            alt={t('"submenu.line.map.title"')}
          />
        )}
      </a>
    </Link>
  );
};
export default LineMap;
