import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import { Email1, Phone } from '@/icons';
import { ComponentShopMenuContact } from '@/models/types';

const iconLinkClassnames = 'flex items-center gap-0.5 py-1';

interface MenuContactProps {
  data?: ComponentShopMenuContact;
}

const MenuContact: React.FC<MenuContactProps> = ({ data }) => {
  if (!data) return null;

  const { email, phone, about, aboutUrl } = data;
  return (
    <div className="sm:hidden flex flex-col text-14">
      <h3 className="py-1">
        <Trans i18nKey="header.link.contact" />
      </h3>
      {email && (
        <a
          href={`mailto:${email}`}
          className={classNames(iconLinkClassnames, 'hover:text-primary-blue hover:underline')}
        >
          <Email1 className="w-4 fill-current h-4 pr-1" />
          {email}
        </a>
      )}
      {phone && (
        <div className={iconLinkClassnames}>
          <Phone className="w-4 fill-current h-4 pr-1" />
          {phone}
        </div>
      )}
      {about && aboutUrl && (
        <Link href={aboutUrl}>
          <a className="py-1 hover:text-primary-blue hover:underline">{about}</a>
        </Link>
      )}
    </div>
  );
};

export default MenuContact;
