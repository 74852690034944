import React, { useState } from 'react';
import MessagesContext, { CustomMessage } from '@/components/login/MessagesContext';

const MessagesProvider: React.FC = ({ children }) => {
  const [isMessageAnimated, setIsMessageAnimated] = useState(false);
  const [customMessages, setCustomMessages] = useState<CustomMessage[]>([]);

  const pushToMessages = (message: CustomMessage) => {
    setCustomMessages((prev) => [...prev, message]);
  };

  const clearMessages = () => {
    setCustomMessages([]);
  };

  return (
    <MessagesContext.Provider
      value={{
        isMessageAnimated,
        setIsMessageAnimated,
        customMessages,
        clearMessages,
        pushToMessages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
