import { ParsedQs, stringify } from 'qs';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { types } from '@web/shop-logic';
import { Tariff } from '@web/shop-logic/dist/hooks/types';
import { ConnectionPayload } from '@web/shop-logic/dist/hooks/useConnection';
import { LocationType } from '@web/shop-logic/dist/hooks/useLocations';
import domains from '@/constants/domains';
import redirects from '@/generated/redirects';
import rewrites from '@/generated/rewrites';

export const getFirstParamValue = <T>(param: T | T[]): T =>
  Array.isArray(param) ? param[0] : param;

export const createParamArray = <T>(param: T | T[]): T[] => {
  if (Array.isArray(param)) return param;
  if (typeof param !== 'undefined') return [param];
  return [];
};

export interface RouteQueriesMap {
  fare: string[];
  search: string[];
  links: string[];
}

const routeQueryMappings: RouteQueriesMap = {
  search: [
    'departureDate',
    'fromLocationId',
    'fromLocationType',
    'toLocationId',
    'toLocationType',
    'tariffs',
  ],
  links: ['fromLocationId', 'fromLocationType', 'toLocationId', 'toLocationType'],
  fare: ['routeId', 'fromStationId', 'toStationId', 'tariffs'],
};

export const isQueryValid = (
  query: ParsedUrlQuery | ParsedQs,
  route: keyof RouteQueriesMap,
): boolean => {
  const queryKeys = routeQueryMappings[route];
  return queryKeys.every((key) => query[key]);
};

export const createBackQuery = ({
  returnDepartureDate,
  fromLocationId,
  fromLocationType,
  toLocationId,
  toLocationType,
  tariffs,
}: ConnectionPayload): ParsedUrlQueryInput => ({
  departureDate: returnDepartureDate?.slice(0, 10),
  fromLocationId: toLocationId,
  fromLocationType: toLocationType,
  toLocationId: fromLocationId,
  toLocationType: fromLocationType,
  tariffs,
  direction: 'back',
});

export const buildQuery = (query: Record<string, unknown>): string =>
  stringify(query, { arrayFormat: 'repeat', serializeDate: (d) => d.toISOString().slice(0, 10) });

export const mapFareQuery = (query: ParsedUrlQuery) => ({
  tariffs: createParamArray(query.tariffs) as Tariff[],
  routeId: getFirstParamValue(query.routeId),
  fromStationId: +getFirstParamValue(query.fromStationId),
  toStationId: +getFirstParamValue(query.toStationId),
});

export const mapConnectionQueryToPayload = (
  query: Record<string, string | string[] | number>,
  fromLocationName = '',
  toLocationName = '',
): Omit<ConnectionPayload, 'id'> => ({
  tariffs: createParamArray(query.tariffs) as Tariff[],
  toLocationType: query.toLocationType as LocationType,
  toLocationId: +getFirstParamValue(query.toLocationId),
  fromLocationType: query.fromLocationType as LocationType,
  departureDate: getFirstParamValue(query.departureDate) as string,
  ...(query.returnDepartureDate
    ? { returnDepartureDate: getFirstParamValue(query.returnDepartureDate) as string }
    : undefined),
  fromLocationId: +getFirstParamValue(query.fromLocationId),
  fromLocationName,
  toLocationName,
});

/**
 * Vytvoří NextJS Router Query ze stringu obsahujícího relativní URL
 * "/?departureDate=2021-07-31&direction=back" => { departureDate: "2021-07-31", direction: "back" }
 */
export const getQueryFromString = (url: string): ParsedUrlQuery => {
  const cleanUrl = url.startsWith('/') ? url.substring(1) : url;

  return [...new URLSearchParams(cleanUrl).entries()].reduce((obj, [k, v]) => {
    if (Array.isArray(obj[k])) {
      obj[k].push(v);
    } else if (obj[k]) {
      obj[k] = [obj[k], v];
    } else {
      obj[k] = v;
    }
    return obj;
  }, {});
};

export const getSearchQueryFromString = (url: string): ParsedUrlQuery => {
  const cleanUrl = url.startsWith('/') ? url.substring(1) : url;

  const values = [...new URLSearchParams(cleanUrl).entries()].reduce((obj, [k, v]) => {
    if (k === 'tariffs') {
      obj[k] = obj[k] ? [...obj[k], v] : [v];
    } else {
      obj[k] = v;
    }
    return obj;
  }, {});

  return {
    tariffs: [types.Tariff.Regular],
    ...values,
  };
};

/** Mění formát Nextového router.pathname na formát objektu výše
 * "reservation/fare/[direction]" => "/reservation/fare"
 * "reservation/fare/there" => "/reservation/fare"
 * "reservation/fare/" => "/reservation/fare"
 * "sk/reservation/fare/there" => "/reservation/fare"
 */
export const getUrlFromPathname = (pathname: string, isDefaultLocale = true): string =>
  `/${pathname
    ?.split('?')[0]
    ?.split('/')
    ?.filter((path) => path)
    ?.slice(isDefaultLocale ? 0 : 1, isDefaultLocale ? 2 : 3)
    ?.join('/')}`;

export const languageToDomains = Object.fromEntries(
  domains.map(({ locale, domain }) => [locale, domain]),
);

export const getRewritedPath = (
  locale: string,
  route: string,
  handleRedirectPath?: boolean,
): string => {
  const isDefaultLocale = locale === 'cs';
  const rewritedPath = isDefaultLocale
    ? rewrites.find((item) => item.destination === route && item.locale === undefined)?.source
    : rewrites
        .find((item) => item.destination === route && item.source.startsWith(`/${locale}/`))
        ?.source.replace(new RegExp(`^\\/${locale}\\/`), '/');

  // TODO:
  // Redirects are not correctly setup
  // ex: { source: '/o-nas/kontakty', destination: '/kontakty', permanent: true } should apply only in cs locale

  if (handleRedirectPath) {
    const redirectPath = redirects.find((item) => item.source === rewritedPath)?.destination;
    return redirectPath || rewritedPath || route;
  }

  return rewritedPath || route;
};
