import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import IconLink from '@/components/header/IconLink';
import MessagesContext from '@/components/login/MessagesContext';
import URLS from '@/constants/url';
import useContextSelector from '@/hooks/useContextSelector';
import { ShoppingBasket } from '@/icons';

interface UnpaidShoppingBasketProps {
  countOfUnpaidTickets: number;
}

const UnpaidShoppingBasket: React.FC<UnpaidShoppingBasketProps> = ({ countOfUnpaidTickets }) => {
  const isMessageAnimated = useContextSelector(MessagesContext, (c) => c.isMessageAnimated);
  const { t } = useTranslation();
  return (
    <IconLink
      href={URLS.RESERVATION.CART}
      aria-label={t('accessibility.basketItemsCount', { count: countOfUnpaidTickets })}
    >
      <div className="relative">
        <ShoppingBasket className="w-3 h-3 fill-current" />
        {!!countOfUnpaidTickets && (
          <div
            className={classNames(
              'rounded-md px-0.5 bg-secondary-green text-9 text-neutral-white absolute left-2 top-[14px]',
              isMessageAnimated && 'transform scale-150 transition ease-in-out duration-500',
            )}
          >
            {countOfUnpaidTickets}
          </div>
        )}
      </div>
      <span className="lg:hidden text-11">
        <Trans i18nKey="basket.title" />
      </span>
    </IconLink>
  );
};

export default UnpaidShoppingBasket;
