import React from 'react';

interface OutsideLinkProps {
  href: string;
}

const ExternalLink: React.FC<OutsideLinkProps> = ({ href, children }) => (
  <a
    className="text-primary-blue hover:text-secondary-bluedark hover:underline"
    href={href}
    aria-describedby="new_window"
    target="_blank"
    rel="noreferrer"
  >
    {children || href}
  </a>
);

export default ExternalLink;
