import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import Tooltip from '@/components/atoms/Tooltip';
import { iconLinkWidthClasses } from '@/components/header/IconLink';
import { Info, ShoppingBasket } from '@/icons';

const EmptyShoppingBasket = () => {
  return (
    <div
      className={classNames(
        'flex items-center gap-0.5 sm:border-b-2 border-transparent mt-px',
        iconLinkWidthClasses,
      )}
    >
      <Tooltip
        title={
          <div className="flex items-center gap-x-1 justify-center">
            <Info className="h-3 w-3 fill-current" />
            <Trans i18nKey="basket.empty" />
          </div>
        }
      >
        <div className="flex flex-col items-center w-full">
          <ShoppingBasket className="w-3 h-3 fill-current" />
          <span className="lg:hidden text-11 mt-px">
            <Trans i18nKey="basket.title" />
          </span>
        </div>
      </Tooltip>
      <div className="sr-only">
        <Trans i18nKey="basket.empty" />
      </div>
    </div>
  );
};

export default EmptyShoppingBasket;
