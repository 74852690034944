import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import FormCheckbox from '@/components/atoms/FormCheckbox';
import FormInput from '@/components/atoms/FormInput';
import FormPhoneInput from '@/components/atoms/FormPhoneInput';

interface PersonalDataStepProps {
  hidden: boolean;
  companyInformation: boolean;
  /**
   * Uživatel s otevřenou jízdenkou má ve formuláři méně polí
   */
  userIsOpenTicket: boolean;
}

const PersonalDataStep: React.FC<PersonalDataStepProps> = ({
  hidden,
  companyInformation,
  userIsOpenTicket,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(hidden && 'hidden')}>
      <h3 className="h3 mb-2 -mt-1">
        <Trans i18nKey="registration.title.contactInformation" />
      </h3>

      <div className="w-full mb-2">
        <FormInput name="firstName" className="w-full" label={t('registration.firstName')} />
      </div>
      <div className="w-full mb-2">
        <FormInput name="surname" className="w-full" label={t('registration.surname')} />
      </div>
      <div className="w-full mb-2">
        <FormInput
          name="email"
          className="w-full"
          label={t('registration.email')}
          inputProps={{ inputMode: 'email' }}
        />
      </div>

      <div className="w-full mb-2">
        <FormPhoneInput
          name="phoneNumber"
          className="w-full"
          label={t('registration.phoneNumber')}
        />
      </div>

      {!userIsOpenTicket && (
        <>
          <div className="w-full mb-2">
            <FormCheckbox name="companyInformation" label={t('registration.companyInformation')} />
          </div>

          <div className={companyInformation ? '' : 'hidden'}>
            <h3 className="h3 mb-2 -mt-1">
              <Trans i18nKey="registration.title.firmInformation" />
            </h3>

            <div className="w-full mb-2">
              <FormInput
                name="company.companyName"
                className="w-full"
                label={t('registration.companyName')}
              />
            </div>

            <div className="w-full mb-2">
              <FormInput
                name="company.address"
                className="w-full"
                label={t('registration.companyAddress')}
              />
            </div>

            <div className="w-full mb-2">
              <FormInput
                name="company.registrationNumber"
                className="w-full"
                label={t('registration.registrationNumber')}
              />
            </div>

            <div className="w-full mb-2">
              <FormInput
                name="company.vatNumber"
                className="w-full"
                label={t('registration.vatNumber')}
              />
            </div>
          </div>
        </>
      )}

      <div className="w-full mb-2">
        <FormInput name="password" password className="w-full" label={t('loginBox.password')} />
      </div>
      <div className="w-full mb-2">
        <FormInput
          name="passwordConfirmed"
          password
          className="w-full"
          label={t('loginBox.passwordConfirmed')}
        />
      </div>
    </div>
  );
};

export default PersonalDataStep;
