import { Trans, useTranslation } from 'next-i18next';
import React from 'react';

const linkProps = {
  target: '_blank',
  className: 'text-13 hover:text-primary-blue underline',
  rel: 'noreferrer',
};

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="agreeWithTerms"
      components={{
        transportConditions: (
          <a href={t('ticket.passengerModal.info.transportRules')} {...linkProps} />
        ),
        personalDataProcessing: (
          <a href={t('ticket.passengerModal.info.conditionsUrl')} {...linkProps} />
        ),
        bold: <strong />,
      }}
    />
  );
};

export default TermsAndConditions;
