import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import classNames from 'classnames';
import ClickableDiv, { ClickableDivProps } from '@/components/atoms/ClickableDiv';

interface IconLinkProps extends React.HTMLProps<HTMLAnchorElement | HTMLDivElement> {
  children: React.ReactNode;
}

export const iconLinkWidthClasses = 'h-full px-1.5 sm:px-0.75';

const IconLink: React.FC<IconLinkProps> = ({ children, href, className, onClick, ...props }) => {
  const isClickableDiv = !href && onClick;

  const { pathname } = useRouter();
  const isActive = pathname === href;
  const linkClassNames = classNames(
    'flex items-center sm:flex-col sm:justify-center hover:text-primary-blue sm:border-b-2 sm:py-[0.20rem] pt-px',
    iconLinkWidthClasses,
    isActive ? 'text-primary-blue border-primary-blue' : 'text-neutral-black border-transparent',
    className,
  );

  if (isClickableDiv) {
    return (
      <ClickableDiv className={linkClassNames} {...(props as ClickableDivProps)}>
        {children}
      </ClickableDiv>
    );
  }

  return href ? (
    <Link href={href}>
      <a className={linkClassNames} {...(props as React.HTMLProps<HTMLAnchorElement>)}>
        {children}
      </a>
    </Link>
  ) : (
    <div className={linkClassNames} {...(props as React.HTMLProps<HTMLDivElement>)}>
      {children}
    </div>
  );
};

export default IconLink;
