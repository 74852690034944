import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import env from '@/constants/env';

const api = axios.create({ baseURL: `${env.STRAPI_URL}/api` });

export const fetchAPI = async <TResponse>(
  path: string,
  options: AxiosRequestConfig = {},
  fallback: TResponse | null = null,
): Promise<TResponse | null> => {
  try {
    const { data } = await api<TResponse>(path, options);
    return data;
  } catch {
    // Kvůli odpovědi typu "Not Found", který by spadl na JSON parse
    return fallback;
  }
};

/**
 * Tahá data z REST API
 * @param endpoint URL obsahu
 * @param locale jazyková verze
 * @returns data anebo {} kvůli možnosti přímo destrukturalizovat vrácený objekt
 */

export const getStrapiSingleType = async <TResponse>(
  path: string,
  locale?: string,
  customPopulate?: any,
  preview?: boolean,
): Promise<TResponse> => {
  if (locale !== 'de-AT') {
    return fetchAPI<TResponse>(path, {
      params: {
        populate: customPopulate ?? 'deep',
        locale,
        ...(preview && { publicationState: 'preview' }),
      },
    });
  }
  const responses = await Promise.allSettled([
    fetchAPI<TResponse>(path, {
      params: {
        populate: customPopulate ?? 'deep',
        locale: 'de-AT',
        ...(preview && { publicationState: 'preview' }),
      },
    }),
    fetchAPI<TResponse>(path, {
      params: {
        populate: customPopulate ?? 'deep',
        locale: 'de',
        ...(preview && { publicationState: 'preview' }),
      },
    }),
  ]);
  return (
    (
      responses.find(
        (res) => res.status === 'fulfilled' && res.value,
      ) as PromiseFulfilledResult<TResponse>
    )?.value ?? ({} as TResponse)
  );
};

export const getStrapiCollection = async <TResponse extends unknown[]>(
  path: string,
  paramsProp: Record<string, unknown> = {},
  fallback: TResponse = [] as TResponse,
) => {
  const params =
    paramsProp.locale === 'de-AT'
      ? {
          ...paramsProp,
          locale: ['de-AT', 'de'],
        }
      : paramsProp;

  return fetchAPI<TResponse>(path, { params }, fallback);
};

export const postQuery = <TResponse, TRequest = unknown>(
  path: string,
  data: TRequest,
): Promise<AxiosResponse<TResponse, TRequest>> =>
  api.post<TResponse, undefined, TRequest>(path, data, {
    validateStatus: (status) => status < 500,
    headers: { 'Content-Type': 'application/json' },
  });
