import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { ChevronDown } from '@/icons';

interface HeaderToggleMenuProps {
  showMenu: boolean;
  setShowMenu: (menuState: boolean) => void;
}

const HeaderToggleMenu: React.FC<HeaderToggleMenuProps> = ({ showMenu, setShowMenu }) => (
  <>
    {/* Icon is not rendered directly, it is going to be rendered when user clicks on the toggle Menu, chevron icon will transform to X  */}
    <ClickableDiv
      onClick={() => {
        setShowMenu(!showMenu);
      }}
      className="relative flex items-center"
    >
      <div
        className={classNames(
          'header-toggle-button flex items-center text-14 sm:hidden',
          'relative rounded-full p-0.75 ',
          showMenu && 'is-active',
        )}
      >
        <ChevronDown className="header-toggle-chevron header-toggle-chevron-down w-3 h-3 transition-transform duration-300" />
        <ChevronDown className="header-toggle-chevron header-toggle-chevron-up absolute m-auto w-3 h-3 transition-transform duration-300" />
      </div>
      <span className="text-14 ml-0.5">
        <Trans i18nKey="topMenu.menu" />
      </span>
    </ClickableDiv>
  </>
);

export default HeaderToggleMenu;
