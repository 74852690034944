import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface FullScreenLoaderProps {
  showLoader?: boolean;
}

/**
 * Full screen loader
 * Examples:
 * Uncontrolled component:
 * {isFoo && <FullScreenLoader>}
 *
 * Controlled component:
 * <FullScreenLoader showLoader={isFoo}>}
 */
const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({ showLoader = true }) => (
  <Backdrop open={showLoader} className="full-screen-loader-backdrop text-blue">
    <CircularProgress className="full-screen-loader-spinner rounded-full" />
  </Backdrop>
);

export default FullScreenLoader;
