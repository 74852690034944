import { useMedia } from 'react-use';
import BREAKPOINTS from '@/breakpoints';

interface MobileWidthProps {
  isMobile: boolean;
}

const mobileQuery = `(max-width: ${BREAKPOINTS.SM.MAX}px)`;

const useMobileWidth = (): MobileWidthProps => {
  const isMobile = useMedia(mobileQuery, false);

  return { isMobile };
};

export default useMobileWidth;
