import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import StrapiImage from '@/components/content/StrapiImage';
import RichParagraph from '@/components/strapi/RichParagraph';
import { ComponentPagePageBanner } from '@/models/types';
import { getComponentFromContent } from '@/utils/dataUtils';
import { MenuDataProps } from '@/utils/pagesUtils';

interface MenuNewsArticlesProps extends Pick<MenuDataProps, 'promotionsData'> {
  title: string;
  url: string;
}

const MenuNewsArticles: React.FC<MenuNewsArticlesProps> = ({ promotionsData, title, url }) => {
  /**
   * Namísto stránek se použijí jen bannery z obsahu stránky
   * Předpokládá se, že první komponenta obsahu je `page.page-banner-row`
   */
  const links = getComponentFromContent<ComponentPagePageBanner[]>(
    promotionsData?.content,
    'banner',
  )?.slice(0, 3);

  if (!links || !url) return null;

  return (
    <div className="lg:grid lg:col-span-2 gap-2 text-14">
      {title && <h4 className="col-span-2 font-bold pt-1">{title}</h4>}
      {links.map(({ id, header, url: generatedUrl, image, secondaryText }) => (
        <Link key={id} href={generatedUrl}>
          <a className="grid-cols-1 flex items-center gap-2 group sm:my-2">
            {image && (
              <div className="sm:hidden w-20 h-12">
                <StrapiImage className="rounded-sm" image={image} alt={header} />
              </div>
            )}
            <div className="w-2/3 hover:text-primary-blue hover:underline">
              {header}
              {secondaryText && (
                <div className="text-secondary-redwarn -my-1 group-hover:text-primary-blue">
                  <RichParagraph text={secondaryText} />
                </div>
              )}
            </div>
          </a>
        </Link>
      ))}
      {url && (
        <Link href={url}>
          <a className="col-span-2 py-1 text-neutral-gray hover:underline">
            <Trans i18nKey="show.more" />
          </a>
        </Link>
      )}
    </div>
  );
};

export default MenuNewsArticles;
