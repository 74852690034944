import { useRouter } from 'next/router';
import React from 'react';
import { Currency } from '@web/shop-logic/dist/hooks/types';

interface CurrencySymbolProps {
  currency: Currency;
}

const CurrencySymbol: React.FC<CurrencySymbolProps> = ({ currency }) => {
  const { locale } = useRouter();

  return (
    <>
      {(0)
        .toLocaleString(locale, {
          style: 'currency',
          currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
        .replace(/\d/g, '')}
    </>
  );
};

export default CurrencySymbol;
