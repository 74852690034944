import SearchRouteLink from '../header/SearchRouteLink';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useEffectOnce, useWindowScroll, useWindowSize } from 'react-use';
import classNames from 'classnames';
import { useHeadersActions, useUser, useUserTickets } from '@web/shop-logic';
import IdentityDesktop from '@/components/account/IdentityDesktop';
import IdentityMobile from '@/components/account/IdentityMobile';
import HorizontalLine from '@/components/atoms/HorizontalLine';
import CurrenciesPopover from '@/components/header/CurrenciesPopover';
import EmptyShoppingBasket from '@/components/header/EmptyShoppingBasket';
import HeaderLinks from '@/components/header/HeaderLinks';
import HeaderMobileNavbarToggler from '@/components/header/HeaderMobileNavbarToggler';
import HeaderRjLogo from '@/components/header/HeaderRjLogo';
import LanguagesCurrenciesPopper, {
  LocalizedPage,
} from '@/components/header/LanguagesCurrenciesPopper';
import LanguagesPopover from '@/components/header/LanguagesPopover';
import UnpaidShoppingBasket from '@/components/header/UnpaidShoppingBasket';
import UserAccountButtons from '@/components/header/UserAccountButtons';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import MessagesContext from '@/components/login/MessagesContext';
import HeaderCommonForSAHolding from '@/components/templates/HeaderCommonForSAHolding';
import HeaderLinksSingleRow from '@/components/templates/HeaderLinksSingleRow';
import HeaderToggleMenu from '@/components/templates/HeaderToggleMenu';
import env from '@/constants/env';
import URLS from '@/constants/url';
import rewrites from '@/generated/rewrites';
import useContext from '@/hooks/useContext';
import useMobileWidth from '@/hooks/useMobileWidth';
import { PageEntity } from '@/models/types';
import { fetchAPI } from '@/utils/fetchUtils';
import { MenuDataProps } from '@/utils/pagesUtils';

interface ShowSubmenuProps {
  show: boolean;
  linkIndex: number;
  prevShow?: boolean;
  prevIndex?: number;
}

const mobileNavigationStyles =
  'flex absolute top-12 left-0 z-60 w-full whitespace-nowrap bg-transparent ' +
  'lg:relative lg:top-0 lg:w-auto lg:min-h-headerBar lg:mr-2 lg:px-0 lg:flex-wrap ' +
  'sm:flex-col sm:bg-white sm:border-primary-yellow sm:shadow-headerLink sm:overflow-auto sm:overscroll-contain';

interface HeaderProps {
  locales: LocalizedPage[];
  menuData: MenuDataProps;
  pageType: 'pages' | 'page-templates';
}

/**
 * Identity feature flag Temporary Utility
 **/
export function isIdentityEnabled(environment, identity) {
  if (identity === 'no') return false;
  if (identity === 'full') return true;
  if (identity === 'test') return environment === 'dev' || environment === 'qa';
  return false;
}

const Header: React.FC<HeaderProps> = ({ locales, menuData, pageType }) => {
  const { isLoggedIn } = useUser();
  const { isMobile } = useMobileWidth();
  const { pathname, query, asPath, domainLocales, route, push } = useRouter();
  const { totalCount, fetchTickets } = useUserTickets('UNPAID');
  const { authorize } = useHeadersActions();
  const [toggle, setToggle] = useState(false);
  const [closePopper, setClosePopper] = useState(false);
  const [redirLoading, setRedirLoading] = useState(false);
  const { height } = useWindowSize();
  const { y } = useWindowScroll();
  const [showSubMenu, setShowSubmenu] = useState<ShowSubmenuProps>({
    show: false,
    linkIndex: 0,
    prevShow: false,
    prevIndex: 0,
  });

  const isIdentity = isIdentityEnabled(env.ENVIRONMENT, menuData.menuTop.identity);

  useEffectOnce(() => {
    if (isLoggedIn) setTimeout(authorize, 0);
  });

  const { customMessages, setIsMessageAnimated } = useContext(MessagesContext);

  const shouldShowCurrencies = !isLoggedIn && pathname === URLS.HOMEPAGE;

  const showTogglerForHeaderLinks =
    Object.values(URLS.RESERVATION).some((url) => pathname.includes(url)) ||
    pathname === URLS.MAP ||
    query?.departureDate;

  const [showMenu, setShowMenu] = useState(!showTogglerForHeaderLinks);

  const handleLang = async ({ locale, id }: LocalizedPage) => {
    // Kdyz je locales undefined, znamena to ze nejsme na Strapi strance

    const pageRedir = locales ? '' : asPath;

    setRedirLoading(true);

    const rewriteDestination = rewrites.find(
      (rw) =>
        rw.destination === route &&
        (locale === 'cs' ? rw.locale === undefined : rw.source.startsWith(`/${locale}/`)),
    )?.source;

    const redirPathname =
      rewriteDestination ||
      (id ? (await fetchAPI<PageEntity>(`/${pageType}/${id}`))?.generatedUrl : pageRedir);

    setClosePopper(true);
    if (redirPathname !== undefined) {
      const domain = domainLocales.find((dl) => dl.defaultLocale === locale)?.domain;
      if (domain) {
        window.location.assign(`https://${domain}${redirPathname}`);
      } else {
        await push(redirPathname || URLS.HOMEPAGE, undefined, { locale });
      }
    }
    setRedirLoading(false);
  };

  useEffect(() => {
    if (customMessages.length) {
      setIsMessageAnimated(true);
      const timer = setTimeout(() => setIsMessageAnimated(false), 1500);
      return () => window.clearTimeout(timer);
    }
  }, [customMessages.length]);

  /* Při přesměrování stáhnout jízdenky a zavřít menu */
  useEffect(() => {
    setTimeout(fetchTickets, 0);
    setToggle(false);
    setShowMenu(!showTogglerForHeaderLinks);
    setShowSubmenu((prevState) => ({ ...prevState, show: false }));
  }, [asPath]);

  return (
    <>
      {showSubMenu.show && <span className="fixed z-35 searchBox-overlay" />}
      <header
        className={classNames(
          'sm:sticky sm:-top-[36px] bg-primary-yellow lg:relative',
          (showSubMenu.show || isMobile) && 'z-40',
        )}
      >
        <HeaderCommonForSAHolding />
        <div
          // position relative due to absolutely positioned nav on mobile
          className="page-layout--desktop sm:relative sm:h-12 lg:min-h-headerBar flex justify-between items-center flex-1"
        >
          {/* LEFT: Logo + Text-link menu */}
          <div className="h-full sm:ml-0.5 flex items-center">
            <HeaderRjLogo />
            {isMobile && (
              <nav
                className={classNames(mobileNavigationStyles, !toggle && 'hidden')}
                style={
                  isMobile
                    ? {
                        // window height - header height - SAHolding header height (can be scrolled away)
                        height: height - 48 - Math.max(0, 36 - y),
                      }
                    : undefined
                }
              >
                {isIdentity && <IdentityMobile />}
                {!isLoggedIn && <UserAccountButtons isHeaderTitle isIdentity={isIdentity} />}
                <SearchRouteLink />
                <HorizontalLine className="border-neutral-gray3" />
                <HeaderLinks
                  menuData={menuData}
                  setShowSubmenu={setShowSubmenu}
                  showSubMenu={showSubMenu}
                />
                <HorizontalLine className="border-neutral-gray3" />
                <LanguagesPopover locales={locales} handleLang={handleLang} />
                {shouldShowCurrencies && <CurrenciesPopover />}
              </nav>
            )}
            {/* Toggle menu (desktop) */}
            {!isMobile && showTogglerForHeaderLinks && (
              <HeaderToggleMenu showMenu={showMenu} setShowMenu={setShowMenu} />
            )}
          </div>
          {/* RIGHT: Icon menu  Desktop */}
          <div className="h-full ml-2 flex justify-center items-center">
            <LanguagesCurrenciesPopper
              closePopper={closePopper}
              handleLang={handleLang}
              locales={locales}
              setClosePopper={setClosePopper}
            />
            <UserAccountButtons isIdentity={isIdentity} />
            {!isMobile && isIdentity && <IdentityDesktop />}
            {totalCount ? (
              <UnpaidShoppingBasket countOfUnpaidTickets={totalCount} />
            ) : (
              <EmptyShoppingBasket />
            )}
            <HeaderMobileNavbarToggler toggle={toggle} setToggle={setToggle} />
          </div>
        </div>
        {/* Desktop Header Links */}
        {showMenu && (
          <HeaderLinksSingleRow
            menuData={menuData}
            setShowSubmenu={setShowSubmenu}
            setClosedPopper={setClosePopper}
          />
        )}
      </header>
      {redirLoading && <FullScreenLoader />}
    </>
  );
};

export default Header;
