import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';

const tokensByLocale = {
  cs: '19n6q83076381p97704q523o225snqn2',
  sk: '19n6q83076381p97704q523o225snqn2',
  en: 'p735856ps7n5043sros295p3r38277on',
};

const blacklist = ['/reservation'];

const DaktelaNightBot = () => {
  const { locale, pathname } = useRouter();

  const initConfig = {
    server: 'https://sav6pbx.daktela.com/',
    accessToken: tokensByLocale[locale],
  };

  useEffect(() => {
    const isBlacklisted = blacklist.some((path) => pathname.startsWith(path));

    const mutateDaktelaEl = () => {
      const daktelaWidgetEl = document.getElementById('daktela-web');
      if (daktelaWidgetEl) {
        daktelaWidgetEl.style.display = isBlacklisted ? 'none' : 'block';
        return true;
      }
      return false;
    };

    if (!mutateDaktelaEl()) {
      const observer = new MutationObserver(() => {
        if (mutateDaktelaEl()) {
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
      });
      return () => observer.disconnect();
    }
  }, [pathname]);

  if (!tokensByLocale[locale]) return null;

  return (
    <Script
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          var daktelaScriptEl = document.createElement("script");
          (function (attrs) { Object.keys(attrs).forEach(function (key)
          { daktelaScriptEl.setAttribute(key, attrs[key]); }
          ); })
          ({
            "src":"${initConfig.server + 'external/web/web.js'}",
            "type":"text/javascript",
            "async":true,
            "charset":"utf-8"
          });
          daktelaScriptEl.onload = function() {
            var daktelaWeb = new daktelaGui();
            daktelaWeb.init(${JSON.stringify(initConfig)})
          };
          document.getElementsByTagName("head")[0].appendChild(daktelaScriptEl);`,
      }}
    />
  );
};

export default DaktelaNightBot;
