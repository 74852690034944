import { useTranslation } from 'next-i18next';
import { AnyObject } from 'yup/es/types';
import * as yup from 'yup';
import Tariff from '@/components/registration/tariffOptions';
import useFieldValidation from '@/hooks/useFieldValidation';

interface RegistrationSchemaPayload {
  personalDataSchema: AnyObject;
  settingsSchema: AnyObject;
  completeSchema: AnyObject;
}
export default function useRegistrationSchema(
  userIsOpenTicket: boolean,
): RegistrationSchemaPayload {
  const { t } = useTranslation();
  const { yupField, yupFieldEnum } = useFieldValidation();

  const personalDataShape = {
    firstName: yupField.string,
    surname: yupField.string,
    email: yupField.email,
    phoneNumber: yupField.string,
    password: yupField.password,
    passwordConfirmed: yupField.password.oneOf([yup.ref('password')], t('validation.mustMatch')),
  };

  const personalDataShapeExtended = {
    companyInformation: yup.bool(),
    company: yup.object().when('companyInformation', {
      is: true,
      // Required when checkbox is active
      then: yup.object().shape({
        companyName: yupField.string,
        address: yupField.string,
        registrationNumber: yupField.string,
        vatNumber: yup.string().nullable(),
      }),
      // Non-required when checkbox is not active
      otherwise: yup.object().shape({
        companyName: yup.string().nullable(),
        address: yup.string().nullable(),
        registrationNumber: yup.string().nullable(),
        vatNumber: yup.string().nullable(),
      }),
    }),
  };

  const settingsShape = {
    notifications: yup.object().shape({
      newsletter: yup.bool(),
    }),
    agreeWithTerms: yupField.checkbox,
  };

  const settingsShapeExtended = {
    defaultTariff: yupFieldEnum(Tariff),
    currency: yupFieldEnum({ CZK: 'CZK', EUR: 'EUR' }),
  };

  /**
   * Uživatel s otevřenou jízdenkou má ve formuláři méně polí
   */
  const personalDataSchema = yup
    .object()
    .shape(
      userIsOpenTicket ? personalDataShape : { ...personalDataShape, ...personalDataShapeExtended },
    );
  const settingsSchema = yup
    .object()
    .shape(userIsOpenTicket ? settingsShape : { ...settingsShape, ...settingsShapeExtended });
  const completeSchema = yup.object().shape(
    userIsOpenTicket
      ? { ...personalDataShape, ...settingsShape }
      : {
          ...personalDataShape,
          ...personalDataShapeExtended,
          ...settingsShape,
          ...settingsShapeExtended,
        },
  );

  return {
    personalDataSchema,
    settingsSchema,
    completeSchema,
  };
}
