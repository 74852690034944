import React, { useEffect } from 'react';
import HeaderLink from '@/components/header/HeaderLink';
import { MenuDataProps } from '@/utils/pagesUtils';

export interface HeaderLinksProps extends React.HTMLProps<HTMLDivElement> {
  menuData: MenuDataProps;
  setShowSubmenu: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      linkIndex: number;
      prevShow?: boolean;
      prevIndex?: number;
    }>
  >;
  showSubMenu?: { show: boolean; linkIndex: number; prevShow?: boolean; prevIndex?: number };
  setClosedPopper?: (popperState: boolean) => void;
}

const HeaderLinks: React.FC<HeaderLinksProps> = ({
  menuData,
  setShowSubmenu,
  showSubMenu,
  className,
  setClosedPopper,
}) => {
  const { show, linkIndex, prevShow, prevIndex } = showSubMenu || {};

  useEffect(() => {
    if (prevShow && !show) {
      setShowSubmenu({ show: !show, linkIndex, prevShow, prevIndex });
    }
    if (linkIndex === prevIndex && prevShow) {
      setShowSubmenu({ show: false, linkIndex, prevShow, prevIndex });
    }
  }, [show]);

  return (
    <>
      {(menuData?.menuTop?.items || []).map(
        ({ id, item: { page_template: template, page, title, url } }, index) => {
          const content = template?.title || page?.title || title || '';
          const hrefUrl = template?.generatedUrl || page?.generatedUrl || url || '';

          return (
            <HeaderLink
              key={id}
              className={className}
              menuData={menuData}
              index={index}
              show={show}
              linkIndex={linkIndex}
              setShowSubmenu={setShowSubmenu}
              setClosedPopper={setClosedPopper}
              content={content}
              href={hrefUrl}
            />
          );
        },
      )}
    </>
  );
};

export default HeaderLinks;
