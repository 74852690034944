import deburr from 'lodash.deburr';
import { SeatClass } from '@web/shop-logic/dist/hooks/types';
import { VehicleKey } from '@web/shop-logic/dist/hooks/types';
import { ConnectionStation } from '@web/shop-logic/dist/hooks/useDepartures';
import { LocationStation } from '@web/shop-logic/dist/hooks/useLocations';
import { PageContentDynamicZone } from '@/models/types';

export const getUniqueHtmlId = (string: string): string =>
  `${Math.floor(Math.random() * 100000)}_${string ? deburr(string) : ''}`;

export const escapeHtmlBrackets = (string: string): string =>
  string.replace(/</g, '&lt;').replace(/>/g, '&gt;');

export const wait = async (ms: number): Promise<void> =>
  new Promise<void>((res) => setTimeout(() => res(), ms));

export type FareKey = `${SeatClass}` | `${VehicleKey}` | 'C0YELLOW_R8';

/**
 * Returns localization key for given seatClassKey and vehicleKey
 * @param seatClassKey Seat class in train - like 'Standard' or 'Business'
 * @param vehicleKey Type of vehicle - it's generally class of bus - not important for trains
 */
export const getFareKey = (seatClassKey: `${SeatClass}`, vehicleKey?: VehicleKey): FareKey => {
  if (seatClassKey === 'C0' && vehicleKey === 'YELLOW_R8') return 'C0YELLOW_R8';
  return seatClassKey === 'NO' ? vehicleKey || seatClassKey : seatClassKey;
};

// Source: https://stackoverflow.com/a/9039885/7125721
export const isApple =
  typeof navigator !== 'undefined' && /ipod|iphone|ipad|mac/i.test(navigator.userAgent);

export const isSafari =
  typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Vrací první komponentu s daným názvem z obsahu Strapi stránky
 * @var content pole `content` z objektu Page
 * @var componentName název pole komponenty z objektu `content`
 */
export const getComponentFromContent = <T>(
  content: PageContentDynamicZone[],
  componentName: string,
): T => content?.find((item) => item[componentName])?.[componentName] || [];

export const isI18nKey = (key: string): boolean => key.includes('.') && !key.includes(' ');

export const getConnectionDepArrStationNames = (
  stations: ConnectionStation[],
  getStation: (stationId: number) => LocationStation,
): { firstStationName: string; lastStationName: string } => ({
  firstStationName: getStation(stations[0].stationId)?.fullname,
  lastStationName: getStation(stations[stations.length - 1].stationId)?.fullname,
});

/**
 * URL friendly string
 * Source: https://gist.github.com/Majkl578/947036
 */
export const webalizeString = (str: string): string => {
  let normalizedStr = deburr(str);

  normalizedStr = normalizedStr.replace(/[^a-z\d]/gi, '-');
  normalizedStr = normalizedStr.replace(/-+/g, '-');
  if (normalizedStr[0] === '-') {
    normalizedStr = normalizedStr.substring(1, normalizedStr.length);
  }
  if (normalizedStr[normalizedStr.length - 1] === '-') {
    normalizedStr = normalizedStr.substring(0, normalizedStr.length - 1);
  }

  return normalizedStr.toLowerCase();
};

export const getCorrectLocale = (locale: string) => (locale === 'de-AT' ? 'de' : locale);

export const isEmpty = (obj: Record<string, unknown>) => {
  for (const i in obj) return false;
  return true;
};
