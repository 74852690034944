import { Trans } from 'next-i18next';
import React from 'react';
import RadioCustomControl, { RadioCustomControlProps } from '@/components/atoms/RadioCustomControl';

interface PreferenceBoxProps extends Omit<RadioCustomControlProps, 'value'> {
  header: string;
  paragraph: string;
}
const radioClassname = 'w-auto';

const PreferenceBox: React.FC<PreferenceBoxProps> = ({
  header,
  paragraph,
  name,
  checked,
  onChange,
}) => (
  <div className="pt-2 pb-1 border-b border-neutral-gray3">
    <h3 className="h4 mb-1">{header}</h3>
    <p className="mb-1">{paragraph}</p>
    <div className="flex flex-wrap items-center gap-6">
      <RadioCustomControl
        name={name}
        value="true"
        checked={checked}
        onChange={onChange}
        className={radioClassname}
      >
        <Trans i18nKey="cookies.radio.accept" />
      </RadioCustomControl>
      <RadioCustomControl
        name={name}
        value="false"
        checked={!checked}
        onChange={onChange}
        disabled={!onChange}
        className={radioClassname}
      >
        <Trans i18nKey="cookies.radio.refuse" />
      </RadioCustomControl>
    </div>
  </div>
);

export default PreferenceBox;
