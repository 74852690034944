import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * ## Použití
 * Box s pevnou výškou a borderem: `<BoxLoader className="flex justify-center items-center w-full h-60 rounded-sm border border-neutral-gray3" />`
 */
const BoxLoader: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, size = 40 }) => (
  <div className={className}>
    <CircularProgress size={size} className="full-screen-loader-spinner rounded-full" />
  </div>
);

export default BoxLoader;
