import React, { useEffect, useState } from 'react';
import SubmenuColumn from '@/components/topMenu/SubmenuColumn';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ComponentShopMenuItem } from '@/models/types';
import { MenuDataProps } from '@/utils/pagesUtils';

interface SubmenuProps {
  menuData: MenuDataProps;
  currentIndex: number;
}

/**
 * Blok s šedým pozadím, obsahuje 4 sloupce
 */
const Submenu: React.FC<SubmenuProps> = ({ menuData, currentIndex }) => {
  const [submenuItem, setSubmenuItem] = useState<ComponentShopMenuItem>(
    menuData.menuTop?.items[currentIndex],
  );
  const { isMobile } = useMobileWidth();

  useEffect(() => {
    setSubmenuItem(menuData.menuTop?.items[currentIndex]);
  }, [currentIndex]);

  const { submenu1, submenu2, submenu3, submenu4, submenuContentType } = submenuItem;
  return (
    <div className="lg:hidden group-hover:block lg:absolute lg:w-full top-[48px] left-0 z-10">
      {isMobile ? (
        [submenu1, submenu2, submenu3, submenu4].map((section, index) => (
          <SubmenuColumn
            key={`${index}${section?.[0]?.id}` || index}
            columns={section}
            columnIndex={index}
            contentType={submenuContentType}
            menuData={menuData}
          />
        ))
      ) : (
        <div className="bg-white cursor-default">
          <div className="page-layout--desktop grid grid-flow-row lg:grid-flow-col lg:grid-cols-4 lg:gap-4 lg:pt-2 lg:pb-3">
            {[submenu1, submenu2, submenu3, submenu4].map((section, index) => (
              <SubmenuColumn
                key={`${index}${section?.[0]?.id}` || index}
                columns={section}
                columnIndex={index}
                contentType={submenuContentType}
                menuData={menuData}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Submenu;
