import Link from 'next/link';
import React from 'react';
import PageIcon from '@/components/strapi/PageIcon';
import { ComponentShopMenuBanner } from '@/models/types';

interface MenuBannerProps {
  data: ComponentShopMenuBanner[];
}

const MenuBanner: React.FC<MenuBannerProps> = ({ data }) => {
  if (!data || !data.length) return null;

  return (
    <div className="flex flex-col gap-2 lg:text-14 lg:mt-1 sm:pt-2 mr-5">
      {(data || []).map(({ id, text, url, icon }) => (
        <Link key={id} href={url}>
          <a className="flex items-center gap-1 lg:bg-neutral-graylight rounded-sm lg:p-2 lg:font-bold">
            {icon && <PageIcon data={icon} className="sm:hidden w-3 h-3" />}
            {text}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default MenuBanner;
