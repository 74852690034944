import React from 'react';
import classNames from 'classnames';
import {
  Accessible,
  Accomodation,
  Apple,
  ArrowEast,
  ArrowNorth,
  ArrowSouth,
  ArrowWest,
  Bolt,
  Beer,
  Cake,
  Calendar,
  CarRental,
  Champagne,
  DirectionsBus,
  LocalCafe,
  MenuBook,
  PedalBike,
  Card,
  Cash,
  OffClose,
  Restaurant,
  Desinfection,
  School,
  Elderly,
  ElectricalServices,
  Email,
  Sale,
  Happy,
  Meh,
  Sad,
  Smile,
  Unhappy,
  Family,
  FlashOn,
  GiftCard,
  Group,
  Headphones,
  Favorite,
  Check,
  ChevronDown,
  ChevronLeft,
  ChildHead,
  Insurance,
  ChevronRight,
  ChevronUp,
  Child1,
  InfoLine,
  Email1,
  LocationOn,
  LocalTaxi,
  Star,
  Lock,
  Luggage,
  Mask,
  Time,
  Mobile,
  LocalParking,
  Person,
  Phone,
  Savings,
  PlaneTicket,
  Play,
  Sanitizer,
  Seat,
  SeatBusiness,
  SeatEconomyPremium,
  SeatPerson,
  Ski,
  Skiing,
  SleepSeats,
  Snow,
  Stevardess,
  Suitcase,
  SunWeather,
  SupportAgent,
  Tea,
  ThumbsUp,
  TrainTicket,
  DirectionsTrain,
  DirectionsWalk,
  Wallet,
  Warning,
  Wifi,
  Language,
} from '@/icons';
import { ComponentPageIcon } from '@/models/types';

export const mapIconToIcon = {
  accessible: Accessible,
  accommodation: Accomodation,
  apple: Apple,
  arrowEast: ArrowEast,
  arrowNorth: ArrowNorth,
  arrowSouth: ArrowSouth,
  arrowWest: ArrowWest,
  bike: PedalBike,
  bolt: Bolt,
  book: MenuBook,
  bus: DirectionsBus,
  beer: Beer,
  cake: Cake,
  calendar: Calendar,
  car: CarRental,
  card: Card,
  cash: Cash,
  close: OffClose,
  desinfection: Desinfection,
  education: School,
  elderly: Elderly,
  electricalServices: ElectricalServices,
  email: Email,
  faceHappy: Happy,
  faceMeh: Meh,
  faceSad: Sad,
  faceSmile: Smile,
  faceUnhappy: Unhappy,
  family: Family,
  flashOn: FlashOn,
  giftcard: GiftCard,
  group: Group,
  headphones: Headphones,
  heart: Favorite,
  champagne: Champagne,
  check: Check,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  child: Child1,
  childHead: ChildHead,
  infoLine: InfoLine,
  insurance: Insurance,
  lightning: FlashOn,
  like: ThumbsUp,
  letter: Email1,
  localCafe: LocalCafe,
  localTaxi: LocalTaxi,
  locationMark: Check,
  locationOn: LocationOn,
  lock: Lock,
  luggage: Luggage,
  mask: Mask,
  mobile: Mobile,
  parking: LocalParking,
  person: Person,
  phone: Phone,
  piggyBank: Savings,
  plane: PlaneTicket,
  play: Play,
  restaurant: Restaurant,
  sale: Sale,
  sanitizer: Sanitizer,
  seat: Seat,
  seatBusiness: SeatBusiness,
  seatEconomy: SeatEconomyPremium,
  seatPerson: SeatPerson,
  ski: Ski,
  skiing: Skiing,
  sleepSeats: SleepSeats,
  snow: Snow,
  star: Star,
  stevardess: Stevardess,
  suitcase: Suitcase,
  sun: SunWeather,
  support: SupportAgent,
  tea: Tea,
  thumbsUp: ThumbsUp,
  ticket: TrainTicket,
  time: Time,
  train: DirectionsTrain,
  walk: DirectionsWalk,
  wallet: Wallet,
  warning: Warning,
  wifi: Wifi,
  world: Language,
};

interface PageIconProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<SVGElement>, SVGElement> {
  data: ComponentPageIcon;
}

const PageIcon: React.FC<PageIconProps> = ({ data, className }) => {
  if (!data) {
    return null;
  }
  const Icon = mapIconToIcon[data.icon];
  return <Icon aria-hidden className={classNames('fill-current flex-shrink-0', className)} />;
};

export default PageIcon;
