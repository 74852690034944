// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { SENTRY_DSN, ENVIRONMENT } from './src/constants/env';
import { routeChangeErrorStart } from './src/hooks/usePreventRouteChange';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: SENTRY_DSN || 'https://14cbb839c2af40fc9fa2fca8e512632e@o574523.ingest.sentry.io/5797314',
  // Adjust this value in production, or use tracesSampler for greater control

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: ENVIRONMENT,
  beforeSend: (event, hint) => {
    /**
     * Error při změně routy je vyžadován kvůli nextu, proto jej nepotřebujeme posílat do Sentry
     */
    if (hint?.originalException?.toString()?.startsWith(routeChangeErrorStart)) {
      return null;
    }
    return event;
  },
  ignoreErrors: [
    'AxiosError',
    'CanceledError',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
  ],
});
