import { useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { Modal as ModalComponent } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import useMobileWidth from '@/hooks/useMobileWidth';
import { Clear } from '@/icons';

export interface ModalInput {
  open?: boolean;
  /* Default: small */
  size?: 'small' | 'big' | 'gallery';
  onClose?: (...args: never) => void;
  unclosable?: boolean;
  unmountOnClose?: boolean;
  className?: string;
  title?: string;
  removePadding?: boolean;
  /* Default: main */
  deleteBorder?: boolean;
  zIndex?: number;
}

type CloseButtonProps = React.HTMLProps<HTMLDivElement> & ModalProps;

const CloseButton: React.FC<CloseButtonProps> = ({ className, unclosable, onClose }) => {
  const { t } = useTranslation();

  return !unclosable ? (
    <ClickableDiv
      aria-label={t('accessibility.button.closeWindow')}
      onClick={() => onClose?.()}
      className={classNames('p-1 text-neutral-gray2 hover:text-neutral-gray', className)}
    >
      <Clear className="w-3 h-3 fill-current" />
    </ClickableDiv>
  ) : null;
};

type ModalProps = Omit<React.HTMLProps<HTMLDivElement>, 'size'> & ModalInput;

const Modal: React.FC<ModalProps> = ({
  children,
  className,
  onClose,
  open,
  size = 'small',
  title,
  unclosable = false,
  removePadding = false,
  deleteBorder = false,
  zIndex,
}) => {
  const { isMobile } = useMobileWidth();

  const AnimationComponent = isMobile ? Slide : Fade;

  const titlePaddingClass = title ? 'lg:p-3 sm:p-2' : 'lg:px-3 lg:pb-3 sm:px-2 sm:pb-2';

  return (
    <ModalComponent
      open={open}
      style={zIndex && { zIndex: zIndex }}
      onClose={(_, reason) => {
        if (!(unclosable && reason === 'backdropClick')) onClose?.();
      }}
      disableEscapeKeyDown={unclosable}
      closeAfterTransition
      keepMounted
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <AnimationComponent in={open} direction={isMobile ? ('up' as const) : undefined}>
        <div
          className={classNames(
            'modal-wrapper focus:outline-none absolute overflow-auto rounded-t-sm lg:rounded-sm lg:shadow-modal sm:bottom-0 sm:left-0 sm:right-0 sm:w-full sm:max-h-[calc(100%-48px)]',
            size === 'small' && 'lg:max-w-modalSmall',
            size === 'big' && 'lg:max-w-modalBig',
            size === 'gallery' && 'lg:w-4/5',
          )}
        >
          {title ? (
            <div className="sticky inset-0 z-30 bg-neutral-white lg:-mb-0.5">
              {/* Všechny strany kromě `left` mají navíc 8px paddingu z CloseButtonu, takže vizuální padding je 16px */}
              <div
                className={classNames(
                  'flex justify-between items-center sm:p-2',
                  removePadding ? 'lg:p-0' : 'lg:p-3',
                  deleteBorder ? 'border-0 lg:py-2 lg:px-2' : 'border-b border-neutral-graylight',
                )}
              >
                <h1 className="text-h3">{title}</h1>
                {!unclosable && (
                  <CloseButton
                    unclosable={unclosable}
                    onClose={onClose}
                    className="self-start flex-shrink-0 -mr-1"
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              className={!removePadding && !unclosable && 'pb-1 pt-2 bg-neutral-white lg:-mb-0.5'}
            >
              <CloseButton
                unclosable={unclosable}
                onClose={onClose}
                className="absolute top-2 lg:right-1.5 right-1 z-40"
              />
            </div>
          )}

          {/* Content */}
          <div
            className={classNames(
              'bg-neutral-white',
              removePadding ? 'lg:p-0' : titlePaddingClass,
              deleteBorder && 'lg:px-0 lg:pb-3 lg:pt-0.5 sm:px-0 sm:pb-3 sm:pt-0',
              className,
            )}
          >
            {children}
          </div>
        </div>
      </AnimationComponent>
    </ModalComponent>
  );
};

export default Modal;
