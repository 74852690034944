import React from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { FormControlLabel, Radio, RadioProps } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@/icons';
import { getUniqueHtmlId } from '@/utils/dataUtils';

export interface RadioCustomControlProps extends RadioProps {
  checked: boolean;
  iconClassName?: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  root: { display: 'flex' },
  label: { display: 'flex', width: '100%' },
}));

const RadioCustomControl: React.FC<RadioCustomControlProps> = ({
  children,
  className,
  value,
  checked = false,
  iconClassName,
  ...props
}) => {
  const htmlId = getUniqueHtmlId(value);
  const { classes } = useStyles();
  const iconClasses = classNames(
    'w-3 h-3 flex-shrink-0 fill-current transition-colors group-hover:text-primary-blue',
    checked ? 'text-primary-blue' : 'text-neutral-gray2',
    iconClassName,
  );

  return (
    <FormControlLabel
      label={<span className="w-full">{children}</span>}
      classes={{ root: classes.root, label: classes.label }}
      className={classNames('w-full', className)}
      control={
        <Radio
          id={htmlId}
          checked={checked}
          value={value}
          color="primary"
          disableRipple
          icon={<RadioButtonUnchecked className={iconClasses} />}
          checkedIcon={<RadioButtonChecked className={iconClasses} />}
          {...props}
        />
      }
    />
  );
};

export default RadioCustomControl;
