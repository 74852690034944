import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import classNames from 'classnames';
import { useHeaders, useHeadersActions, useUser } from '@web/shop-logic';
import { Currency, Language } from '@web/shop-logic/dist/hooks/types';
import CurrencySymbol from '@/components/atoms/CurrencySymbol';
import { ClickablePopper } from '@/components/atoms/Popper';
import { headerLanguages } from '@/components/header/headerLangAndCurrency';
import HeaderCurrencies from '@/components/templates/HeaderCurrencies';
import HeaderLanguages from '@/components/templates/HeaderLanguages';
import HeaderLocalizedFlag from '@/components/templates/HeaderLocalizedFlag';
import URLS from '@/constants/url';
import { ChevronDown } from '@/icons';

export interface LocalizedPage {
  locale: Language;
  id?: string;
}

interface LanguagesCurrenciesPopperProps {
  closePopper: boolean;
  handleLang: (langPage: LocalizedPage) => Promise<void>;
  locales?: LocalizedPage[];
  setClosePopper: (closePopper: boolean) => void;
}

const LanguagesCurrenciesPopper: React.FC<LanguagesCurrenciesPopperProps> = ({
  closePopper,
  handleLang,
  locales,
  setClosePopper,
}) => {
  const { currency } = useHeaders();
  const { setCurrency } = useHeadersActions();
  const { isLoggedIn } = useUser();
  const { pathname } = useRouter();
  const { t } = useTranslation();

  const validLocales: LocalizedPage[] = headerLanguages?.map((locale) => ({
    locale,
    id: locales?.find((l) => l.locale === locale)?.id,
  }));

  const handleCurrency = (newCurrency: Currency) => {
    setCurrency(newCurrency);
    setClosePopper(true);
  };

  const shouldShowCurrencies = !isLoggedIn && pathname === URLS.HOMEPAGE;

  return (
    <ClickablePopper
      isClosedPopper={closePopper}
      setClosedPopper={setClosePopper}
      gtmPlace="Header"
      gtmName="Show language and currency"
      ariaLabel={t('accessibility.languageChoose')}
      content={
        <div className="py-2 flex cursor-pointer">
          <div
            className={classNames(
              shouldShowCurrencies && 'border-r border-neutral-gray3',
              'flex flex-col mx-1',
            )}
          >
            {validLocales?.map(({ locale, id }) => (
              <HeaderLanguages
                key={locale}
                locale={locale}
                handleLanguage={async () => handleLang({ locale, id })}
              />
            ))}
          </div>
          {shouldShowCurrencies && <HeaderCurrencies handleCurrency={handleCurrency} />}
        </div>
      }
    >
      <div className="flex justify-center items-center sm:hidden mr-2 cursor-pointer">
        <HeaderLocalizedFlag />
        {shouldShowCurrencies && (
          <span className="text-14 leading-none">
            <CurrencySymbol currency={currency} />
          </span>
        )}
        <ChevronDown className="w-3 h-3" />
      </div>
    </ClickablePopper>
  );
};

export default LanguagesCurrenciesPopper;
