import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { FilledTextFieldProps, TextField, InputBaseComponentProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Check, Eye, VisibilityOff, Warning } from '@/icons';
import { getUniqueHtmlId } from '@/utils/dataUtils';

export const useInputStyles = makeStyles()(() => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderRadius: 6,
      '&:hover': { backgroundColor: 'inherit' },
      '&:before': {
        top: 0,
        borderRadius: 6,
        border: '1px solid #92A4AD',
        transition: '.2s border-color',
      },
      '&:hover:not(&.Mui-disabled):not(&.Mui-error):before ': { borderColor: '#00519e' },
      '&.Mui-disabled': { backgroundColor: '#eff2f3', '&:before': { border: 'none' } },
      '&.Mui-focused': {
        color: '#000000',
        outline: 'initial',
        backgroundColor: 'inherit',
        '&:before': { borderColor: '#00519e' },
      },
      '&.Mui-error': { '&:before': { borderColor: '#ff0f1d' } },
    },
    '& .MuiFilledInput-underline::after': { borderBottom: 'initial' },
    '& label, & label.Mui-focused': { color: '#5E7181' },
  },
  iconCheck: { fill: '#039900' },
  iconWarning: { fill: '#ff0f1d' },
  iconButton: { fill: '#5e7181' },
  helperText: { marginLeft: 0, marginTop: 4 },
}));

export interface InputProps extends Omit<FilledTextFieldProps, 'variant'> {
  successIcon?: boolean;
  errorIcon?: boolean;
  errorText?: string;
  password?: boolean;
  onlyNumbers?: boolean;
}

const Input: React.FC<InputProps> = ({
  successIcon,
  errorIcon,
  errorText,
  password,
  onlyNumbers,
  children,
  className,
  InputProps,
  inputProps,
  id,
  ...props
}) => {
  const { classes } = useInputStyles();
  const [showPassword, setShowPassword] = useState(false);
  const inputType = password && !showPassword ? 'password' : 'text';
  const onlyNumbersInputProp = onlyNumbers
    ? {
        onInput: (event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }) => {
          // eslint-disable-next-line no-param-reassign
          event.target.value = event.target.value.replace(/\D/g, '');
        },
      }
    : {};

  const onlyNumbersinputProp: InputBaseComponentProps = onlyNumbers ? { inputMode: 'decimal' } : {};

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  let endAdornment = password ? (
    <InputAdornment position="end" aria-hidden="true">
      <IconButton
        className={classes.iconButton}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        size="large"
      >
        {showPassword ? <VisibilityOff className="w-3 h-3" /> : <Eye className="w-3 h-3" />}
      </IconButton>
    </InputAdornment>
  ) : null;

  if (!password && (successIcon || errorIcon))
    endAdornment = (
      <InputAdornment position="end" disablePointerEvents>
        <IconButton edge="end" size="large">
          {successIcon && <Check width="24" className={classes.iconCheck} />}
          {errorIcon && <Warning width="24" className={classes.iconWarning} />}
        </IconButton>
      </InputAdornment>
    );

  return (
    <TextField
      className={classNames(classes.root, className)}
      // Sestaví bezpečný string z atributu `name` (a11y: propojení inputu s labelem)
      id={id || getUniqueHtmlId(props.name)}
      variant="filled"
      error={!!errorText?.length}
      helperText={errorText}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      InputProps={{ endAdornment, ...onlyNumbersInputProp, ...InputProps }}
      inputProps={{ type: inputType, ...onlyNumbersinputProp, ...inputProps }}
      {...props}
    >
      {children}
    </TextField>
  );
};

export default Input;
