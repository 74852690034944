// eslint-disable-next-line import/order
import { i18n } from '../../next-i18next.config';
import { getCookie, hasCookie } from 'cookies-next';
import { setDefaultOptions } from 'date-fns';
import { addWeeks } from 'date-fns';
import ICU from 'i18next-icu';
import { appWithTranslation, UserConfig } from 'next-i18next';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import 'tailwindcss/tailwind.css';
import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { useAsync } from 'react-use';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@sentry/nextjs';
import { consts, ShopReduxProvider } from '@web/shop-logic';
import { Language } from '@web/shop-logic/dist/hooks/types';
import NoScriptDialog from '@/components/atoms/NoScriptDialog';
import PreventRouteChange from '@/components/atoms/PreventRouteChange';
import { getLocaleCurrency } from '@/components/atoms/Price';
import CookiesBar from '@/components/cookiesPreferences/CookiesBar';
import CookiesProvider, {
  CookiesPreferences,
  initialPreferences,
} from '@/components/cookiesPreferences/CookiesProvider';
import { LocalizedPage } from '@/components/header/LanguagesCurrenciesPopper';
import IdentityProvider from '@/components/identity/IdentityProvider';
import NextRouterLoader from '@/components/loader/NextRouterLoader';
import MessagesProvider from '@/components/login/MessagesProvider';
import ModalProvider from '@/components/modal/ModalProvider';
import DaktelaNightBot from '@/components/scripts/DaktelaNightBot';
import Footer from '@/components/templates/Footer';
import Header from '@/components/templates/Header';
import HeaderAlertBar from '@/components/templates/HeaderAlertBar';
import HtmlHead from '@/components/templates/HtmlHead';
import env from '@/constants/env';
import URLS from '@/constants/url';
import { PageTemplateInput, PageEntity } from '@/models/types';
import { sendCookiesPreferencesToGtm } from '@/utils/cookiesUtils';
import { withAppEmotionCache } from '@/utils/createEmotionCache';
import { getCorrectLocale, isI18nKey } from '@/utils/dataUtils';
import { dateLocales } from '@/utils/dateTimeUtils';
import { SharedPropsType } from '@/utils/pagesUtils';
import { polyfill } from '@/utils/polyfills';
import { getFirstParamValue, getRewritedPath } from '@/utils/routerUtils';

require('@/less/Index.less');

export const THEME = createTheme({
  typography: {
    fontFamily: `"Inter", sans-serif`,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 700,
  },
});

interface Props extends SharedPropsType {
  page: PageEntity & PageTemplateInput;
}
interface AppOwnProps {
  canonical: string;
}

const persistConfig =
  typeof window !== 'undefined'
    ? {
        getItem: () => JSON.parse(localStorage.getItem('root') || '{}'),
        setItem: (o) =>
          localStorage.setItem(
            'root',
            JSON.stringify({
              ...persistConfig.getItem(),
              ...o,
            }),
          ),
      }
    : undefined;

const cache =
  typeof window !== 'undefined'
    ? {
        getItem: () => JSON.parse(localStorage.getItem('const-cache') || '{}'),
        setItem: (o) =>
          localStorage.setItem(
            'const-cache',
            JSON.stringify({
              ...cache.getItem(),
              ...o,
            }),
          ),
      }
    : undefined;

consts.api.defaults.timeout = env.ENVIRONMENT !== 'prod' ? 60000 : 30000;

/**
 * Main component for wrappers
 */
const NextApp = ({
  Component,
  pageProps: { seoData, menuData, footerData, ...pageProps },
  canonical,
  router,
}: AppProps<Props> & AppOwnProps): JSX.Element => {
  /**
   * Polyfill pro intl datetimeformat a pluralrules
   */
  const { loading } = useAsync(polyfill, []);
  const correctLocale = getCorrectLocale(router.locale);
  setDefaultOptions({ locale: dateLocales[correctLocale] });

  /**
   * Řeší scroll position při změně route (např tlačítko zpět)
   * Vypíná defaultní chování prohlížečů (nascrollovat na zapamatovanou pozici)
   * Nyní si pozici ukládáme zde včetně výšky okna (takže to neskáče, než se načtou data)
   * Zdroj: https://github.com/vercel/next.js/issues/3303
   */
  const cachedScrollPositions = [];

  useEffect(() => {
    const cookiesFromStorage = Object.keys(initialPreferences).reduce(
      (result, key: keyof CookiesPreferences) => ({
        ...result,
        [key]: hasCookie(key) ? JSON.parse(getCookie(key)) : false,
      }),
      {} as CookiesPreferences,
    );
    sendCookiesPreferencesToGtm('consentDefault', cookiesFromStorage);
    if (env.GTM_ID) TagManager.initialize({ gtmId: env.GTM_ID });

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
      let shouldScrollRestore;

      router.events.on('routeChangeStart', () => {
        cachedScrollPositions.push([window.scrollX, window.scrollY]);
      });

      router.events.on('routeChangeComplete', () => {
        if (shouldScrollRestore) {
          const { x, y } = shouldScrollRestore;
          window.scrollTo(x, y);
          shouldScrollRestore = false;
        }
      });

      router.beforePopState(() => {
        const [x, y] = cachedScrollPositions.pop() || [0, 0];
        shouldScrollRestore = { x, y };

        return true;
      });
    }
  }, []);

  /**
   * Nastavuje do localStorage kody pro affiliate, handluji se v shop-logic
   */
  useEffect(() => {
    if (router.query.affiliate) {
      localStorage.setItem('affiliateCode', getFirstParamValue(router.query.affiliate));
      localStorage.setItem('affiliateCodeExpiration', addWeeks(new Date(), 1).valueOf().toString());
    }
  }, [router.query]);

  useEffect(() => {
    router.beforePopState(({ url }) => {
      /**
       * 'back' button on card - no booking provided so we want to push user
       * to homepage synchronously
       */
      if (
        router.pathname.includes(URLS.RESERVATION.CART) &&
        url.includes(URLS.RESERVATION.PASSENGERS) &&
        typeof window !== 'undefined'
      ) {
        window.location.replace(URLS.HOMEPAGE);
        return false;
      }
      return true;
    });
  }, [router]);

  // Function to handle changes in localStorage
  // If the stored language differs from the current locale, update the localStorage
  useEffect(() => {
    const handleStorageChange = (): void => {
      const storedData = persistConfig.getItem();
      if (storedData && storedData.user) {
        const storedLanguage = storedData.user.language;
        const currentLocale = router.locale;

        if (storedLanguage !== currentLocale) {
          persistConfig.setItem({
            user: {
              ...storedData.user,
              language: currentLocale,
            },
          });
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Initial check
    handleStorageChange();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [router.locale]);

  // polyfill loading on client side (show content when SSR)
  if (loading && typeof window !== 'undefined') return null;

  return (
    <>
      <DaktelaNightBot />
      <NoScriptDialog />
      <ErrorBoundary showDialog>
        <GoogleReCaptchaProvider reCaptchaKey={env.RECAPTCHA_KEY}>
          <HtmlHead generalSeo={seoData} canonical={canonical} />
          <ShopReduxProvider
            persistConfig={persistConfig}
            cache={cache}
            persist={{ booking: true, responseState: true }}
            currency={getLocaleCurrency(router.locale)}
            lang={getCorrectLocale(router.locale) as Language}
            env={env.ENVIRONMENT}
          >
            <ThemeProvider theme={THEME}>
              <IdentityProvider>
                <CookiesProvider>
                  <MessagesProvider>
                    <ModalProvider>
                      <CookiesBar />
                      {/* Footer je vždy na konci stránky i v případě, že stránka nemá obsah (Strapi...)*/}
                      <div className="flex flex-col min-h-screen">
                        {/* @ts-expect-error Extending window obj */}
                        {!(typeof window !== 'undefined' && window.isWebView) && (
                          <Header
                            locales={(pageProps?.page?.localizations as LocalizedPage[]) || []}
                            menuData={menuData}
                            pageType={pageProps?.page?.template ? 'page-templates' : 'pages'}
                          />
                        )}
                        <div className="relative flex-grow">
                          <HeaderAlertBar />
                          <Component {...pageProps} />
                        </div>
                        <Footer footerData={footerData} />
                        <NextRouterLoader />
                        <PreventRouteChange />
                      </div>
                    </ModalProvider>
                  </MessagesProvider>
                </CookiesProvider>
              </IdentityProvider>
            </ThemeProvider>
          </ShopReduxProvider>
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </>
  );
};

NextApp.getInitialProps = async (
  context: AppContext,
): Promise<AppOwnProps & AppInitialProps<Props>> => {
  const ctx = await App.getInitialProps(context);
  const { asPath, locale } = context.router;
  const cleanPath = asPath.split('#')[0].split('?')[0];
  const canonical = getRewritedPath(locale, cleanPath);

  return { ...ctx, canonical };
};

export default appWithTranslation(withAppEmotionCache(NextApp), {
  i18n,
  load: 'currentOnly',
  keySeparator: false,
  use: [new ICU()],
  missingKeyHandler: (lngs, ns, key) => isI18nKey(key) && console.error('missing:', key),
} as UserConfig);
