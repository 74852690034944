module.exports = [
  { locale: 'de-AT', domain: 'regiojet.at' },
  { locale: 'en', domain: 'regiojet.com' },
  { locale: 'cs', domain: 'regiojet.cz' },
  { locale: 'de', domain: 'regiojet.de' },
  { locale: 'hu', domain: 'regiojet.hu' },
  { locale: 'pl', domain: 'regiojet.pl' },
  { locale: 'sk', domain: 'regiojet.sk' },
  { locale: 'uk', domain: 'regiojet.ua' },
];
