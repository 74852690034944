import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  ClickAwayListener,
  Fade,
  Grow,
  Popper as PopperComponent,
  PopperPlacementType,
} from '@mui/material';
import ModalContext from '@/components/modal/ModalContext';
import useContextSelector from '@/hooks/useContextSelector';
import { getFirstFocusableElement } from '@/utils/a11yUtils';
import { ClickableElementProps, handleClick, handleKeyPress } from '@/utils/clickableUtils';

const useStyles = makeStyles()(() => ({
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
  popper: {
    zIndex: 2000,
    // Fix max-width on mobile
    maxWidth: 'calc(100vw - 16px)',
    '&[x-placement*="bottom"] .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
    '&[x-placement*="top"] .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '100% 0',
      },
    },
    '&[x-placement*="right"] .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '100% 100%',
      },
    },
    '&[x-placement*="left"] .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '0 0',
      },
    },
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  arrow: {
    overflow: 'hidden',
    width: 0,
    height: 0,
    boxSizing: 'border-box',
    borderLeft: ' 5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid white',
    top: '-5px',
  },
}));

interface PopperProps {
  content: React.ReactElement;
  children: React.ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  placement?: PopperPlacementType;
  // Pokud `content` obsahuje interaktivní prvky, musíš vyplnit ariaLabel
  ariaLabel?: string;
}

/**
 * Reusable Popover with arrow
 * https://stackoverflow.com/a/64040075
 */
export const Popper: React.FC<PopperProps> = ({
  placement = 'bottom',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children,
  ariaLabel,
}) => {
  const { classes } = useStyles();
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);
  const contentRef = useRef();

  /**
   * Nastavuje focus do Popperu, jakmile je vyrenderován
   * Zdroj: https://github.com/popperjs/react-popper/issues/395#issuecomment-730554252
   */
  useEffect(() => {
    setTimeout(() => {
      if (open) {
        const focusable = getFirstFocusableElement(contentRef.current);
        focusable?.focus();
      }
    }, 0);
  }, [open]);

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <PopperComponent
        open={!!open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'window',
            },
          },
          {
            name: 'arrow',
            enabled: arrow,
            options: {
              element: arrowRef,
            },
          },
        ]}
        /**
         * role `tooltip`: pouze text bez interaktivních prvků, bez aria-label
         * role `dialog`: menu s interaktivními prvky, obsahuje aria-label
         */
        role={ariaLabel ? 'dialog' : 'tooltip'}
        aria-label={ariaLabel}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClose}>
            <Fade {...TransitionProps}>
              <div ref={contentRef} className="shadow-popper rounded-sm bg-white">
                {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
                {content}
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </PopperComponent>
    </>
  );
};

interface ClickablePopperProps
  extends Pick<ClickableElementProps<HTMLDivElement>, 'gtmPlace' | 'gtmName'>,
    Pick<PopperProps, 'arrow' | 'placement'>,
    // ariaLabel je povinný (klikací prvek musí mít popis)
    Required<Pick<PopperProps, 'ariaLabel'>> {
  content: React.ReactElement | (() => React.ReactElement);
  children: React.ReactElement;
  disabled?: boolean;
  isClosedPopper?: boolean;
  setClosedPopper?: (state: boolean) => void;
}

/**
 * Children musí být element, který je schopen pracovat s React Reference
 * Pokud má být v children custom komponenta, je potřeba ji obalit např. do <div>
 * (správně by měla mít custom komponenta React.forwardRef, ale nefunguje to
 * https://material-ui.com/components/tooltips/#custom-child-element
 */
export const ClickablePopper: React.FC<ClickablePopperProps> = ({
  placement,
  arrow,
  content,
  children,
  disabled = false,
  isClosedPopper = false,
  setClosedPopper,
  gtmPlace,
  gtmName,
  ariaLabel,
}) => {
  const state = useContextSelector(ModalContext, (c) => c.state);
  const { asPath } = useRouter();

  const [open, setOpen] = useState(false);

  const existingOnClick = children.props.onClick;
  const contentNode = typeof content === 'function' ? content() : content;

  const newOnClick = (e: unknown) => {
    setOpen(!open);
    existingOnClick?.(e);
  };

  useEffect(() => {
    if (isClosedPopper) {
      setOpen(false);
      setClosedPopper(false);
    }
  }, [isClosedPopper]);

  /* Zavře popper při přesměrování */
  useEffect(() => setOpen(false), [asPath]);

  useEffect(() => {
    /* Zavře popper při otevření modálu */
    if (Object.keys(state).length) setOpen(false);
  }, [state]);

  return disabled ? (
    React.cloneElement(children, { ...children.props, disabled: true })
  ) : (
    <Popper
      open={!!open}
      onClose={() => setOpen(false)}
      arrow={arrow}
      placement={placement}
      content={contentNode}
      ariaLabel={ariaLabel}
    >
      {React.cloneElement(children, {
        ...children.props,
        // Odpovídá ClickableDiv
        onClick: handleClick({ onClick: newOnClick, gtmPlace, gtmName }),
        tabIndex: 0,
        role: 'button',
        onKeyPress: handleKeyPress({ onClick: newOnClick, gtmPlace, gtmName }),
        'aria-label': ariaLabel,
      })}
    </Popper>
  );
};

/**
 * Reusable Popper for SearchBox
 */

interface SearchBoxPopperProps extends Pick<PopperProps, 'onClose' | 'placement'> {
  content: React.ReactElement;
  children: React.ReactElement;
  open?: boolean;
}

export const SearchBoxPopper: React.FC<SearchBoxPopperProps> = ({
  placement = 'bottom',
  open,
  onClose = () => {},
  content,
  children,
}) => {
  const { classes } = useStyles();
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);
  const contentRef = useRef();

  /**
   * Nastavuje focus do Popperu, jakmile je vyrenderován
   * Zdroj: https://github.com/popperjs/react-popper/issues/395#issuecomment-730554252
   * Nutný timeout alespoň 100 ms jinak způsobuje bug se scrollováním na konec stránky
   */
  useEffect(() => {
    setTimeout(() => {
      if (open) {
        const focusable = getFirstFocusableElement(contentRef.current);
        focusable?.focus();
      }
    }, 100);
  }, [open]);

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <PopperComponent
        role="dialog"
        open={!!open}
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'window',
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClose}>
            <Grow
              {...TransitionProps}
              style={{ animation: 'fadeIn 254ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
              timeout={open ? 254 : 0}
            >
              <div
                ref={contentRef}
                className="shadow-popper rounded-sm bg-white mt-0.5 overflow-hidden"
              >
                {content}
              </div>
            </Grow>
          </ClickAwayListener>
        )}
      </PopperComponent>
    </>
  );
};
