import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { Check } from '@/icons';

interface BenefitListProps extends React.HTMLProps<HTMLDivElement> {
  fullWidth?: boolean;
  compact?: boolean;
}

const BenefitList: React.FC<BenefitListProps> = ({
  className,
  fullWidth = false,
  compact = false,
}) => {
  const rowClasses = classNames('flex items-start gap-1 mb-1.5', !fullWidth && 'lg:w-3/4');
  const iconClasses = classNames('w-3 h-3 fill-primary-blue flex-shrink-0');

  return (
    <ul className={classNames(compact && 'flex items-center justify-start', className)}>
      <li className={rowClasses}>
        <Check className={iconClasses} />
        <Trans i18nKey="loginBox.registerColumn.item.prices" />
      </li>
      <li className={rowClasses}>
        <Check className={iconClasses} />
        <Trans
          i18nKey={
            compact
              ? 'loginBox.registerColumn.item.reservations.compact'
              : 'loginBox.registerColumn.item.reservations'
          }
        />
      </li>
      <li className={rowClasses}>
        <Check className={iconClasses} />
        <Trans
          i18nKey={
            compact
              ? 'loginBox.registerColumn.item.tickets.compact'
              : 'loginBox.registerColumn.item.tickets'
          }
        />
      </li>
    </ul>
  );
};
export default BenefitList;
