import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { Enum_Componentpageseo_Robots } from '@/models/types';
import { languageToDomains } from '@/utils/routerUtils';

const mapRobotsToKeys = {
  noindex_nofollow: 'noindex, nofollow',
  noindex_follow: 'noindex, follow',
  index_nofollow: 'index, nofollow',
  index_follow: 'index, follow',
};

const isIndexable = (robots: Enum_Componentpageseo_Robots): boolean =>
  robots !== Enum_Componentpageseo_Robots.NoindexFollow &&
  robots !== Enum_Componentpageseo_Robots.NoindexNofollow;

/**
 * Title + SEO
 * Lze importovat vícekrát v rámci Nextu
 * Defaultní hodnoty se nastavují v `HtmlHead.tsx`
 * Poté je možné hodnoty přepsat na úrovni stránek
 */
interface SeoHeadProps {
  imageUrl: string;
  title: string;
  description: string;
  ogDescription: string;
  robots: Enum_Componentpageseo_Robots;
  canonical: string;
}
const SeoHead: React.FC<SeoHeadProps> = ({
  title,
  description,
  ogDescription,
  imageUrl,
  robots,
  canonical,
}) => {
  const { locale } = useRouter();
  const pageRobots = mapRobotsToKeys[robots];
  const pageCanonical = isIndexable(robots) && `https://${languageToDomains[locale]}${canonical}`;

  return (
    <>
      <DefaultSeo
        canonical={pageCanonical}
        title={title}
        description={description || ogDescription}
        openGraph={{
          title: title,
          description: ogDescription || description,
          images: imageUrl ? [{ url: imageUrl }] : undefined,
        }}
      />
      <Head>{pageRobots && <meta key="robots" name="robots" content={pageRobots} />}</Head>
    </>
  );
};

export default SeoHead;
