import { getCookie, hasCookie, setCookie } from 'cookies-next';
import React, { useEffect, useState } from 'react';
import CookiesContext from '@/components/cookiesPreferences/CookiesContext';
import { sendCookiesPreferencesToGtm } from '@/utils/cookiesUtils';

export interface CookiesPreferences {
  required: boolean;
  performance: boolean;
  marketing: boolean;
  functional: boolean;
  personalization: boolean;
  userData: boolean;
}

export interface CookiesPreferencesProps {
  initialPreferences: CookiesPreferences;
  minimalAllowedPreferences: CookiesPreferences;
  cookiesValues: CookiesPreferences;
  setCookiesValues: React.Dispatch<React.SetStateAction<CookiesPreferences>>;
  saveCookiesPreferences: (preferences: CookiesPreferences) => void;
  isCookiesSet: boolean;
  allowAllCookies: () => void;
  rejectAllCookies: () => void;
}

export const initialPreferences: CookiesPreferences = {
  required: false,
  performance: false,
  marketing: false,
  functional: false,
  personalization: false,
  userData: false,
};

const MAX_AGE_GRANTED = 12 * 30 * 24 * 60 * 60; // 12 months
const MAX_AGE_DENIED = 6 * 30 * 24 * 60 * 60; // 6 months

const CookiesProvider: React.FC = ({ children }) => {
  const [cookiesValues, setCookiesValues] = useState<CookiesPreferences>(initialPreferences);

  const allAllowedPreferences: CookiesPreferences = {
    ...Object.keys(initialPreferences).reduce(
      (result, key) => ({ ...result, [key]: true }),
      {} as CookiesPreferences,
    ),
  };

  const minimalAllowedPreferences: CookiesPreferences = {
    ...Object.keys(initialPreferences).reduce(
      (result, key) => ({ ...result, [key]: key === 'required' }),
      {} as CookiesPreferences,
    ),
  };

  useEffect(() => {
    const cookiesFromStorage = Object.keys(initialPreferences).reduce(
      (result, key: keyof CookiesPreferences) => ({
        ...result,
        [key]: hasCookie(key) ? JSON.parse(getCookie(key)) : undefined,
      }),
      {} as CookiesPreferences,
    );
    setCookiesValues(cookiesFromStorage);
  }, []);

  const isCookiesSet = Object.values(cookiesValues).every((value) => value !== undefined);

  // Uloží preference do cookies a lokálního statu a pošle je do GTM
  const saveCookiesPreferences = (preferences: CookiesPreferences) => {
    setCookiesValues(preferences);
    Object.entries(preferences).forEach(([key, value]) => {
      setCookie(key, value, {
        maxAge: !!value ? MAX_AGE_GRANTED : MAX_AGE_DENIED,
      });
    });
    sendCookiesPreferencesToGtm('consentUpdate', preferences);
  };

  // Nastav všechny kategorie cookies jako Povolit
  const allowAllCookies = () => {
    saveCookiesPreferences(allAllowedPreferences);
  };

  // Nastav všechny kategorie kromě požadovaných cookies(!) jako Zakázat
  const rejectAllCookies = () => {
    saveCookiesPreferences(minimalAllowedPreferences);
  };

  return (
    <CookiesContext.Provider
      value={{
        initialPreferences,
        minimalAllowedPreferences,
        cookiesValues,
        setCookiesValues,
        saveCookiesPreferences,
        isCookiesSet,
        allowAllCookies,
        rejectAllCookies,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

export default CookiesProvider;
