import { createContext } from 'use-context-selector';
import { initialState } from '@/components/modal/modalReducer';
import {
  State,
  HideFn,
  ShowFn,
  DestroyFn,
  UpdateFn,
  HideAllFn,
} from '@/components/modal/modalTypes';

export type ModalContextState = {
  state: State;
  updateModal: UpdateFn;
  hideModal: HideFn;
  hideAllModals: HideAllFn;
  destroyModal: DestroyFn;
  showModal: ShowFn;
};

export const initialContextState = {
  state: initialState,
  hideModal: (): void => {},
  hideAllModals: (): void => {},
  showModal: (): { id: string; hide: () => void; destroy: () => void; update: () => void } => ({
    id: 'id',
    hide: () => {},
    destroy: () => {},
    update: () => {},
  }),
  destroyModal: (): void => {},
  updateModal: (): void => {},
};

const ModalContext = createContext<ModalContextState>(initialContextState);

export default ModalContext;
