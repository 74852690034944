import { shouldPolyfill as shouldPolyfillDateTime } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';

const polyfillCanonicalLocales = async () => {
  if (shouldPolyfillCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
};
const polyfillLocale = async () => {
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
};

const polyfillDateTime = async (locale: string) => {
  const unsupportedLocale = shouldPolyfillDateTime(locale);
  if (!unsupportedLocale) {
    return;
  }
  await import('@formatjs/intl-datetimeformat/polyfill-force');
  const dataPolyfills = [
    import('@formatjs/intl-datetimeformat/add-all-tz'),
    import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`),
  ];
  await Promise.all(dataPolyfills);
};

const polyfillPluralRules = async (locale: string) => {
  const unsupportedLocale = shouldPolyfillPluralRules(locale);
  if (!unsupportedLocale) {
    return;
  }
  await import('@formatjs/intl-pluralrules/polyfill-force');
  await import(`@formatjs/intl-pluralrules/locale-data/${unsupportedLocale}`);
};

export const polyfill = async (locale: string) => {
  await polyfillLocale();
  await polyfillCanonicalLocales();
  await polyfillPluralRules(locale);
  await polyfillDateTime(locale);
};
