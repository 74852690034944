import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import URLS from '@/constants/url';

const SearchRouteLink = () => (
  <Link href={URLS.HOMEPAGE}>
    <a className="px-2 py-1.5 font-bold bg-white">
      <Trans i18nKey="searchRoutes.button.searchRoutes" />
    </a>
  </Link>
);

export default SearchRouteLink;
