import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Input, { InputProps } from '@/components/atoms/Input';
import { ArrowDropDown } from '@/icons';
import { CitiesOptionValues } from '@/utils/citiesSelectUtils';

export interface SelectInputProps extends InputProps {
  options: CitiesOptionValues[];
}

const SelectInput: React.FC<SelectInputProps> = ({ options, ...props }) => {
  const [selectValue, setSelectValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectValue(event.target.value);

  return (
    <Input
      select
      value={selectValue}
      defaultValue=""
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          PaperProps: { style: { maxHeight: '45%' } },
        },
        IconComponent: (iconProps) => (
          <ArrowDropDown
            {...iconProps}
            className="absolute right-1 w-3 max-h-3 flex-shrink-0 pointer-events-none"
          />
        ),
      }}
      {...props}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Input>
  );
};

export default SelectInput;
