import { Context, useContext as useContextOrig } from 'use-context-selector';

const useContext = <Value>(context: Context<Value>, defaultValue?: Value) => {
  try {
    return useContextOrig(context) ?? defaultValue;
  } catch {
    return defaultValue;
  }
};

export default useContext;
