import { parseStrapiDate } from '../dateTimeUtils';
import { format, isAfter, isEqual, isFuture, isToday, isWithinInterval, parseISO } from 'date-fns';
import { ComponentExclusionsVisibility, ExclusionEntity } from '@/models/types';

export const isExclusionVisible = (visibility: ComponentExclusionsVisibility[]) => {
  const to = visibility[visibility.length - 1]?.visibleTo;
  if (!to) return true;
  else {
    const parsedTo = parseStrapiDate(to);
    return isToday(parsedTo) || isFuture(parsedTo);
  }
};

export const isExclusionNow = (visibility: ComponentExclusionsVisibility[]) => {
  const today = new Date();

  for (const period of visibility) {
    const from = parseISO(period.visibleFrom);
    const periodTo = period.visibleTo ? parseISO(period.visibleTo) : null;

    if (periodTo) {
      if (isWithinInterval(today, { start: from, end: periodTo })) {
        return true;
      }

      if (isToday(periodTo)) {
        return true;
      }
    } else {
      if (isAfter(today, from) || isEqual(today, from) || isToday(periodTo)) {
        return true;
      }
    }
  }

  return false;
};

export const sortExclusions = (values: ExclusionEntity[]) => {
  return values.sort((a, b) => {
    const aDate = parseStrapiDate(a.visibility[0].visibleFrom);
    const bDate = parseStrapiDate(b.visibility[0].visibleFrom);

    return aDate.getTime() - bDate.getTime();
  });
};

export const formatExclusions = (title: string, visibilities: ComponentExclusionsVisibility[]) => {
  let includesEndDate = false;

  const formattedVisibilities = visibilities.map((visibility) => {
    const from = visibility.visibleFrom ? format(parseISO(visibility.visibleFrom), 'd.M.yyyy') : '';
    const to = visibility.visibleTo ? format(parseISO(visibility.visibleTo), 'd.M.yyyy') : '';

    if (!to) {
      return from;
    } else {
      includesEndDate = true;
      return `${from} ${to ? '-' : ''} ${to}`;
    }
  });

  return {
    title,
    includesEndDate,
    visibilities: formattedVisibilities,
  };
};
