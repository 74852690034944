import React from 'react';
import { useField } from 'formik';
import Input, { InputProps } from '@/components/atoms/Input';

const FormInput: React.FC<InputProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return <Input {...field} {...props} errorText={errorText} />;
};

export default FormInput;
