import { Trans, useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import Input from '@/components/atoms/Input';
import { PhoneInputProps } from '@/components/atoms/phoneInput/PhoneInput';
import BoxLoader from '@/components/loader/BoxLoader';

/**
 * Placeholder pro: PhoneInput
 * Loading přes input s labelem
 */
const PhoneInputPlaceholder: React.FC<PhoneInputProps> = (props) => (
  <div className="relative">
    <Input {...props} />
    <BoxLoader className="absolute inset-0 flex justify-center items-center" />
  </div>
);

/**
 * Dynamický import
 * Placeholder před načtením komponenty: Loading přes input s labelem
 */
const PhoneInput = dynamic(() => import('@/components/atoms/phoneInput/PhoneInput'), {
  // eslint-disable-next-line react/display-name
  loading: () => (
    <PhoneInputPlaceholder
      label={<Trans i18nKey="registration.phoneNumber" />}
      className="w-full"
    />
  ),
});

const FormPhoneInput: React.FC<PhoneInputProps> = ({ name, ...props }) => {
  const { i18n, t } = useTranslation();

  /**
   * Původně interní stav PhoneInputComponent
   * Přesunuto zde kvůli Formik validaci
   */
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [field, meta, validate] = useField({
    name,
    validate: () => (isValidPhone ? null : (t('validation.phoneNumber') as string)),
  });
  const errorText = meta.error && meta.touched ? meta.error : '';
  const { setFieldTouched, setFieldValue } = useFormikContext();

  // https://stackoverflow.com/questions/54461158/how-to-integrate-react-intl-tel-input-with-formik
  const onBlur = (valid) => {
    setFieldTouched(name, true);
    if (!valid) validate.setError(t('validation.phoneNumber'));
  };

  const onChange = (val: object) => setFieldValue(name, val.toString().replace(/-/g, ''));

  return (
    <PhoneInput
      inputProps={{ locale: i18n.language, isValidPhone, setIsValidPhone }}
      {...field}
      onBlur={onBlur}
      onChange={onChange}
      {...props}
      errorText={errorText}
    />
  );
};
export default FormPhoneInput;
