import dynamic from 'next/dynamic';
import React from 'react';
import MenuBanner from '@/components/strapi/MenuBanner';
import MenuContact from '@/components/strapi/MenuContact';
import MenuExclusions from '@/components/strapi/MenuExclusions';
import MenuNewsArticles from '@/components/strapi/MenuNewsArticles';
import MenuPromotionsArticles from '@/components/strapi/MenuPromotionsArticles';
import ShopFavouriteLink from '@/components/strapi/ShopFavouriteLink';
import LineMap from '@/components/topMenu/LineMap';
import useMobileWidth from '@/hooks/useMobileWidth';
import {
  ComponentShopMenuLink,
  Enum_Componentshopmenuitem_Submenucontenttype,
} from '@/models/types';
import { MenuDataProps } from '@/utils/pagesUtils';

const MenuDelay = dynamic(() => import('@/components/strapi/MenuDelay'), { ssr: false });

interface SubmenuColumnProps {
  columns: ComponentShopMenuLink[];
  columnIndex: number;
  contentType: Enum_Componentshopmenuitem_Submenucontenttype;
  menuData: MenuDataProps;
}

/**
 * Sloupec submenu s jednotlivými položkami menu
 */
const SubmenuColumn: React.FC<SubmenuColumnProps> = ({
  columns,
  columnIndex,
  contentType,
  menuData: { menuTop, newsData, promotionsData, exclusionsData },
}) => {
  const { isMobile } = useMobileWidth();
  /**
   * Custom obsah pro každou položku menu
   * Struktura:
   *    {
   *      contentType: {
   *        columnIndex: <ContentComponent>
   *      }
   *    }
   */
  const ContentComponents: Record<string, Record<number, JSX.Element>> = {
    [Enum_Componentshopmenuitem_Submenucontenttype.KamJezdime]: {
      1: (
        <LineMap
          image={menuTop?.kamJezdimeLineMap?.image}
          title={menuTop?.kamJezdimeLineMap?.title}
          url={menuTop?.kamJezdimeLineMap?.url}
        />
      ),
      2: <ShopFavouriteLink data={menuTop?.kamJezdimeFavouriteRoute} />,
    },
    [Enum_Componentshopmenuitem_Submenucontenttype.Sluzby]: {
      0: <MenuBanner data={menuTop?.sluzbyMenuBanner} />,
    },
    [Enum_Componentshopmenuitem_Submenucontenttype.AkceANovinky]: {
      0: (
        <MenuPromotionsArticles
          promotionsData={promotionsData}
          title={menuTop?.akceANovinkyPromotionsArticles?.page?.title}
          url={menuTop?.akceANovinkyPromotionsArticles?.page?.generatedUrl}
        />
      ),
      1: (
        <MenuNewsArticles
          newsData={newsData}
          title={menuTop?.akceANovinkyNewsArticles?.page?.title}
          url={menuTop?.akceANovinkyNewsArticles?.page?.generatedUrl}
        />
      ),
    },
    [Enum_Componentshopmenuitem_Submenucontenttype.AktualityZProvozu]: {
      2: <MenuExclusions exclusionsData={exclusionsData} />,
      3: <MenuDelay stationId={372825000} />,
    },
    [Enum_Componentshopmenuitem_Submenucontenttype.ONas]: {
      3: <MenuContact data={menuTop?.contact} />,
    },
  };
  const ContentComponent = ContentComponents[contentType]?.[columnIndex] || null;

  /**
   * Nemáme ani custom obsah, ani položky menu
   */
  if (!ContentComponent && !columns?.length) return null;

  return (
    <>
      {isMobile ? (
        <div className="bg-neutral-graylight pl-2">{ContentComponent}</div>
      ) : (
        ContentComponent
      )}
      {!!columns?.length && (
        <div className="flex flex-col gap-2 sm:bg-neutral-graylight sm:p-2">
          {(columns || []).map(({ id, page_template: template, page, title, url }) => {
            const content = template?.title || page?.title || title || '';
            const hrefUrl = template?.generatedUrl || page?.generatedUrl || url || '';

            return (
              <a
                key={id}
                href={hrefUrl}
                className="lg:text-14 text-black hover:text-primary-blue hover:underline"
              >
                {content}
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SubmenuColumn;
