import { Trans } from 'next-i18next';
import React, { useState } from 'react';
import { Popover } from '@mui/material';
import { useHeaders, useHeadersActions } from '@web/shop-logic';
import { Currency } from '@web/shop-logic/dist/hooks/types';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import CurrencySymbol from '@/components/atoms/CurrencySymbol';
import PopoverHeader from '@/components/organisms/PopoverHeader';
import HeaderCurrencies from '@/components/templates/HeaderCurrencies';

const CurrenciesPopover = () => {
  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
  const handleCrPopover = (event) => setCurrencyAnchorEl(event.currentTarget);
  const { currency } = useHeaders();
  const { setCurrency } = useHeadersActions();

  const handleCurrency = (newCurrency: Currency) => {
    setCurrency(newCurrency);
    setCurrencyAnchorEl(null);
  };

  return (
    <>
      <ClickableDiv
        className="flex items-center justify-between border-b-2 border-transparent font-bold px-2 py-1.5 bg-white"
        onClick={handleCrPopover}
      >
        <Trans i18nKey="registration.currency" />
        <span>
          <CurrencySymbol currency={currency} />
          {' / '}
          {currency}
        </span>
      </ClickableDiv>
      <Popover
        anchorEl={currencyAnchorEl}
        anchorReference="none"
        transitionDuration={0}
        open={!!currencyAnchorEl}
        onClose={() => setCurrencyAnchorEl(null)}
        PaperProps={{ className: 'cssreset-popover-paper' }}
      >
        <PopoverHeader
          i18nKey="registration.currency"
          handleBack={() => setCurrencyAnchorEl(null)}
        />
        <div className="inline-block lg:p-3 searchBox-mob-container popover-city">
          <HeaderCurrencies handleCurrency={handleCurrency} />
        </div>
      </Popover>
    </>
  );
};

export default CurrenciesPopover;
