import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BookingContext } from '@web/shop-logic';
import URLS from '@/constants/url';
import useContextSelector from '@/hooks/useContextSelector';
import { getQueryFromString, getUrlFromPathname, isQueryValid } from '@/utils/routerUtils';

export const routeChangeErrorStart = 'Route change to';

/**
 * URL, na kterých hlídáme odcházejícího uživatele
 */
const watchedUrls = [
  URLS.RESERVATION.SEATING,
  URLS.RESERVATION.ADDONS,
  URLS.RESERVATION.PASSENGERS,
];

/**
 * Povolené URL, na které může uživatel odejít (objednávka)
 */
const allowedUrls = Object.values(URLS.RESERVATION);

interface PreventRouteChangeProps {
  preventedUrl: string;
  setPreventedUrl: Dispatch<SetStateAction<string>>;
  setUserConfirmed: Dispatch<SetStateAction<boolean>>;
}

/**
 * Hook that listens to `next/router` `'routeChangeStart'` events and prevents changing
 * to a requested URL
 */
const usePreventRouteChange = (): PreventRouteChangeProps => {
  const { events, pathname } = useRouter();
  const bookingsCount = useContextSelector(
    BookingContext,
    (c) => +!!c.state.booking?.there + +!!c.state.booking?.back,
  );

  const [preventedUrl, setPreventedUrl] = useState<string>(null);
  const [userConfirmed, setUserConfirmed] = useState(false);

  /* URL, ze které uživatel právě odchází */
  const currentUrl = getUrlFromPathname(pathname);

  useEffect(() => {
    const routeChangeStart = (url) => {
      /**
       * Cílová URL, na které chce uživatel skončit
       */
      const isRedirLocaleDefault = url.split('/')?.[1]?.length !== 2;
      const nextUrl = getUrlFromPathname(url, isRedirLocaleDefault);
      const nextUrlQuery = getQueryFromString(url);

      /**
       * Přerušení odejití hlídáme jen z některých URL
       * Pokud jsme na hlídané URL, zkontrolujeme, jestli cílová URL je mimo objednávku
       */
      const isWatched = watchedUrls.includes(currentUrl);

      /**
       * Stránka "Výběr spojů" patří do `allowedUrls`,
       * ale používá URL Homepage + parametry, takže ji nejde přidat přímo do `allowedUrls`
       * Use case: Zpáteční jízdenka - přesměrování z Addons zpátky na Výběr spojů
       */
      const isAllowed =
        !bookingsCount || allowedUrls.includes(nextUrl) || isQueryValid(nextUrlQuery, 'search');

      if (!userConfirmed && currentUrl !== url && isWatched && !isAllowed) {
        events.emit('routeChangeError');
        setPreventedUrl(nextUrl);

        // Following is a hack-ish solution to abort a Next.js route change
        // as there's currently no official API to do so
        // See https://github.com/zeit/next.js/issues/2476#issuecomment-573460710
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw `${routeChangeErrorStart} "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
      }
    };

    events.on('routeChangeStart', routeChangeStart);

    return () => events.off('routeChangeStart', routeChangeStart);
  }, [currentUrl, events, userConfirmed, bookingsCount]);

  useEffect(() => {
    if (!preventedUrl) setUserConfirmed(false);
  }, [preventedUrl]);

  return { preventedUrl, setPreventedUrl, setUserConfirmed };
};

export default usePreventRouteChange;
