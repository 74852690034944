import { createContext } from 'use-context-selector';
import React from 'react';
import { AlertVariants } from '@/components/atoms/Alert';

export interface CustomMessage {
  text: string;
  variant?: AlertVariants;
}

export type MessagesContextState = {
  isMessageAnimated: boolean;
  setIsMessageAnimated: React.Dispatch<React.SetStateAction<boolean>>;
  customMessages: CustomMessage[];
  clearMessages: React.Dispatch<React.SetStateAction<CustomMessage[]>>;
  pushToMessages: (message: CustomMessage) => void;
};

export const initialContextState = {
  isMessageAnimated: false,
  setIsMessageAnimated: (): void => {},
  customMessages: [],
  clearMessages: (): void => {},
  pushToMessages: (): void => {},
};

const MessagesContext = createContext<MessagesContextState>(initialContextState);

export default MessagesContext;
