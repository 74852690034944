import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Button } from '@/components/atoms/Button';
import ModalContext from '@/components/modal/ModalContext';
import useContextSelector from '@/hooks/useContextSelector';

interface PreventRouteChangeModalProps {
  preventedUrl: string;
  setUserConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreventRouteChangeModal: React.FC<PreventRouteChangeModalProps> = ({
  preventedUrl,
  setUserConfirmed,
}) => {
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);
  const { push } = useRouter();

  const onLeave = async () => {
    setUserConfirmed(true);
    hideAllModals();
    await push(preventedUrl);
  };

  return (
    <div>
      <p>
        <Trans i18nKey="reservation.leave.text" />
      </p>
      <div className="flex sm:flex-col lg:justify-end mt-3 gap-2">
        {/**
         * Tlačítko: Pokračovat v rezervaci
         * Zavře modal, uživatel zůstane na stránce
         */}
        <Button size="big" onClick={hideAllModals}>
          <Trans i18nKey="reservation.leave.decline" />
        </Button>
        {/**
         * Tlačítko: Opustit rezervaci
         * Zavře modal, uživatel pokračuje na URL
         */}
        {preventedUrl && (
          <Button variant="secondary" size="big" onClick={onLeave}>
            <Trans i18nKey="reservation.leave.accept" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default PreventRouteChangeModal;
