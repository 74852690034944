module.exports = {
  ignoreReservationPathnames: [
    '/reservation/seating/[direction]',
    '/reservation/fare/[direction]',
    '/reservation/addons/[direction]',
    '/reservation/cart',
    '/reservation/checkout',
    '/reservation/passengers',
    '/reservation/success',
    '/review/[ticketId]',
    '/preview',
  ],
  ignorePageGenerationPathnames: [
    '/[...all]',
    '/404',
    '/500',
    '/_app',
    '/_document',
    '/attacker-catch',
    '/review/[ticketId]',
    '/preview/[pageId]',
    '/strapi-sitemap.xml',
    '/stations/[stationId]',
    '/strapi-export',
    '/payment-redirect-gate',
    '/password-reset',
    '/logout',
    '/login',
    '/credit',
    '/account',
    '/tickets',
    '/sitemap.xml',
    '/payments',
    '/blog/[slug]',
    '/blog/category',
    '/blog/category/[slug]',
    '/blog/preview/[slug]',
    '/blog/authors',
    '/blog/authors/[slug]',
    '/blog-sitemap.xml'
  ],
};
