const currencyOptions = [
  {
    value: 'CZK',
    label: 'Kč',
  },
  {
    value: 'EUR',
    label: 'Euro',
  },
];

export default currencyOptions;
