import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import { ResponseStateContext, useSetResponseState, utils } from '@web/shop-logic';
import { ResponseStateReducerProps } from '@web/shop-logic/dist/store/responseState/types';
import Alert, { AlertVariants } from '@/components/atoms/Alert';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import MessagesContext from '@/components/login/MessagesContext';
import useContextSelector from '@/hooks/useContextSelector';
import { Clear } from '@/icons';

const mapClassesToVariant = {
  [AlertVariants.WARNING_REVERSE]: 'bg-secondary-redwarn',
  [AlertVariants.CONFIRMED_REVERSE]: 'bg-secondary-green',
};

interface HeaderAlertProps {
  message: string;
  variant: AlertVariants;
  onClose: () => void;
  errorFieldValue?: string;
}

const HeaderAlert: React.FC<HeaderAlertProps> = ({
  message,
  variant,
  onClose,
  errorFieldValue,
}) => {
  const [height, setHeight] = useState<number | 'auto' | undefined>(0);
  const customMessages = useContextSelector(MessagesContext, (c) => c.customMessages);
  /**
   * Komponenta je renderována se stavem height: 0
   * Ihned po renderu se spustí animace na height: auto
   */
  useEffect(() => {
    if (height === 0) requestAnimationFrame(() => setHeight('auto'));
  }, []);

  useEffect(() => {
    if (customMessages.length) {
      const timer = setTimeout(() => {
        requestAnimationFrame(() => setHeight(0));
      }, 3300);
      return () => window.clearTimeout(timer);
    }
  }, [customMessages.length]);

  const loginAnimationProps = customMessages.length
    ? { duration: 300, easing: 'linear', delay: 300 }
    : {};

  return (
    <AnimateHeight height={height} {...loginAnimationProps}>
      <div
        className={classNames('flex justify-between items-center', mapClassesToVariant[variant])}
      >
        {/* Prázdný div kvůli justify-between (3 children hack) */}
        <div />
        <Alert variant={variant}>
          <Trans i18nKey={message?.toString()} />
          &nbsp;
          <Trans i18nKey={errorFieldValue} />
        </Alert>
        {/* Button: Zavřít */}
        <ClickableDiv
          onClick={onClose}
          className="flex items-center mr-2 text-14 text-white cursor-pointer hover:underline"
        >
          <Trans i18nKey="close" />
          <Clear className="w-3 h-3 fill-current" />
        </ClickableDiv>
      </div>
    </AnimateHeight>
  );
};

interface ErrorAlertProps {
  message: string;
  category: number;
  errorFieldValue?: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, category, errorFieldValue }) => {
  /**
   * Potřebujeme kategorii erroru pro volání funkce setState
   */
  const setState = useSetResponseState(category);

  /**
   * Vymaže error z responseState
   */
  const closeError = async () => setState('', false);

  /**
   * Při změně URL zavři error, aby se neobjevil na další stránce
   */
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', closeError);

    return () => router.events.off('routeChangeStart', closeError);
  }, [router]);

  useEffect(() => {
    if (!!message) {
      utils.gtmPush({
        event: 'alert-warn',
        name: message,
      });
    }
  }, [message]);

  return (
    <HeaderAlert
      message={
        message === 'alert.outage' && router.pathname.startsWith('/reservation')
          ? 'customError.reservation'
          : message
      }
      errorFieldValue={errorFieldValue}
      variant={AlertVariants.WARNING_REVERSE}
      onClose={closeError}
    />
  );
};

interface CustomMessageAlertProps {
  loginMessage: string;
  variant?: AlertVariants;
}

const CustomMessageAlert: React.FC<CustomMessageAlertProps> = ({ variant, loginMessage }) => {
  const clearMessages = useContextSelector(MessagesContext, (c) => c.clearMessages);

  useEffect(() => {
    const timer = setTimeout(() => clearMessages([]), 3400);

    return () => window.clearTimeout(timer);
  }, []);

  return (
    <HeaderAlert
      message={loginMessage}
      variant={variant || AlertVariants.CONFIRMED_REVERSE}
      onClose={() => clearMessages([])}
    />
  );
};

/**
 * Lišta zobrazující chybové a potvrzující alerty
 * @returns
 */
const HeaderAlertBar: React.FC = () => {
  /**
   * Chybové hlášky
   * ---
   * responseState obsahuje objekt {category: {error: {message}}}
   * Nejdříve převedeme kategorii (číselný index!) dovnitř error objektu
   * Poté odstraníme všechny prázdné errory
   * Výsledkem je objekt {index: {category, message}}
   */
  const responseState = useContextSelector(
    ResponseStateContext,
    (c) => c.state,
    {} as ResponseStateReducerProps,
  );
  const customMessages = useContextSelector(MessagesContext, (c) => c.customMessages);

  const errors = Object.entries(responseState)
    .filter(([category, errorObject]) => +category !== 6 || !errorObject?.error?.message)
    .map(([category, errorObject]) =>
      errorObject?.error?.message
        ? { ...errorObject?.error, category: parseInt(category, 10) }
        : undefined,
    )
    .filter((errorObject) => errorObject?.message);

  return errors.length || customMessages.length ? (
    /* Sticky element pod headerem, obsahuje všechny errory */
    <div className="sticky top-0 z-40">
      <div className="absolute top-0 w-full">
        {Object.entries(errors).map(([key, error]) => (
          <ErrorAlert
            key={key}
            message={error.message}
            category={error.category}
            errorFieldValue={error?.errorFields?.[key]?.value}
          />
        ))}
        {customMessages.map(({ text, variant }) => (
          <CustomMessageAlert key={text} loginMessage={text} variant={variant} />
        ))}
      </div>
    </div>
  ) : null;
};

export default HeaderAlertBar;
