import { Trans, useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React from 'react';
import classNames from 'classnames';
import { useUser, useUserTickets } from '@web/shop-logic';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { ClickablePopper } from '@/components/atoms/Popper';
import Price from '@/components/atoms/Price';
import IconLink from '@/components/header/IconLink';
import UserAccountPopper from '@/components/header/UserAccountPopper';
import LoginBox from '@/components/login/LoginBox';
import MessagesContext from '@/components/login/MessagesContext';
import useModal from '@/components/modal/useModal';
import URLS from '@/constants/url';
import useContextSelector from '@/hooks/useContextSelector';
import { TicketCircle, TrainTicket } from '@/icons';
import { getUniqueHtmlId } from '@/utils/dataUtils';

interface UserAccountButtonsProps {
  isHeaderTitle?: boolean;
  isIdentity?: boolean;
}

const UserAccountButtons: React.FC<UserAccountButtonsProps> = ({ isHeaderTitle, isIdentity }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { showModal } = useModal();
  const isMessageAnimated = useContextSelector(MessagesContext, (c) => c.isMessageAnimated);
  const { totalCount } = useUserTickets('VALID');

  const { accountCode, firstName, surname, creditPrice, credit, employeeNumber } = user?.user || {};
  const accoutTitleShort = firstName ? `${firstName?.[0] || ''}${surname?.[0] || ''}` : accountCode;
  const accountTitleLong = `${firstName || ''} ${surname || ''}`;

  const openModal = () =>
    showModal(<LoginBox uniqueId={getUniqueHtmlId(`loginBox-${Math.random()}`)} />, {
      size: 'big',
      removePadding: true,
    });

  return accountCode ? (
    <div className="flex items-center">
      <IconLink className="lg:hidden" href={URLS.ACCOUNT}>
        <TicketCircle className="w-3 h-3" />
        <div className="max-w-14 inline-block overflow-ellipsis text-center whitespace-nowrap overflow-hidden text-neutral-black text-11 font-normal">
          {creditPrice || !!credit ? (
            <Price price={credit} />
          ) : (
            <Trans i18nKey="account.myAccount.short" />
          )}
        </div>
      </IconLink>
      <ClickablePopper
        gtmPlace="Header"
        gtmName="Show user account popover"
        ariaLabel={t('accessibility.userAccountMenu')}
        content={
          <UserAccountPopper {...{ accountTitleLong, creditPrice, accountCode, employeeNumber }} />
        }
      >
        <div className="sm:hidden flex items-center mr-2 text-14">
          <IconLink data-id="header-user-account-button">
            <div className="flex justify-center items-center px-1 bg-neutral-black group-hover:bg-primary-blue text-primary-yellow rounded-lg text-12 font-bold">
              <TicketCircle className="-ml-1 w-2 h-2 fill-current" />
              {'\u00a0'}
              {accoutTitleShort}
            </div>
          </IconLink>
          {(user.user?.credit || user.user?.creditPrice) && <Price price={user.user?.credit} />}
        </div>
      </ClickablePopper>
      <IconLink className="relative" data-id="tickets" href={URLS.TICKETS}>
        <TrainTicket className="w-3 h-3 fill-current mr-0.5" />
        <div className="sm:max-w-14 inline-block overflow-ellipsis text-center lg:whitespace-nowrap overflow-hidden sm:text-11 text-14">
          {!!totalCount && (
            <div
              className={classNames(
                'rounded-md px-0.5 bg-secondary-green text-9 text-neutral-white absolute left-[26px] top-[14px]',
                isMessageAnimated && 'animate-login',
              )}
            >
              {totalCount}
            </div>
          )}
          <span className="ml-0.5">
            <Trans i18nKey="header.title.my.journeys" />
          </span>
        </div>
      </IconLink>
    </div>
  ) : (
    <div className="flex items-center">
      <ClickableDiv
        className={classNames(
          'lg:hidden w-full',
          isHeaderTitle && 'px-2 border-neutral-gray3 border-b bg-white',
        )}
        onClick={openModal}
      >
        {isHeaderTitle ? (
          <h3 className="flex items-center gap-1 h3 pt-3 pb-2.5">
            <TicketCircle className="w-3 h-3" />
            <Trans i18nKey="settings.mojeid.login" />
          </h3>
        ) : (
          <div className="flex flex-col items-center w-full text-11 text-black">
            <TicketCircle className="w-3 h-3" />
            <Trans i18nKey="settings.mojeid.login" />
          </div>
        )}
      </ClickableDiv>
      {isIdentity ? (
        <Button
          className="sm:hidden border-none font-normal leading-none whitespace-nowrap"
          data-id="ticket-login-button"
          variant="transparent"
          onClick={openModal}
        >
          <Trans i18nKey="settings.mojeid.ticketLogin" />
        </Button>
      ) : (
        <div className="sm:hidden flex items-center h-full mr-1">
          <Button
            className="flex justify-center items-center pr-3 whitespace-nowrap"
            data-id="login-button"
            variant="transparent"
            onClick={openModal}
          >
            <TicketCircle className="mr-0.5 w-3 h-3" />
            <span className="text-14 leading-none">
              <Trans i18nKey="settings.mojeid.login" />
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default dynamic(() => Promise.resolve(UserAccountButtons), { ssr: false });
