import React from 'react';
import { captureMessage } from '@sentry/nextjs';

export interface ClickableElementProps<Element>
  extends React.DetailedHTMLProps<React.HTMLAttributes<Element>, Element> {
  gtmPlace?: string;
  gtmName?: string;
  onClick: React.MouseEventHandler<Element>;
  isClickable?: boolean;
}

type ClickEvent = React.MouseEvent<Element, MouseEvent>;
type ClickFunction = (e: ClickEvent | KeyboardEvent) => void;
type KeyboardEvent = React.KeyboardEvent<Element>;
type KeyboardFunction = (e: KeyboardEvent) => void;

/**
 * Odesílá data do GTM
 * Lze použít před voláním interakční události
 */
export const sendGtm = (gtmPlace?: string, gtmName?: string): void => {
  if (gtmPlace && gtmName) {
    try {
      /**
       * Měření interakčních click událostí, které nemění URL
       * Například tlačítka "Zobrazit více informací" apod.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).dataLayer.push({
        /**
         * Název komponenty (např. "ConnectionCard")
         * Max. 40 znaků!
         */
        place: gtmPlace,
        /**
         * Popis události (unikátní parametr) [stav]
         * Např. "Show more info (TRAIN_LOW_COST) [CLOSED]"
         * Max. 40 znaků!
         */
        name: gtmName,
        event: 'click',
      });
    } catch (error) {
      captureMessage(`GTM selhalo: ${gtmPlace}, ${gtmName}, ${error}`);
    }
  }
};

/**
 * Vrací funkci, která se posílá do props `onClick`
 * Sestavuje funkci s props (onClick, gtmPlace, gtmName)
 */
export const handleClick =
  ({ onClick, gtmPlace, gtmName }: ClickableElementProps<Element>): ClickFunction =>
  (e: unknown) => {
    sendGtm(gtmPlace, gtmName);
    onClick?.(e as ClickEvent);
  };

/**
 * Chceme povolit simulaci kliknutí pomocí mezerníku nebo enteru
 * https://css-tricks.com/snippets/javascript/javascript-keycodes/#keycode-values
 */
const validHandlingKeyboardKeys = [' ', 'Enter'];

/**
 * Vrací funkci, která se posílá do props `onKeyPress`
 * Pokud je zmáčknuta povolená klávesa, spustí se funkce sestavená v `handleClick()`
 */
export const handleKeyPress =
  ({ onClick, gtmPlace, gtmName }: ClickableElementProps<Element>): KeyboardFunction =>
  (e: KeyboardEvent) => {
    /**
     * Implementace inspirována:
     * https://www.vzhurudolu.cz/blog/190-pristupnost-rohlik#tlacitka
     */
    if (validHandlingKeyboardKeys.includes(e.key)) {
      e.preventDefault();
      const handleClickFunction = handleClick({ onClick, gtmPlace, gtmName });
      return handleClickFunction(e as KeyboardEvent);
    }

    return () => undefined;
  };
