import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import URLS from '@/constants/url';
import {
  TrainTicket,
  StarHoliday,
  PlaneTicket,
  LanguageSchool,
  IconElement,
  HotelIcon,
} from '@/icons';

interface HeaderCommonLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  Icon: IconElement;
  translationKey: string;
  isActive?: boolean;
}

const HeaderCommonLink: React.FC<HeaderCommonLinkProps> = ({
  href,
  Icon,
  translationKey,
  isActive = false,
}) => (
  <a
    className={classNames(
      'flex flex-1 justify-center p-1 hover:text-primary-blue',
      isActive ? 'bg-primary-yellow ' : 'hover:bg-primary-yellow transition-colors',
    )}
    href={href}
  >
    <Icon className="w-2.5 h-2.5 mr-1" />
    <div className="sm:hidden text-14">
      <Trans i18nKey={translationKey} />
    </div>
  </a>
);

interface NavTypesProps {
  trans: string;
  isActive?: boolean;
  href: string;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | React.ComponentType;
}

const navTypes: NavTypesProps[] = [
  { trans: 'top.menu.lang.regiojet', isActive: true, href: URLS.HOMEPAGE, icon: TrainTicket },
  { trans: 'samenu.11', href: 'top.menu.href.air.tickets', icon: PlaneTicket },
  { trans: 'samenu.13', href: 'top.menu.href.vacation', icon: StarHoliday },
  { trans: 'samenu.14', href: 'top.menu.href.hotels', icon: HotelIcon },
  {
    trans: 'top.menu.lang.accommodation',
    href: 'top.menu.href.lang.accommodation',
    icon: LanguageSchool,
  },
];

const HeaderCommonForSAHolding: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-primary-yellowlight2 relative">
      <nav className="page-layout--desktop flex">
        {navTypes.map(({ trans, isActive, href, icon }) => (
          <HeaderCommonLink
            key={trans}
            href={t(href)}
            Icon={icon}
            translationKey={trans}
            isActive={isActive}
          />
        ))}
      </nav>
    </div>
  );
};
export default HeaderCommonForSAHolding;
