import { useRouter } from 'next/router';
import React, { ComponentType } from 'react';
import { Language } from '@web/shop-logic/dist/hooks/types';
import {
  CzechFlag,
  SlovakiaFlag,
  UkFlag,
  GermanyFlag,
  HungaryFlag,
  IconElementProps,
  AustriaFlag,
  UkraineFlag,
  PolandFlag,
} from '@/icons';

const flagIcon: Record<Language, ComponentType<IconElementProps>> = {
  'de-AT': AustriaFlag,
  cs: CzechFlag,
  de: GermanyFlag,
  en: UkFlag,
  hu: HungaryFlag,
  sk: SlovakiaFlag,
  uk: UkraineFlag,
  pl: PolandFlag,
};

interface HeaderLocalizedProps {
  selectedLanguage?: Language;
}

const HeaderLocalizedFlag: React.FC<HeaderLocalizedProps> = ({ selectedLanguage }) => {
  const { locale } = useRouter();
  const Icon = flagIcon[selectedLanguage || locale];

  return Icon ? (
    <div className="px-1">
      <Icon className="w-2.5" />
    </div>
  ) : null;
};
export default HeaderLocalizedFlag;
