import remarkGfm from 'remark-gfm';
import React, { isValidElement, ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import LinkableHeader from '@/components/atoms/LinkableHeader';
import env from '@/constants/env';

const getId = (children) => {
  const child = children?.[0];
  const id = isValidElement(child)
    ? (child as ReactElement)?.props?.children?.toString()
    : child?.toString();
  return id;
};

const floatMap: Record<string, string> = {
  left: 'lg:float-left',
  right: 'lg:float-right',
};

const resolveFloatClass = (src) => {
  const arr = src.split('#');
  if (arr.length === 1) return null;
  const alignment = arr[arr.length - 1];
  if (alignment === 'left' || alignment === 'right') {
    return floatMap[alignment];
  }

  return null;
};

interface RichParagraphProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  fullWidth?: boolean;
}

const RichParagraph: React.FC<RichParagraphProps> = ({
  text,
  fullWidth,
  className,
  ...rootProps
}) =>
  text ? (
    <div {...rootProps} className={classNames([className, 'wysiwyg wysiwyg-spacing'])}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        transformImageUri={(input) =>
          /^https?:/.test(input) ? input : `${env.STRAPI_URL}${input}`
        }
        components={{
          table: (props) => (
            <div className="overflow-auto">
              <table className={classNames(fullWidth && 'w-full')} {...props} />
            </div>
          ),
          img: ({ src, ...props }) => {
            const floatClass = resolveFloatClass(src);
            return (
              <img
                src={src}
                className={classNames(floatClass && floatClass, 'sm:block lg:inline sm:my-0.5')}
                {...props}
              />
            );
          },
          ol: (props) => <ol className="list-decimal list-inside" {...props} />,
          h1: ({ children }) => (
            <LinkableHeader headlineLevel={1} id={getId(children)}>
              {children}
            </LinkableHeader>
          ),
          h2: ({ children }) => (
            <LinkableHeader headlineLevel={2} id={getId(children)}>
              {children}
            </LinkableHeader>
          ),
          h3: ({ children }) => (
            <LinkableHeader headlineLevel={3} id={getId(children)}>
              {children}
            </LinkableHeader>
          ),
          h4: ({ children }) => (
            <LinkableHeader headlineLevel={4} id={getId(children)}>
              {children}
            </LinkableHeader>
          ),
          h5: ({ children }) => (
            <LinkableHeader headlineLevel={5} id={getId(children)}>
              {children}
            </LinkableHeader>
          ),
        }}
      >
        {text}
      </ReactMarkdown>
    </div>
  ) : null;

export default RichParagraph;
