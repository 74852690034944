import React from 'react';
import classNames from 'classnames';
import { ChevronDown } from '@/icons';

interface ChevronProps extends React.HTMLProps<HTMLDivElement> {
  isOpened?: boolean;
}
const Chevron: React.FC<ChevronProps> = ({ isOpened, className, ...props }) => (
  <div className={classNames('inline-flex justify-center items-center', className)} {...props}>
    <ChevronDown
      className={classNames(
        'w-2.5 h-2.5 lg:w-3 lg:h-3 fill-current transition-transform',
        isOpened && 'transform -rotate-180',
      )}
    />
  </div>
);

export default Chevron;
