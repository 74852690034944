import { Trans } from 'next-i18next';
import React, { FC } from 'react';

const NoScriptDialog: FC = () => (
  <noscript>
    <dialog
      open
      style={{
        position: 'fixed',
        left: '50vw',
        top: '50vh',
        transform: 'translate(-50%, -50%)',
        margin: 0 /*reset some browser centering*/,
        padding: '2em',
        backgroundColor: '#FABB00',
        zIndex: 999,
      }}
    >
      <Trans i18nKey="javascript.disabled.dialog" />
    </dialog>
  </noscript>
);

export default NoScriptDialog;
