import React from 'react';
import classNames from 'classnames';
import env from '@/constants/env';
import { UploadFile } from '@/models/types';

export interface StrapiImageProps extends Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'> {
  image: Pick<UploadFile, 'alternativeText' | 'url' | 'width' | 'height' | 'formats' | 'name'>;
  alt?: string;
  fitCover?: boolean;
  loading?: 'eager' | 'lazy';
}
export const fitCoverClasses = 'w-full h-full object-cover object-top';

const StrapiImage: React.FC<StrapiImageProps> = ({
  image,
  alt,
  fitCover = true,
  loading = 'lazy',
  className,
  ...props
}) => {
  const mobileImage = image?.formats?.mobile;
  const desktopImage = image?.formats?.desktop;

  /**
   * Pokud název souboru končí znaky `@2x`, `@3x` apod.,
   * znamená to, že je vyexportován ve dvoj/trojnásobné velikosti
   * Do HTML atributů width a height musíme poslat původní rozměry,
   * jinak se obrázek zobrazí zvětšený
   *
   * Regex: testuje výskyt znaku `@`, čísla, a znaku `.` (tečka před příponou souboru)
   * Vrací číslo mezi znaky `@` a `.`
   *
   * Defaultní kvalita obrázku je 1.
   */
  const isRetina = (image?.name || '').match(/@(\d{1})x\./);
  const retinaQuality = parseInt(isRetina?.[1], 10) || 1;

  return image ? (
    <picture>
      {mobileImage && (
        /**
         * Mobilní obrázek
         * Největší podporovaná šířka: 420px
         * Media queries pro retina @2x: 420x2 = 840px
         *
         * V `srcset` je u url hodnota `2x`
         * Mobil s retinou např. @3x posílá do media queries hodnotu 420x3 = 1260px
         * Prohlížeč to vyhodnotí tak, že jim pošle desktopový obrázek
         * Tímto donutíme (všechny) mobily, aby si stáhly mobilní obrázek, i když nedosahuje kvalit jejich obrazovky
         */
        <source media="(max-width: 840px)" srcSet={`${env.STRAPI_URL + mobileImage.url} 4x`} />
      )}
      {desktopImage && (
        /**
         * Desktopový obrázek
         * Největší podporovaná šířka: 1920px
         *
         * U obrázku na celou šířku je vhodné nastavit fitCover = true,
         * pak se obrázek "zoomuje" tak, aby zakryl celou plochu (i za cenu, že se rozmaže)
         */
        <source media="(min-width: 841px)" srcSet={env.STRAPI_URL + desktopImage.url} />
      )}
      {/*
       * Originální obrázek
       */}
      <img
        src={env.STRAPI_URL + image.url}
        alt={alt || image.alternativeText}
        width={image.width / retinaQuality}
        height={image.height / retinaQuality}
        className={classNames(fitCover && fitCoverClasses, className)}
        loading={loading}
        {...props}
      />
    </picture>
  ) : null;
};

export default StrapiImage;
