import { ModalInput } from '@/components/modal/Modal';
import { State, StateElement, ActionMap, ModalComponentProps } from '@/components/modal/modalTypes';

export enum Types {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  HIDEALL = 'HIDEALL',
  UPDATE = 'UPDATE',
  DESTROY = 'DESTROY',
  UNKNOWN = 'UNKNOWN',
}

type Payload = {
  [Types.SHOW]: StateElement & { id: string };
  [Types.HIDE]: { id: string };
  [Types.HIDEALL]: never;
  [Types.UPDATE]: { id: string; props: ModalComponentProps<ModalInput> };
  [Types.DESTROY]: { id: string };
  [Types.UNKNOWN]: undefined;
};

type Action = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {};

export default function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SHOW: {
      const { id, component, props } = action.payload;

      // Instead of adding new modal to state,
      // modify existing modal with new arguments
      // (no need to destroy previous modal, which leads to flickering animation)
      const permanentModalId = Object.keys(state)[0] || id;

      return {
        [permanentModalId]: {
          ...state[permanentModalId],
          component,
          props: {
            ...state[permanentModalId]?.props,
            ...props,
            // Force reset `undefined` props
            open: true,
            removePadding: props?.removePadding,
            size: props?.size,
            unclosable: props?.unclosable,
            unmountOnClose: props?.unmountOnClose,
            deleteBorder: props?.deleteBorder,
            title: props?.title,
          },
        },
      };
    }
    case Types.HIDE: {
      const { id } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id],
          props: {
            // Remove old props (otherwise it would be merged into new state with next opened modal)
            // ...state[id].props,
            // Preserve size during closing fade animation
            size: state[id].props?.size,
            open: false,
          },
        },
      };
    }
    case Types.HIDEALL: {
      const permanentModalId = Object.keys(state)[0];

      if (!permanentModalId) {
        return state;
      }

      return {
        [permanentModalId]: {
          ...state[permanentModalId],
          props: { ...(state[permanentModalId]?.props || {}), open: false },
        },
      };
    }
    case Types.UPDATE: {
      const { id, props } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id],
          props: {
            ...state[id].props,
            ...props,
          },
        },
      };
    }
    case Types.DESTROY: {
      const { id } = action.payload;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    default:
      throw new Error('Unexpected action');
  }
}
