import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useContext, useContextSelector } from 'use-context-selector';
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useUser, useHeaders, useUserActions, useBookingActions } from '@web/shop-logic';
import Alert from '@/components/atoms/Alert';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import FormInput from '@/components/atoms/FormInput';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import { LoginPage } from '@/components/login/LoginBox';
import MessagesContext from '@/components/login/MessagesContext';
import ModalContext from '@/components/modal/ModalContext';
import useModal from '@/components/modal/useModal';
import RequestPasswordResetBox from '@/components/requestPasswordReset/RequestPasswordResetBox';
import URLS from '@/constants/url';
import useFieldValidation from '@/hooks/useFieldValidation';
import { ArrowBack } from '@/icons';

interface LoginFormProps {
  onLogin?: () => Promise<void>;
  backToLoginOptions: () => void;
  lastCreditTicket: string;
  loginPage: LoginPage;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onLogin,
  backToLoginOptions,
  lastCreditTicket,
  loginPage,
}) => {
  const { user } = useUser();
  const { login, error } = useUserActions();
  const { clearBooking } = useBookingActions();
  const { currency } = useHeaders();
  const { showModal } = useModal();
  const { destroyModal, state } = useContext(ModalContext);
  const pushToMessages = useContextSelector(MessagesContext, (c) => c.pushToMessages);
  const { push } = useRouter();
  const { t } = useTranslation();
  const { yupField } = useFieldValidation();

  const openForgotPasswordModal = () =>
    showModal(<RequestPasswordResetBox />, { size: 'big', title: t('forgotPassword.title') });

  useEffect(() => {
    const callEffect = async () => {
      pushToMessages({ text: t('user.success.login.credit') });
      destroyModal(Object.keys(state)[0]);
      await onLogin?.();
      if (user.user.currency !== currency) {
        clearBooking();
        await push(URLS.HOMEPAGE);
      }
    };

    if (user.user) callEffect();
  }, [user.user]);

  const initialValues = {
    accountCode: loginPage === 'lastCreditTicket' ? lastCreditTicket : '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup
        .object()
        .shape({ accountCode: yupField.accountCode, password: yupField.password })}
      onSubmit={(values) => login(values)}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col items-start w-full">
          <span className="font-bold mb-1.5">
            <Trans i18nKey="creditAccountInfo.title" />
          </span>
          {!!error?.message && (
            <Alert variant="warning" className="mb-1">
              <Trans i18nKey={error.message} />
            </Alert>
          )}
          <div className="mb-2 w-full">
            <FormInput
              name="accountCode"
              onlyNumbers
              className="w-full"
              label={t('loginBox.accountCode')}
            />
          </div>
          <div className="mb-2 w-full">
            <FormInput name="password" password className="w-full" label={t('loginBox.password')} />
          </div>

          <div className="text-14 text-neutral-gray mb-1">
            <Trans i18nKey="loginbox.credit.form.info" />{' '}
          </div>
          <ClickableDiv
            className="mb-4 text-14 hover:text-primary-blue hover:underline"
            onClick={openForgotPasswordModal}
          >
            <Trans i18nKey="loginBox.forgotPassword" />
          </ClickableDiv>

          <div className="flex self-center lg:justify-between sm:flex-col w-full items-center">
            <Button type="submit" size="big" className="sm:w-full" disabled={isSubmitting}>
              <Trans i18nKey="settings.mojeid.login" />
            </Button>
            <ClickableDiv
              onClick={backToLoginOptions}
              className="text-primary-blue flex lg:order-first sm:mt-4"
            >
              <ArrowBack className="w-3 h-3 fill-current mr-1" />
              <span>
                <Trans i18nKey="loginBox.backOption" />
              </span>
            </ClickableDiv>
          </div>
          <FullScreenLoader showLoader={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
