import { Trans, useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React from 'react';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import ExternalLink from '@/components/atoms/ExternalLink';
import CookiesContext from '@/components/cookiesPreferences/CookiesContext';
import CookiesPreferencesModal from '@/components/cookiesPreferences/CookiesPreferencesModal';
import useModal from '@/components/modal/useModal';
import useContext from '@/hooks/useContext';
import { Cookies } from '@/icons';

const CookiesBar: React.FC = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { allowAllCookies, isCookiesSet } = useContext(CookiesContext);
  // Otevři modal 2. úrovně
  const openPreferencesModal = () => showModal(<CookiesPreferencesModal />, { size: 'big' });

  return !isCookiesSet ? (
    <div className="font-sans fixed z-[100] inset-x-0 bottom-0 bg-neutral-white shadow-popper sm:rounded-t-sm">
      <div className="page-layout max-h-screen overflow-y-auto py-4 lg:py-5">
        <h1 className="h2 flex items-center gap-2">
          <Cookies className="w-4 h-4 rounded-full p-0.5 fill-primary-blue bg-primary-bluelight3" />
          <Trans i18nKey="cookies.header" />
        </h1>
        <div className="mt-2 mb-4">
          <Trans
            i18nKey="cookies.main.info"
            components={{
              linkPrivacy: <ExternalLink href={t('ticket.passengerModal.info.conditionsUrl')} />,
              linkCookies: <ExternalLink href={t('footer.cookies.href')} />,
            }}
          />
          <ClickableDiv
            className="inline text-primary-blue underline"
            onClick={openPreferencesModal}
            gtmPlace="CookiesAcceptModal"
            gtmName="Open preferences modal"
          >
            <Trans i18nKey="cookies.setting" />
          </ClickableDiv>
          .
        </div>
        <div className="flex sm:flex-col gap-2 justify-end">
          <Button
            size="big"
            variant="secondary"
            className="sm:w-full lg:px-4"
            onClick={openPreferencesModal}
            gtmPlace="CookiesAcceptModal"
            gtmName="Open preferences modal"
          >
            <Trans i18nKey="cookies.changeSetting" />
          </Button>
          <Button
            size="big"
            className="sm:w-full lg:px-4 sm:order-first"
            onClick={allowAllCookies}
            gtmPlace="CookiesAcceptModal"
            gtmName="Accept all"
          >
            <Trans i18nKey="cookies.accept" />
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default dynamic(() => Promise.resolve(CookiesBar), { ssr: false });
