import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import {
  FormControl,
  FormHelperText,
  CheckboxProps as CheckboxComponentProps,
} from '@mui/material';
import CheckboxComponent from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox as CheckboxFullIcon, CheckboxEmpty as CheckboxEmptyIcon } from '@/icons';
import { ClickableElementProps, sendGtm } from '@/utils/clickableUtils';
import { getUniqueHtmlId } from '@/utils/dataUtils';

const useStyles = makeStyles()({
  primary: {
    padding: '6px',
    color: '#00519E',

    '&.Mui-checked': {
      color: '#00519E',
    },

    '&.Mui-disabled': {
      color: '#92A4AD',
    },
  },
  secondary: {
    padding: '6px',
    color: '#92A4AD',

    '&.Mui-checked': {
      color: '#00519E',
    },

    '&.Mui-disabled': {
      color: '#92A4AD',
    },
  },
  black: {
    padding: '6px',
    color: '#00519E',

    '&.Mui-checked': {
      color: '#00519E',
    },

    '&.Mui-disabled': {
      color: '#92A4AD',
    },
  },
  alternative: {
    padding: '0px',
    color: '#92A4AD',

    '&.Mui-checked': {
      color: '#00519E',
    },

    '&.Mui-disabled': {
      color: '#92A4AD',
    },
  },
  icon: { 'input:focus-visible + &': { outline: '2px solid #00519E' } },
  labelRoot: { margin: 0 },
  labelRootPrimary: {
    margin: 0,
    backgroundColor: '#E7F2FE',
    padding: '8px 6px',
    borderRadius: '6px',
  },
  label: {
    fontSize: '13px',
    color: '#5E7181',
  },
  labelPrimary: {
    fontSize: '13px',
    color: '#00519E',
  },
  labelBlack: {
    fontSize: 'inherit',
    color: '#000',
  },
  labelAlternative: {
    fontSize: 'inherit',
    color: '#000',
    padding: '0px 8px',
  },
});

export interface CheckboxProps
  extends CheckboxComponentProps,
    Pick<ClickableElementProps<HTMLInputElement>, 'gtmPlace' | 'gtmName'> {
  hasCustomStateControl?: boolean;
  label?: string | React.ReactNode;
  checked?: boolean;
  errorText?: string;
  variant?: 'primary' | 'secondary' | 'black' | 'alternative';
}

const Checkbox: React.FC<CheckboxProps> = ({
  hasCustomStateControl = false,
  label = '',
  checked = false,
  errorText,
  onChange,
  variant = 'secondary',
  className,
  gtmPlace,
  gtmName,
  id,
  ...props
}) => {
  const { classes } = useStyles();
  const [isChecked, setIsChecked] = useState(checked);
  const htmlId = id || getUniqueHtmlId(props.name);
  const htmlChecked = hasCustomStateControl ? checked : isChecked;

  const mapRootClassesToVariant = {
    primary: classes.labelRootPrimary,
    secondary: classes.labelRoot,
    black: classes.labelRoot,
    alternative: classes.labelRoot,
  };
  const mapLabelClassesToVariant = {
    primary: classes.labelPrimary,
    secondary: classes.label,
    black: classes.labelBlack,
    alternative: classes.labelAlternative,
  };
  const mapVariantToVariant = {
    primary: classes.primary,
    secondary: classes.secondary,
    black: classes.black,
    alternative: classes.alternative,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    sendGtm(gtmPlace, gtmName);
    if (hasCustomStateControl) {
      onChange?.(event, event.target.checked);
    } else {
      setIsChecked(event.target.checked);
    }
  };

  return (
    <FormControl error={!!errorText?.length} fullWidth={variant === 'primary'}>
      <FormControlLabel
        label={<span>{label}</span>}
        classes={{
          root: mapRootClassesToVariant[variant],
          label: mapLabelClassesToVariant[variant],
        }}
        htmlFor={htmlId}
        control={
          <CheckboxComponent
            // Sestaví bezpečný string z atributu `name` (a11y: propojení inputu s labelem)
            id={htmlId}
            checked={htmlChecked}
            inputProps={{ 'aria-checked': htmlChecked }}
            onChange={handleChange}
            className={classNames(mapVariantToVariant[variant], className)}
            icon={
              <CheckboxEmptyIcon
                className={classNames('w-3 h-3 max-h-3 flex-shrink-0 fill-current', classes.icon)}
              />
            }
            checkedIcon={
              <CheckboxFullIcon
                className={classNames('w-3 h-3 max-h-3 flex-shrink-0 fill-current', classes.icon)}
              />
            }
            disableRipple
            {...props}
          />
        }
      />
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default Checkbox;
