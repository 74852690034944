import Alert from './Alert';
import React from 'react';
import { ResponseStateContext } from '@web/shop-logic';
import useContextSelector from '@/hooks/useContextSelector';

interface ResponseStateAlertProps {
  categoryStateKey: number;
  displayErrorFields?: boolean;
}

const ResponseStateAlert: React.FC<ResponseStateAlertProps> = ({
  categoryStateKey,
  displayErrorFields,
}) => {
  const categoryState = useContextSelector(ResponseStateContext, (c) => c.state[categoryStateKey]);

  const errorFields = categoryState?.error?.errorFields || [];
  const message = categoryState?.error?.message || '';

  const errorFieldMessages = errorFields.reduce((acc, errorField) => {
    return acc + `\n${errorField?.value}`;
  }, '');

  return !!message || !!errorFieldMessages ? (
    <>
      <Alert variant="warning" className="mb-1">
        {!!message && message}
        {!!errorFieldMessages && displayErrorFields && errorFieldMessages}
      </Alert>
    </>
  ) : null;
};

export default ResponseStateAlert;
