import React from 'react';
import HeaderLinks, { HeaderLinksProps } from '@/components/header/HeaderLinks';

interface HeaderLinksSingleRowProps extends HeaderLinksProps {
  setShowSubmenu: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      linkIndex: number;
      prevShow?: boolean;
      prevIndex?: number;
    }>
  >;
  setClosedPopper: (closePopper: boolean) => void;
}

const HeaderLinksSingleRow: React.FC<HeaderLinksSingleRowProps> = ({
  menuData,
  setShowSubmenu,
  setClosedPopper,
}) => (
  <div className="sm:hidden w-full lg:bg-neutral-graylight shadow-headerMenuTop shadow-headerMenu relative h-[48px]">
    <nav className="page-layout flex items-center">
      <HeaderLinks
        menuData={menuData}
        setShowSubmenu={setShowSubmenu}
        setClosedPopper={setClosedPopper}
      />
    </nav>
  </div>
);
export default HeaderLinksSingleRow;
