import { Trans } from 'next-i18next';
import React from 'react';
import RegistrationForm from '@/components/registration/RegistrationForm';

const RegistrationBox: React.FC = () => (
  <div className="sm:px-2 sm:py-4 p-5">
    <h2 className="h2 mb-2 -mt-1">
      <Trans i18nKey="registration.modal.header" />
    </h2>
    <RegistrationForm />
  </div>
);

export default RegistrationBox;
