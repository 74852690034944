import { Trans } from 'next-i18next';
import * as React from 'react';
import { Button } from '@/components/atoms/Button';
import { useIdentity } from '@/components/identity/IdentityProvider';
import { DirectionsTrain } from '@/icons';

const IdentityDesktop = () => {
  const { session, loading, register, handleSignIn, handleSignOut } = useIdentity();

  if (loading) return null;

  return session ? (
    <div className="flex items-center gap-1">
      {/* Todo: temporary sing out */}
      <Button onClick={handleSignOut} disabled={loading}>
        Odhlášení z ID
      </Button>

      <div className="bg-neutral-white rounded-full p-1">
        <DirectionsTrain className="w-[28px] h-auto fill-primary-blue" />
      </div>

      <span className="text-14 font-bold">{session.user.name}</span>
    </div>
  ) : (
    <>
      <div className="h-6 border-l border-l-black mr-2" />

      <div className="flex items-center gap-1">
        <Button onClick={handleSignIn} disabled={loading} variant="transparentBlue">
          <Trans i18nKey="settings.identity.desktopSignIn" />
        </Button>

        <Button onClick={register} disabled={loading}>
          <Trans i18nKey="settings.identity.desktopSignUp" />
        </Button>
      </div>
    </>
  );
};

export default IdentityDesktop;
