import { createContext } from 'use-context-selector';
import {
  CookiesPreferences,
  CookiesPreferencesProps,
} from '@/components/cookiesPreferences/CookiesProvider';

export const initialContextState = {
  initialPreferences: {} as CookiesPreferences,
  minimalAllowedPreferences: {} as CookiesPreferences,
  cookiesValues: {} as CookiesPreferences,
  setCookiesValues: (): void => undefined,
  saveCookiesPreferences: (): void => undefined,
  isCookiesSet: true,
  allowAllCookies: (): void => undefined,
  rejectAllCookies: (): void => undefined,
};

const CookiesContext = createContext<CookiesPreferencesProps>(initialContextState);

export default CookiesContext;
