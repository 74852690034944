import React from 'react';
import { Currency } from '@web/shop-logic/dist/hooks/types';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import CurrencySymbol from '@/components/atoms/CurrencySymbol';
import { headerCurrencies } from '@/components/header/headerLangAndCurrency';

interface HeaderCurrenciesProps {
  handleCurrency: (currency: Currency) => void;
}

const HeaderCurrencies: React.FC<HeaderCurrenciesProps> = ({ handleCurrency }) => (
  <div className="mr-2">
    {headerCurrencies.map((currency) => (
      <ClickableDiv
        key={currency}
        onClick={() => handleCurrency(currency)}
        className="w-full lg:flex gap-2 lg:justify-between sm:py-1 border border-transparent hover:border-primary-blue pr-1 rounded-sm text-14 sm:my-1"
      >
        <span className="inline-block w-5 text-center">
          <CurrencySymbol currency={currency} />
        </span>
        {currency}
      </ClickableDiv>
    ))}
  </div>
);

export default HeaderCurrencies;
