import { setCookie } from 'cookies-next';
import { generateCodeVerifier, generateCodeChallenge } from '@/utils/pkceUtils';

/**
 * Creates a registration URL for the OAuth2.0/OpenID Connect flow using PKCE.
 * It generates a code verifier and challenge, and stores the code verifier in a cookie.
 */
export const createRegistrationUrl = (
  registrationUrl: string,
  clientId: string,
  redirectUri: string,
) => {
  const params = new URLSearchParams();
  params.set('client_id', clientId);
  params.set('redirect_uri', redirectUri);
  params.set('scope', 'openid profile email');
  params.set('response_type', 'code');
  params.set('response_mode', 'fragment');

  const codeVerifier = generateCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const codeChallengeMethod = 'S256';

  params.set('code_challenge', codeChallenge);
  params.set('code_challenge_method', codeChallengeMethod);

  setCookie('pkce_code_verifier', codeVerifier, {
    httpOnly: true,
    secure: process.env.NODE_ENV === 'production',
    path: '/',
  });

  return `${registrationUrl}?${params.toString()}`;
};
