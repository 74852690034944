import { useRouter } from 'next/router';
import React from 'react';
import { useHeaders } from '@web/shop-logic';
import { Currency } from '@web/shop-logic/dist/hooks/types';

export const formatPrice = (price: number, currency: Currency, language: string): string => {
  const currencyFraction = currency === 'EUR' ? 1 : 0;
  const formatter = new Intl.NumberFormat(language, {
    currency,
    style: 'currency',
    minimumFractionDigits: currencyFraction,
    maximumFractionDigits: currencyFraction,
  });

  return formatter.format(price);
};

export const getLocaleCurrency = (locale: string): Currency => (locale === 'cs' ? 'CZK' : 'EUR');

interface PriceProps {
  price: number;
  isLocaleBased?: boolean;
  customCurrency?: Currency;
}

// @ts-expect-error String should be valid: https://stackoverflow.com/a/70895599/7125721
const Price: React.FC<PriceProps> = ({ price, isLocaleBased, customCurrency }) => {
  const { currency: storeCurrency } = useHeaders();
  const { locale } = useRouter();

  const currency = customCurrency ?? storeCurrency;

  return formatPrice(price, isLocaleBased ? getLocaleCurrency(locale) : currency, locale);
};
export default Price;
