import { CookiesPreferences } from './CookiesProvider';
import { Trans, useTranslation } from 'next-i18next';
import { useContextSelector, useContext } from 'use-context-selector';
import { useImmer } from 'use-immer';
import React, { useMemo } from 'react';
import { Button } from '@/components/atoms/Button';
import CookiesContext from '@/components/cookiesPreferences/CookiesContext';
import PreferenceBox from '@/components/cookiesPreferences/PreferenceBox';
import ModalContext from '@/components/modal/ModalContext';
import { Cookies } from '@/icons';

const CookiesPreferencesModal: React.FC = () => {
  const { t } = useTranslation();

  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);

  const { cookiesValues, saveCookiesPreferences, allowAllCookies, rejectAllCookies } =
    useContext(CookiesContext);

  const parsedCookies = useMemo(() => {
    return Object.entries(cookiesValues).reduce((acc, [key, value]) => {
      if (key === 'required') {
        acc[key] = true;
        return acc;
      }
      // If value is undefined, set it to false, otherwise use the original value
      acc[key] = value === undefined ? false : value;
      return acc;
    }, {} as CookiesPreferences);
  }, [cookiesValues]);

  const [cookiesFormValues, setCookiesFormValues] = useImmer<CookiesPreferences>(parsedCookies);

  const handleAllowAll = () => {
    allowAllCookies();
    hideAllModals();
  };

  const handleRejectAll = () => {
    rejectAllCookies();
    hideAllModals();
  };

  const handleSave = () => {
    // Uloží hodnoty do cookies
    saveCookiesPreferences(cookiesFormValues);
    hideAllModals();
  };

  return (
    <div className="font-sans">
      <h1 className="h2 flex items-center gap-2">
        <Cookies className="w-4 h-4 rounded-full p-0.5 fill-primary-blue bg-primary-bluelight3" />
        <Trans i18nKey="cookies.setting" />
      </h1>
      <p className="mt-2 mb-4">
        <Trans i18nKey="cookies.setting.info" />
      </p>
      <div className="flex sm:flex-col justify-center gap-2">
        {/* Tlačítko: Povolit všechny cookies */}
        <Button
          size="big"
          className="sm:w-full lg:px-4"
          onClick={handleAllowAll}
          gtmPlace="CookiesPreferencesModal"
          gtmName="Accept all (top)"
        >
          <Trans i18nKey="cookies.accept" />
        </Button>

        {/* Tlačítko: Zakázat všechny cookies */}
        <Button
          size="big"
          variant="secondary"
          className="sm:w-full lg:px-4"
          onClick={handleRejectAll}
          gtmPlace="CookiesPreferencesModal"
          gtmName="Reject all (top)"
        >
          <Trans i18nKey="cookies.reject" />
        </Button>
      </div>

      {/**
       * Vlastní preference
       */}
      <h2 className="h2 mt-3 border-b border-neutral-gray3 pb-2">
        <Trans i18nKey="cookies.preferences" />
      </h2>

      {/* Požadované soubory cookie */}
      <PreferenceBox
        header={t('cookies.required.header')}
        paragraph={t('cookies.required.text')}
        name="cookiesRequiredAccept"
        checked={cookiesFormValues?.required}
        onChange={undefined}
      />

      {/* Soubory cookie pro zvýšení výkonu */}
      <PreferenceBox
        header={t('cookies.performance.header')}
        paragraph={t('cookies.performance.text')}
        name="cookiesPerformanceAccept"
        checked={cookiesFormValues?.performance}
        onChange={() =>
          setCookiesFormValues((draft) => {
            draft.performance = !draft.performance;
          })
        }
      />

      {/* Cílené soubory cookie */}
      <PreferenceBox
        header={t('cookies.marketing.header')}
        paragraph={t('cookies.marketing.text')}
        name="cookiesMarketingAccept"
        checked={cookiesFormValues?.marketing}
        onChange={() =>
          setCookiesFormValues((draft) => {
            draft.marketing = !draft.marketing;
          })
        }
      />

      {/* Funkční soubory cookie */}
      <PreferenceBox
        header={t('cookies.functional.header')}
        paragraph={t('cookies.functional.text')}
        name="cookiesFunctionalAccept"
        checked={cookiesFormValues?.functional}
        onChange={() =>
          setCookiesFormValues((draft) => {
            draft.functional = !draft.functional;
          })
        }
      />

      {/* Funkční soubory cookie */}
      <PreferenceBox
        header={t('cookies.userdata.header')}
        paragraph={t('cookies.userdata.text')}
        name="cookiesUserDataAccept"
        checked={cookiesFormValues?.userData}
        onChange={() =>
          setCookiesFormValues((draft) => {
            draft.userData = !draft.userData;
          })
        }
      />

      {/* Funkční soubory cookie */}
      <PreferenceBox
        header={t('cookies.personalization.header')}
        paragraph={t('cookies.personalization.text')}
        name="cookiesPersonalizationAccept"
        checked={cookiesFormValues?.personalization}
        onChange={() =>
          setCookiesFormValues((draft) => {
            draft.personalization = !draft.personalization;
          })
        }
      />

      <div className="flex flex-wrap gap-2 justify-end">
        <Button
          size="big"
          variant="primary"
          onClick={handleSave}
          className="sm:w-full mt-2"
          gtmPlace="CookiesPreferencesModal"
          gtmName="Save custom preferences"
        >
          <Trans i18nKey="cookies.save" />
        </Button>
      </div>
    </div>
  );
};

export default CookiesPreferencesModal;
