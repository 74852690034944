import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { useUser, useUserActions, ResponseStateContext } from '@web/shop-logic';
import { Notifications, RegisterPayload } from '@web/shop-logic/dist/hooks/useUser';
import Alert from '@/components/atoms/Alert';
import { Button } from '@/components/atoms/Button';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import useModal from '@/components/modal/useModal';
import PersonalDataStep from '@/components/registration/PersonalDataStep';
import RegistrationSuccessBox from '@/components/registration/RegistrationSuccessBox';
import SettingsStep from '@/components/registration/SettingsStep';
import Tariff from '@/components/registration/tariffOptions';
import useRegistrationSchema from '@/components/registration/useRegistrationSchema';
import URLS from '@/constants/url';
import useCaptchaHeader from '@/hooks/useCaptchaHeader';
import useContextSelector from '@/hooks/useContextSelector';

interface RegisterFormPayload extends Omit<RegisterPayload, 'notifications'> {
  notifications: Notifications;
  passwordConfirmed: string;
}

const defaultInitialValues: RegisterFormPayload = {
  firstName: '',
  surname: '',
  email: '',
  phoneNumber: '',
  companyInformation: false,
  company: { companyName: '', address: '', registrationNumber: '', vatNumber: '' },
  password: '',
  passwordConfirmed: '',
  defaultTariff: Tariff.Regular,
  currency: 'CZK',
  notifications: { newsletter: false, reservationChange: true, routeRatingSurvey: true },
  agreeWithTerms: false,
};

enum FormSteps {
  PERSONAL_DATA = 'PERSONAL_DATA',
  SETTINGS = 'SETTINGS',
  ERROR = 'ERROR',
}

/** Zobrazuje chybové hlášky z backendu
 * Pokud z requestu přijdou errory ke konkrétním polím formuláře,
 * zapíše je do Formik contextu, a ten je sám zobrazí.
 */
const HandleBackendErrors: React.FC = () => {
  const categoryState = useContextSelector(ResponseStateContext, (c) => c.state[12]);
  const errorFields = categoryState?.error?.errorFields;
  const { setFieldError } = useFormikContext();
  const { t } = useTranslation();

  useEffect(() => {
    Object.keys(errorFields || {})?.forEach((objKey) => {
      const { key, value } = errorFields[objKey];
      setFieldError(key, t(value));
    });
  }, [errorFields]);

  return null;
};

/** Registrační formulář
 * Formulář je rozdělen na 2 části, po vyplnění a zvalidování obou částí se pošle request
 * Pokud backend vrátí chybu, ukážou se obě části zároveň
 * Vše je v jednom formuláři s jedním submit tlačítkem, validationSchema se mění podle aktuálního kroku
 * Přepnutí na další krok probíhá ve funkci onSubmit
 * Po odeslání requestu se zobrazí modal a uživatel je (na pozadí pod modalem) automaticky přihlášen
 */
const RegistrationForm: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { register, registerOpenTicket } = useUserActions();
  const categoryState = useContextSelector(ResponseStateContext, (c) => c.state[12]);
  const { showModal } = useModal();
  const { pathname, push } = useRouter();
  const { applyCaptcha } = useCaptchaHeader();

  const userIsOpenTicket = user.user?.creditPrice === false;

  const { personalDataSchema, settingsSchema, completeSchema } =
    useRegistrationSchema(userIsOpenTicket);

  const [formActiveStep, setFormActiveStep] = useState<FormSteps>(FormSteps.PERSONAL_DATA);
  const [initialValues, setInitialValues] = useState<RegisterFormPayload>(defaultInitialValues);

  const message = categoryState?.error?.message;

  useEffect(() => {
    /**
     * Přihlášený uživatel s otevřenou jízdenkou se může registrovat
     * Zde se předvyplní některé hodnoty, které mohl zadat při předchozí registraci
     * Zároveň se z formuláře smažou pole, které aktuálně backend neumí změnit
     */
    if (userIsOpenTicket) {
      const newInitialValues = {
        ...initialValues,
        firstName: user.user?.firstName || '',
        surname: user.user?.surname || '',
        email: user.user?.email || '',
        phoneNumber: user.user?.phoneNumber || '',
        company: undefined,
        companyInformation: undefined,
        currency: undefined,
        defaultTariff: undefined,
      };
      setInitialValues(newInitialValues);
    }
  }, [user]);

  const getValidationSchema = () => {
    if (formActiveStep === FormSteps.PERSONAL_DATA) {
      return personalDataSchema;
    }
    if (formActiveStep === FormSteps.SETTINGS) {
      return settingsSchema;
    }

    return completeSchema;
  };

  const submitForm = async (
    { passwordConfirmed, ...values }: RegisterFormPayload,
    { setFieldTouched },
  ) => {
    if (formActiveStep === FormSteps.PERSONAL_DATA) {
      // Step PERSONAL_DATA completed => show SETTINGS
      setFormActiveStep(FormSteps.SETTINGS);
      // Reset "touch" on required contactFields in next step (submit touches all contactFields, even in hidden steps)
      setFieldTouched('agreeWithTerms', false, false);
      return;
    }
    if (formActiveStep === FormSteps.SETTINGS || formActiveStep === FormSteps.ERROR) {
      // Step SETTINGS completed => send request
      const editedValues = {
        ...values,
        // Zaškrtlý checkbox [notifications.newsletter] vyjadřuje NEsouhlas
        notifications: { newsletter: !values.notifications.newsletter, ...values.notifications },
      };

      await applyCaptcha();
      const registerFunction = userIsOpenTicket ? registerOpenTicket : register;
      const result = await registerFunction(editedValues as RegisterPayload);

      if (result) {
        // Request successfully completed
        showModal(<RegistrationSuccessBox password={values.password} />, {
          onClose: async () => {
            if (pathname !== URLS.HOMEPAGE) await push(URLS.HOMEPAGE);
          },
          title: t('registration.modal.header'),
        });
      } else {
        // Request returns error => Step ERROR (show all steps)
        setFormActiveStep(FormSteps.ERROR);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <HandleBackendErrors />
          {message?.length && (
            <Alert variant="warning" className="mb-1">
              {message}
            </Alert>
          )}
          <div className="grid grid-cols-1">
            <PersonalDataStep
              hidden={
                formActiveStep !== FormSteps.PERSONAL_DATA && formActiveStep !== FormSteps.ERROR
              }
              companyInformation={values.companyInformation}
              userIsOpenTicket={userIsOpenTicket}
            />
            <SettingsStep
              hidden={formActiveStep !== FormSteps.SETTINGS && formActiveStep !== FormSteps.ERROR}
              userIsOpenTicket={userIsOpenTicket}
            />
            <Button type="submit" size="big" className="w-full" disabled={isSubmitting}>
              <Trans
                i18nKey={
                  formActiveStep === FormSteps.PERSONAL_DATA
                    ? 'button.next'
                    : 'registration.settingsForm.continue'
                }
              />
            </Button>
            <FullScreenLoader showLoader={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
