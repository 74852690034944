import React from 'react';
import classNames from 'classnames';

interface BannerProps extends React.HTMLProps<HTMLDivElement> {
  icon?: React.ReactNode;
  onClick?: () => void;
}

const Banner: React.FC<BannerProps> = ({ children, icon, className, ...props }) => (
  <div
    className={classNames('card relative overflow-hidden box-border rounded-sm', className)}
    {...props}
  >
    {icon}
    {children}
  </div>
);

export default Banner;
