import { Trans } from 'next-i18next';
import React from 'react';
import RequestPasswordResetForm from '@/components/requestPasswordReset/RequestPasswordResetForm';

const RequestPasswordResetBox: React.FC = () => (
  <div>
    <p className="mb-5">
      <Trans i18nKey="forgotPassword.paragraph" />
    </p>

    <RequestPasswordResetForm />
  </div>
);

export default RequestPasswordResetBox;
