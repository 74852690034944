import { Trans } from 'next-i18next';
import React from 'react';
import { Language } from '@web/shop-logic/dist/hooks/types';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import HeaderLocalizedFlag from '@/components/templates/HeaderLocalizedFlag';

interface HeaderLanguagesProps {
  locale: Language;
  handleLanguage: () => Promise<void> | void;
}

const HeaderLanguages: React.FC<HeaderLanguagesProps> = ({ handleLanguage, locale }) => (
  <ClickableDiv
    className="w-full sm:py-1 flex items-center pr-1 border border-transparent hover:border-primary-blue rounded-sm hover:border text-14 sm:my-1"
    onClick={async () => handleLanguage()}
  >
    <HeaderLocalizedFlag selectedLanguage={locale} />
    <Trans i18nKey={`language.${locale}`} />
  </ClickableDiv>
);

export default HeaderLanguages;
