import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { ComponentShopTextLink } from '@/models/types';

interface ShopFavouriteLinkProps {
  data?: ComponentShopTextLink[];
}

const ShopFavouriteLink: React.FC<ShopFavouriteLinkProps> = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }

  return (
    <div className="flex flex-col text-14 sm:hidden">
      <h3 className="py-1">
        <Trans i18nKey="routes.favorite" />
      </h3>
      {(data || []).map(({ id, text, textMobile, url, urlMobile }) => (
        <Link key={id} href={url || urlMobile}>
          <a rel="nofollow" className="py-1 hover:text-primary-blue hover:underline">
            {text || textMobile}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default ShopFavouriteLink;
