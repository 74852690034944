import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Tooltip as TooltipComponent, TooltipProps } from '@mui/material';

const useStyles = makeStyles()(() => ({
  tooltip: { backgroundColor: '#000', padding: '8px 16px', fontSize: '14px', fontWeight: 400 },
  arrow: {
    color: '#000',
    // fix- arrow is not distorted, no gap between arrow and tooltip, must be in em
    height: '0.81em',
  },
}));

const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  const { classes } = useStyles();
  return (
    <TooltipComponent arrow placement="bottom" classes={classes} enterTouchDelay={0} {...props}>
      {children}
    </TooltipComponent>
  );
};

export default Tooltip;
