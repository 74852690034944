import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { ChevronLeft } from '@/icons';

interface PopoverHeaderProps {
  i18nKey: string;
  handleBack: () => void;
  hasShadow?: boolean;
}
const PopoverHeader: React.FC<PopoverHeaderProps> = ({ i18nKey, handleBack, hasShadow = true }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 z-10 w-full flex items-center justify-center py-1 bg-white',
        hasShadow && 'shadow-modal',
      )}
    >
      <ClickableDiv
        aria-label={t('accessibility.button.closeWindow')}
        className="absolute top-1/2 transform -translate-y-1/2 left-1"
        onClick={handleBack}
      >
        <ChevronLeft className="w-4 h-4 fill-neutral-gray2" />
      </ClickableDiv>
      <h1 className="h1">
        <Trans i18nKey={i18nKey} />
      </h1>
    </div>
  );
};

export default PopoverHeader;
