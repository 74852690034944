import { useRouter } from 'next/router';
import React from 'react';
import classNames from 'classnames';
import Chevron from '@/components/atoms/Chevron';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import Submenu from '@/components/topMenu/Submenu';
import useMobileWidth from '@/hooks/useMobileWidth';
import { Enum_Componentshopmenuitem_Submenucontenttype } from '@/models/types';
import { MenuDataProps } from '@/utils/pagesUtils';

const classes = 'py-1.5 flex items-center cursor-pointer transition-colors';
const activeClasses = 'text-primary-blue border-b border-primary-red';

const menusWithContent = [
  Enum_Componentshopmenuitem_Submenucontenttype.AkceANovinky,
  Enum_Componentshopmenuitem_Submenucontenttype.AktualityZProvozu,
  Enum_Componentshopmenuitem_Submenucontenttype.ONas,
];

interface HeaderLinkProps extends React.HTMLProps<HTMLDivElement> {
  index: number;
  show: boolean;
  linkIndex: number;
  setShowSubmenu: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      linkIndex: number;
      prevShow?: boolean;
      prevIndex?: number;
    }>
  >;
  content: string;
  href: string;
  menuData: MenuDataProps;
  setClosedPopper?: (closePopper: boolean) => void;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({
  className,
  index,
  show,
  linkIndex,
  setShowSubmenu,
  content,
  href,
  menuData,
  setClosedPopper,
}) => {
  const { push } = useRouter();
  const { isMobile } = useMobileWidth();

  if (!content || !href) return null;

  const { submenu1, submenu2, submenu3, submenu4, submenuContentType } =
    menuData.menuTop.items[index];

  const enabledSubmenu =
    submenu1.length ||
    submenu2.length ||
    submenu3.length ||
    submenu4.length ||
    menusWithContent.includes(submenuContentType);

  return isMobile ? (
    /**
     * Mobile: otevře submenu
     */
    <>
      <ClickableDiv
        className={classNames(
          classes,
          'sm:px-2 sm:justify-between sm:text-base sm:font-bold relative bg-white',
          show && linkIndex === index && activeClasses,
          className,
        )}
        data-id="header-link"
        onClick={() => {
          if (!enabledSubmenu) push(href);
          else
            setShowSubmenu(({ linkIndex: prevLinkIndex, show: prevShowStat }) => ({
              show: !show,
              linkIndex: index,
              prevShow: prevShowStat,
              prevIndex: prevLinkIndex,
            }));
        }}
      >
        {content}
        {enabledSubmenu && <Chevron isOpened={show && linkIndex === index} />}
      </ClickableDiv>

      {show && linkIndex === index && <Submenu menuData={menuData} currentIndex={linkIndex} />}
    </>
  ) : (
    /**
     * Desktop: odkaz
     */
    <div
      className="group"
      onMouseOver={() => {
        if (!enabledSubmenu) return;
        setClosedPopper(true);
        setShowSubmenu(({ linkIndex: prevLinkIndex, show: prevShowStat }) => ({
          show: true,
          linkIndex: index,
          prevShow: prevShowStat,
          prevIndex: prevLinkIndex,
        }));
      }}
      onMouseOut={
        enabledSubmenu
          ? () =>
              setShowSubmenu(({ linkIndex: prevLinkIndex, show: prevShowStat }) => ({
                show: false,
                linkIndex: index,
                prevShow: prevShowStat,
                prevIndex: prevLinkIndex,
              }))
          : undefined
      }
    >
      <a
        href={href}
        tabIndex={0}
        className={classNames(
          classes,
          'lg:mt-px lg:px-1.5 lg:border-b-2 lg:text-14 lg:border-neutral-graylight lg:hover:border-primary-blue',
          className,
        )}
      >
        {content}
      </a>
      {enabledSubmenu && <Submenu menuData={menuData} currentIndex={index} />}
    </div>
  );
};

export default HeaderLink;
