import React from 'react';
import classNames from 'classnames';

interface HorizontalLineProps {
  className?: string;
}

const HorizontalLine: React.FC<HorizontalLineProps> = ({ className }) => (
  <hr className={classNames('border-t-1 px-2', className)} />
);
export default HorizontalLine;
