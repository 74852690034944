import React from 'react';
import { useField } from 'formik';
import SelectInput, { SelectInputProps } from '@/components/atoms/SelectInput';

const FormSelect: React.FC<SelectInputProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return <SelectInput {...field} {...props} errorText={errorText} />;
};

export default FormSelect;
