import dynamic from 'next/dynamic';
import Accessible from '@web/icons/dist/icons/accessible.svg';
import Accomodation from '@web/icons/dist/icons/accomodation.svg';
import Account from '@web/icons/dist/icons/account.svg';
import Apple from '@web/icons/dist/icons/apple.svg';
import ArrowBack from '@web/icons/dist/icons/arrow_back.svg';
import ArrowDropDown from '@web/icons/dist/icons/arrow_drop_down.svg';
import ArrowEast from '@web/icons/dist/icons/arrow_east.svg';
import ArrowForward from '@web/icons/dist/icons/arrow_forward.svg';
import ArrowNorth from '@web/icons/dist/icons/arrow_north.svg';
import ArrowRightAlt from '@web/icons/dist/icons/arrow_right_alt.svg';
import ArrowSouth from '@web/icons/dist/icons/arrow_south.svg';
import ArrowSyncAlt from '@web/icons/dist/icons/arrow_sync_alt.svg';
import ArrowWest from '@web/icons/dist/icons/arrow_west.svg';
import Bank from '@web/icons/dist/icons/bank.svg';
import Beer from '@web/icons/dist/icons/beer.svg';
import Bolt from '@web/icons/dist/icons/bolt.svg';
import Breakfast from '@web/icons/dist/icons/breakfast.svg';
import Cake from '@web/icons/dist/icons/cake.svg';
import Calendar from '@web/icons/dist/icons/calendar.svg';
import CarRental from '@web/icons/dist/icons/car_rental.svg';
import Card from '@web/icons/dist/icons/card.svg';
import Cash from '@web/icons/dist/icons/cash.svg';
import Champagne from '@web/icons/dist/icons/champagne.svg';
import Check from '@web/icons/dist/icons/check.svg';
import CheckCircle from '@web/icons/dist/icons/check_circle.svg';
import Checkbox from '@web/icons/dist/icons/checkbox.svg';
import CheckboxEmpty from '@web/icons/dist/icons/checkbox_empty.svg';
import ChevronDown from '@web/icons/dist/icons/chevron_down.svg';
import ChevronLeft from '@web/icons/dist/icons/chevron_left.svg';
import ChevronRight from '@web/icons/dist/icons/chevron_right.svg';
import ChevronUp from '@web/icons/dist/icons/chevron_up.svg';
import Child1 from '@web/icons/dist/icons/child-1.svg';
import ChildHead from '@web/icons/dist/icons/child_head.svg';
import Circle from '@web/icons/dist/icons/circle.svg';
import Clear from '@web/icons/dist/icons/clear.svg';
import Cookies from '@web/icons/dist/icons/cookies.svg';
import Desinfection from '@web/icons/dist/icons/desinfection.svg';
import DestinationFrom from '@web/icons/dist/icons/destination_from.svg';
import DirectionsBus from '@web/icons/dist/icons/directions_bus.svg';
import DirectionsTrain from '@web/icons/dist/icons/directions_train.svg';
import DirectionsWalk from '@web/icons/dist/icons/directions_walk.svg';
import Document from '@web/icons/dist/icons/document.svg';
import Download from '@web/icons/dist/icons/download.svg';
import Elderly from '@web/icons/dist/icons/elderly.svg';
import ElectricalServices from '@web/icons/dist/icons/electrical_services.svg';
import Email1 from '@web/icons/dist/icons/email-1.svg';
import Email from '@web/icons/dist/icons/email.svg';
import Error from '@web/icons/dist/icons/error.svg';
import ErrorOutlinr from '@web/icons/dist/icons/error_outlinr.svg';
import Eye from '@web/icons/dist/icons/eye.svg';
import Facebook from '@web/icons/dist/icons/facebook.svg';
import Family from '@web/icons/dist/icons/family.svg';
import FastBank from '@web/icons/dist/icons/fast_bank.svg';
import Favorite from '@web/icons/dist/icons/favorite.svg';
import FlashOn from '@web/icons/dist/icons/flash_on.svg';
import FreeWater from '@web/icons/dist/icons/free_water.svg';
import Gepard from '@web/icons/dist/icons/gepard.svg';
import GestureTapButton from '@web/icons/dist/icons/gesture_tap_button.svg';
import GiftCard from '@web/icons/dist/icons/gift_card.svg';
import Gps from '@web/icons/dist/icons/gps.svg';
import Group from '@web/icons/dist/icons/group.svg';
import Happy from '@web/icons/dist/icons/happy.svg';
import Headphones from '@web/icons/dist/icons/headphones.svg';
import HelpLine from '@web/icons/dist/icons/help_line.svg';
import Home from '@web/icons/dist/icons/home.svg';
import HotelIcon from '@web/icons/dist/icons/hotel.svg';
import Info from '@web/icons/dist/icons/info.svg';
import InfoLine from '@web/icons/dist/icons/info_line.svg';
import Instagram from '@web/icons/dist/icons/instagram.svg';
import Insurance from '@web/icons/dist/icons/insurance.svg';
import Juice from '@web/icons/dist/icons/juice.svg';
import Key from '@web/icons/dist/icons/key.svg';
import Kids from '@web/icons/dist/icons/kids.svg';
import Language from '@web/icons/dist/icons/language.svg';
import LanguageSchool from '@web/icons/dist/icons/language_school.svg';
import Linkedin from '@web/icons/dist/icons/linkedin.svg';
import LocalCafe from '@web/icons/dist/icons/local_cafe.svg';
import LocalParking from '@web/icons/dist/icons/local_parking.svg';
import LocalTaxi from '@web/icons/dist/icons/local_taxi.svg';
import LocationOn from '@web/icons/dist/icons/location_on.svg';
import Lock from '@web/icons/dist/icons/lock.svg';
import Lowcost from '@web/icons/dist/icons/lowcost.svg';
import Luggage from '@web/icons/dist/icons/luggage.svg';
import Map from '@web/icons/dist/icons/map.svg';
import Mask from '@web/icons/dist/icons/mask.svg';
import Maximize from '@web/icons/dist/icons/maximize.svg';
import Meditation from '@web/icons/dist/icons/meditation.svg';
import Meh from '@web/icons/dist/icons/meh.svg';
import MenuBook from '@web/icons/dist/icons/menu_book.svg';
import Minimize from '@web/icons/dist/icons/minimize.svg';
import Mobile from '@web/icons/dist/icons/mobile.svg';
import Money1 from '@web/icons/dist/icons/money-1.svg';
import NoElectricalServices from '@web/icons/dist/icons/no_electrical_services.svg';
import NoGestureTapButton from '@web/icons/dist/icons/no_gesture_tap_button.svg';
import OffClose from '@web/icons/dist/icons/off_close.svg';
import OnlineCatering from '@web/icons/dist/icons/online_catering.svg';
import PedalBike from '@web/icons/dist/icons/pedal_bike.svg';
import PersonFree from '@web/icons/dist/icons/person-free.svg';
import Person from '@web/icons/dist/icons/person.svg';
import Phone from '@web/icons/dist/icons/phone.svg';
import PlaneTicket from '@web/icons/dist/icons/plane_ticket.svg';
import Play from '@web/icons/dist/icons/play.svg';
import PlusBus from '@web/icons/dist/icons/plus_bus.svg';
import Power from '@web/icons/dist/icons/power.svg';
import Print from '@web/icons/dist/icons/print.svg';
import PriorityService from '@web/icons/dist/icons/priority_service.svg';
import Qr from '@web/icons/dist/icons/qr.svg';
import RadioButtonChecked from '@web/icons/dist/icons/radio_button_checked.svg';
import RadioButtonUnchecked from '@web/icons/dist/icons/radio_button_unchecked.svg';
import Receipt from '@web/icons/dist/icons/receipt.svg';
import Restaurant from '@web/icons/dist/icons/restaurant.svg';
import Sad from '@web/icons/dist/icons/sad.svg';
import Sale from '@web/icons/dist/icons/sale.svg';
import Sanitizer from '@web/icons/dist/icons/sanitizer.svg';
import Savings from '@web/icons/dist/icons/savings.svg';
import School from '@web/icons/dist/icons/school.svg';
import Search from '@web/icons/dist/icons/search.svg';
import Seat from '@web/icons/dist/icons/seat.svg';
import SeatBusiness from '@web/icons/dist/icons/seat_business.svg';
import SeatEconomyPremium from '@web/icons/dist/icons/seat_economy_premium.svg';
import SeatFlat from '@web/icons/dist/icons/seat_flat.svg';
import SeatPerson from '@web/icons/dist/icons/seat_person.svg';
import Security from '@web/icons/dist/icons/security.svg';
import Settings from '@web/icons/dist/icons/settings.svg';
import ShoppingBasket from '@web/icons/dist/icons/shopping_basket.svg';
import Silence from '@web/icons/dist/icons/silence.svg';
import Ski from '@web/icons/dist/icons/ski.svg';
import Skiing from '@web/icons/dist/icons/skiing.svg';
import SleepSeats from '@web/icons/dist/icons/sleep_seats.svg';
import Smile from '@web/icons/dist/icons/smile.svg';
import Snow from '@web/icons/dist/icons/snow.svg';
import SofaSingle from '@web/icons/dist/icons/sofa_single.svg';
import Star from '@web/icons/dist/icons/star.svg';
import StarHoliday from '@web/icons/dist/icons/star_holiday.svg';
import Stevardess from '@web/icons/dist/icons/stevardess.svg';
import Stroller from '@web/icons/dist/icons/stroller.svg';
import Suitcase from '@web/icons/dist/icons/suitcase.svg';
import SunWeather from '@web/icons/dist/icons/sun_weather.svg';
import SupportAgent from '@web/icons/dist/icons/support_agent.svg';
import Sync from '@web/icons/dist/icons/sync.svg';
import TableChair from '@web/icons/dist/icons/table_chair.svg';
import Tea from '@web/icons/dist/icons/tea.svg';
import ThumbsUp from '@web/icons/dist/icons/thumbs_up.svg';
import TicketCircle from '@web/icons/dist/icons/ticket_circle.svg';
import Time from '@web/icons/dist/icons/time.svg';
import TrainTicket from '@web/icons/dist/icons/train_ticket.svg';
import TrainWagon from '@web/icons/dist/icons/train_wagon.svg';
import Twitter from '@web/icons/dist/icons/twitter.svg';
import Unhappy from '@web/icons/dist/icons/unhappy.svg';
import UnitedBuses from '@web/icons/dist/icons/united_buses.svg';
import VendingMachine from '@web/icons/dist/icons/vending_machine.svg';
import VisibilityOff from '@web/icons/dist/icons/visibility_off.svg';
import Wallet from '@web/icons/dist/icons/wallet.svg';
import Warning from '@web/icons/dist/icons/warning.svg';
import Wifi from '@web/icons/dist/icons/wifi.svg';
import WifiOff from '@web/icons/dist/icons/wifi_off.svg';
import WorkStays from '@web/icons/dist/icons/work_stays.svg';

export type IconElementProps = React.DetailedHTMLProps<React.SVGAttributes<SVGElement>, SVGElement>;
export type IconElement = React.ComponentType<IconElementProps>;

const ApplePay = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/apple_pay.svg'));
const AustriaFlag = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/austria_flag.svg'),
);
const AWallet = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/a_wallet.svg'));
const CardPay = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/card_pay.svg'));
const CsasServis24 = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/csas_servis24.svg'),
);
const Csob = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/csob.svg'));
const CzechFlag = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/czech-flag.svg'));
const EconomyBus = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/economy_bus.svg'));
const Emptysearch = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/emptySearch.svg'),
);
const Era = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/era.svg'));
const Fio = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/fio.svg'));
const GermanyFlag = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/germany_flag.svg'),
);
const Giropay = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/giropay.svg'));
const GooglePay = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/google_pay.svg'));
const HungaryFlag = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/hungary_flag.svg'),
);
const IdsLogo = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/ids.svg'));
const InstantTransfer = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/instant_transfer.svg'),
);
const Kb = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/kb.svg'));
const MbankMpenize = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/mbank_mpenize.svg'),
);
const Moneta = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/moneta.svg'));
const ObbLogo = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/obb.svg'));
const PolandFlag = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/poland_flag.svg'));
const Rating = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/rating.svg'));
const Reiffeisen = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/reiffeisen.svg'));
const RjLogo = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/rj_logo.svg'));
const RjLogoFull = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/rj_logo_full.svg'),
);
const RjLogoMobile = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/rj_logo_mobile.svg'),
);
const RegiojetLogoStuha = dynamic<IconElementProps>(
  () => import('@/assets/regiojet_logo_stuha.svg'),
);
const RocketLg = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/rocket-lg.svg'));
const RocketSm = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/rocket-sm.svg'));
const Sepa = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/sepa.svg'));
const SlovakiaFlag = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/slovakia_flag.svg'),
);
const SlovenskaSporitelna = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/slovenska_sporitelna.svg'),
);
const SofortAt = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/sofort_at.svg'));
const SofortDe = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/sofort_de.svg'));
const Sporopay = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/sporopay.svg'));
const StornoConfirmed = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/storno_confirmed.svg'),
);
const SuccessHeaderBg = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/success_header_bg.svg'),
);
const SuccessHeaderBgMobile = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/success_header_bg_mobile.svg'),
);
const Tatrapay = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/tatrapay.svg'));
const UkFlag = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/uk_flag.svg'));
const UkraineFlag = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/ukraine_flag.svg'),
);
const UkrainianRailways = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/ukrainian_railways.svg'),
);
const Unicredit = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/unicredit.svg'));
const Vub = dynamic<IconElementProps>(() => import('@web/icons/dist/icons/vub.svg'));
const WestbahnLogo = dynamic<IconElementProps>(
  () => import('@web/icons/dist/icons/westbahn_logo.svg'),
);

export {
  AWallet,
  Accessible,
  Accomodation,
  Account,
  Apple,
  ApplePay,
  ArrowBack,
  ArrowDropDown,
  ArrowEast,
  ArrowForward,
  ArrowNorth,
  ArrowRightAlt,
  ArrowSouth,
  ArrowSyncAlt,
  ArrowWest,
  AustriaFlag,
  Bank,
  Beer,
  Bolt,
  Breakfast,
  Cake,
  Calendar,
  CarRental,
  Card,
  CardPay,
  Cash,
  Champagne,
  Check,
  CheckCircle,
  Checkbox,
  CheckboxEmpty,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Child1,
  ChildHead,
  Circle,
  Clear,
  Cookies,
  CsasServis24,
  Csob,
  CzechFlag,
  Desinfection,
  DestinationFrom,
  DirectionsBus,
  DirectionsTrain,
  DirectionsWalk,
  Document,
  Download,
  EconomyBus,
  Elderly,
  ElectricalServices,
  Email,
  Email1,
  Emptysearch,
  Era,
  Error,
  ErrorOutlinr,
  Eye,
  Facebook,
  Family,
  FastBank,
  Favorite,
  Fio,
  FlashOn,
  FreeWater,
  Gepard,
  GermanyFlag,
  GestureTapButton,
  GiftCard,
  Giropay,
  GooglePay,
  Gps,
  Group,
  Happy,
  Headphones,
  HelpLine,
  Home,
  HotelIcon,
  HungaryFlag,
  IdsLogo,
  Info,
  InfoLine,
  Instagram,
  InstantTransfer,
  Insurance,
  Juice,
  Kb,
  Key,
  Kids,
  Language,
  LanguageSchool,
  Linkedin,
  LocalCafe,
  LocalParking,
  LocalTaxi,
  LocationOn,
  Lock,
  Lowcost,
  Luggage,
  Map,
  Mask,
  MbankMpenize,
  Meditation,
  Meh,
  MenuBook,
  Mobile,
  Moneta,
  Money1,
  NoElectricalServices,
  NoGestureTapButton,
  ObbLogo,
  OffClose,
  OnlineCatering,
  PedalBike,
  Person,
  PersonFree,
  Phone,
  PlaneTicket,
  Play,
  PlusBus,
  PolandFlag,
  Power,
  Print,
  PriorityService,
  Qr,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Rating,
  Receipt,
  Reiffeisen,
  Restaurant,
  RjLogo,
  RjLogoFull,
  RjLogoMobile,
  RegiojetLogoStuha,
  RocketLg,
  RocketSm,
  Sad,
  Sale,
  Sanitizer,
  Savings,
  School,
  Search,
  Seat,
  SeatBusiness,
  SeatEconomyPremium,
  SeatFlat,
  SeatPerson,
  Security,
  Sepa,
  Settings,
  ShoppingBasket,
  Silence,
  Ski,
  Skiing,
  SleepSeats,
  SlovakiaFlag,
  SlovenskaSporitelna,
  Smile,
  Snow,
  SofaSingle,
  SofortAt,
  SofortDe,
  Sporopay,
  Star,
  StarHoliday,
  Stevardess,
  StornoConfirmed,
  Stroller,
  SuccessHeaderBg,
  SuccessHeaderBgMobile,
  Suitcase,
  SunWeather,
  SupportAgent,
  Sync,
  TableChair,
  Tatrapay,
  Tea,
  ThumbsUp,
  Time,
  TrainTicket,
  TrainWagon,
  TicketCircle,
  Twitter,
  UkFlag,
  UkraineFlag,
  UkrainianRailways,
  Unhappy,
  Unicredit,
  UnitedBuses,
  VendingMachine,
  VisibilityOff,
  Vub,
  Wallet,
  Warning,
  WestbahnLogo,
  Wifi,
  WifiOff,
  WorkStays,
  Maximize,
  Minimize,
};
