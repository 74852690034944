import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import URLS from '@/constants/url';
import { RjLogoFull, RjLogoMobile } from '@/icons';

const HeaderRjLogo = () => {
  const { t } = useTranslation();

  return (
    <Link href={URLS.HOMEPAGE}>
      <a
        role="banner"
        className="flex items-center h-full lg:mr-16"
        aria-label={t('accessibility.gotoHomepage')}
      >
        <RjLogoMobile className="w-11 h-4 ml-1.5 lg:hidden" />
        <RjLogoFull height={32} width={124} className="sm:hidden" />
      </a>
    </Link>
  );
};

export default HeaderRjLogo;
