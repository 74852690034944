enum Tariff {
  Regular = 'REGULAR',
  CzechStudentPass15 = 'CZECH_STUDENT_PASS_15',
  CzechStudentPass26 = 'CZECH_STUDENT_PASS_26',
  Isic = 'ISIC',
  AttendedChild = 'ATTENDED_CHILD',
  Disabled = 'DISABLED',
  DisabledAttendance = 'DISABLED_ATTENDANCE',
  Euro26 = 'EURO26',
  Child = 'CHILD',
  UaRefugee = 'UA_REFUGEE',
  UaRefugeeChild = 'UA_REFUGEE_CHILD',
  Disabled3 = 'DISABLED_3',
  ChildUnder12 = 'CHILD_UNDER_12',
}

export default Tariff;
