import {
  format,
  parseISO,
  isAfter,
  startOfDay,
  addDays,
  differenceInDays,
  parse,
  isValid,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { enGB, cs, de, hu, pl, sk, uk } from 'date-fns/locale';
import { isBrowser } from 'react-use/lib/misc/util';

const regexWithMilliseconds = /^\d{2}:\d{2}:\d{2}\.\d{3}$/;
const regexWithoutMilliseconds = /^\d{2}:\d{2}$/;

const determiteParseFormat = (time: string) => {
  if (regexWithMilliseconds.test(time)) return 'HH:mm:ss.SSS';
  if (regexWithoutMilliseconds.test(time)) return 'HH:mm';

  return '';
};

export const dateLocales = Object.freeze({
  cs,
  de,
  en: enGB,
  hu,
  pl,
  sk,
  uk,
});

export type FormatType =
  | 'CALENDAR_DATE'
  | 'DATE'
  | 'HOURS_MINUTES'
  | 'LONG_MONTH_YEAR'
  | 'LONG_WEEKDAY'
  | 'SHORT_DATE'
  | 'SHORT_YEAR_DATE'
  | 'TIME_SHORT_DAY'
  | 'YEAR-MONTH-DAY';

const mapFormatToFormatter: Record<FormatType, string> = {
  CALENDAR_DATE: 'EEEEEE, P',
  DATE: 'd. M. yyyy',
  HOURS_MINUTES: 'HH:mm',
  LONG_MONTH_YEAR: 'MMMM yyyy',
  LONG_WEEKDAY: 'EEEE, d. MMMM yyyy',
  SHORT_DATE: '	EEEEEE d.M.',
  SHORT_YEAR_DATE: 'EEEEEE d.M. yyyy',
  TIME_SHORT_DAY: 'EEEEEE, P HH:mm',
  'YEAR-MONTH-DAY': 'yyyy-MM-dd',
};

const getDateLocale = () => {
  const locale = isBrowser ? document.documentElement.lang : 'cs';
  return dateLocales[locale];
};

export const getTimeZoneLocalTime = (dateString: string) => {
  const parsed = parseISO(dateString);
  // Extract timezone from the date string, or use UTC as default
  // This might fail if date is not in standard ECMA script format:
  // https://262.ecma-international.org/#sec-date-time-string-format
  const timeZone = dateString.endsWith('Z') ? 'UTC' : dateString.slice(-6);
  return utcToZonedTime(parsed, timeZone);
};

export const formatConnectionTime = (
  dateString: string,
  formatType: FormatType = 'HOURS_MINUTES',
) => {
  const localizedDate = getTimeZoneLocalTime(dateString);
  return format(localizedDate, mapFormatToFormatter[formatType], {
    locale: getDateLocale(),
  });
};

export const formatTime = (date: Date | string, formatType: FormatType = 'HOURS_MINUTES'): string =>
  isValid(new Date(date))
    ? format(new Date(date), mapFormatToFormatter[formatType], {
        locale: getDateLocale(),
      })
    : '';

export const formatStationTime = (time: string): string => {
  if (!time?.length) return '';

  const parseFormat = determiteParseFormat(time);

  if (!parseFormat) return '';

  return format(parse(time, parseFormat, new Date()), 'HH:mm', {
    locale: getDateLocale(),
  });
};

export const formatStrapiDate = (date: string, formatType?: string) =>
  date
    ? format(parse(date, 'yyyy-MM-dd', new Date()), formatType || 'dd-MM-yyyy', {
        locale: getDateLocale(),
      })
    : '';

export const parseStrapiDate = (date: string) => parse(date, 'yyyy-MM-dd', new Date());

export const isVisible = (visibleFrom: string, visibleTo: string): boolean => {
  const now = new Date();
  const publishedInFuture = visibleFrom && isAfter(parseISO(visibleFrom), now);
  const publishedInPast = visibleTo && isAfter(now, parseISO(visibleTo));
  return !publishedInFuture && !publishedInPast;
};

export const dayDiff = (departureTime: string, arrivalTime: string): number =>
  Math.abs(
    differenceInDays(
      startOfDay(getTimeZoneLocalTime(departureTime)),
      startOfDay(getTimeZoneLocalTime(arrivalTime)),
    ),
  );

export const today = startOfDay(new Date());
export const tomorrow = addDays(new Date(), 1);

export const getMonthName = (date: string, locale: string) =>
  format(new Date(date), 'MMMM', { locale: locale === 'cs' ? dateLocales.cs : dateLocales.en });
