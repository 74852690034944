import React from 'react';
import { useField } from 'formik';
import Alert from '@/components/atoms/Alert';
import Checkbox, { CheckboxProps } from '@/components/atoms/Checkbox';

interface FormCheckboxProps extends CheckboxProps {
  errorTextInAlert?: boolean;
}
const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  errorTextInAlert = false,
  ...props
}) => {
  const [field, meta] = useField({ name, type: 'checkbox' });

  const errorText = meta.error && meta.touched ? meta.error : '';

  /**
   * Checkbox s chybovou hláškou v alertu nad ním
   */
  if (errorTextInAlert) {
    return (
      <>
        {errorText && (
          <Alert variant="warning" className="mb-1">
            {errorText}
          </Alert>
        )}
        <Checkbox hasCustomStateControl {...field} {...props} />
      </>
    );
  }
  return <Checkbox hasCustomStateControl {...field} {...props} errorText={errorText} />;
};

export default FormCheckbox;
