import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Popover } from '@mui/material';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { LocalizedPage } from '@/components/header/LanguagesCurrenciesPopper';
import { headerLanguages } from '@/components/header/headerLangAndCurrency';
import PopoverHeader from '@/components/organisms/PopoverHeader';
import HeaderLanguages from '@/components/templates/HeaderLanguages';
import HeaderLocalizedFlag from '@/components/templates/HeaderLocalizedFlag';

interface LanguagesPopoverProps {
  handleLang: (langPage: LocalizedPage) => Promise<void>;
  locales?: LocalizedPage[];
}

const LanguagesPopover: React.FC<LanguagesPopoverProps> = ({ locales, handleLang }) => {
  const { locale } = useRouter();

  const [langAnchorEl, setLangAnchorEl] = useState(null);

  const validLocales: LocalizedPage[] = headerLanguages?.map((loc) => ({
    locale: loc,
    id: locales?.find((l) => l.locale === loc)?.id,
  }));

  const handleLangPopover = (event) => setLangAnchorEl(event.currentTarget);

  return (
    <>
      <ClickableDiv
        className="flex items-center justify-between border-b-2 border-transparent font-bold px-2 py-1.5 bg-white"
        onClick={handleLangPopover}
      >
        <Trans i18nKey="header.popover.language" />
        <div className="flex items-center gap-1">
          <HeaderLocalizedFlag />
          <Trans i18nKey={`language.${locale}`} />
        </div>
      </ClickableDiv>
      <Popover
        anchorEl={langAnchorEl}
        anchorReference="none"
        transitionDuration={0}
        open={!!langAnchorEl}
        onClose={() => setLangAnchorEl(null)}
        PaperProps={{ className: 'cssreset-popover-paper' }}
      >
        <PopoverHeader i18nKey="header.popover.language" handleBack={() => setLangAnchorEl(null)} />
        <div className="inline-block lg:p-3 searchBox-mob-container popover-city">
          <div className="mx-2">
            {validLocales.map((localePage) => (
              <HeaderLanguages
                key={localePage.locale}
                locale={localePage.locale}
                handleLanguage={async () => {
                  await handleLang(localePage);
                  setLangAnchorEl(null);
                }}
              />
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default LanguagesPopover;
