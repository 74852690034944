import React from 'react';
import classNames from 'classnames';
import { CheckCircle, Warning, Info, Stevardess } from '@/icons';

export enum AlertVariants {
  WARNING = 'warning',
  WARNING_REVERSE = 'warning_reverse',
  INFO = 'info',
  INFO_REVERSE = 'info_reverse',
  CONFIRMED = 'confirmed',
  CONFIRMED_REVERSE = 'confirmed_reverse',
  HIRING = 'hiring',
  TRANSPARENT = 'transparent',
}

export interface AlertProps extends React.HTMLProps<HTMLDivElement> {
  /* Obsah (HTML nebo string) */
  children?: React.ReactNode;
  /* Varianta (mění barvu a ikonku) */
  variant?: `${AlertVariants}`;
  /* Nadpis v alertu */
  headline?: string;
  contentClasses?: string;
  iconClasses?: string;
  innerContentClasses?: string;
}

const mapIconToVariant = {
  [AlertVariants.INFO]: Info,
  [AlertVariants.INFO_REVERSE]: Info,
  [AlertVariants.WARNING]: Warning,
  [AlertVariants.WARNING_REVERSE]: Warning,
  [AlertVariants.CONFIRMED]: CheckCircle,
  [AlertVariants.CONFIRMED_REVERSE]: CheckCircle,
  [AlertVariants.HIRING]: Stevardess,
  [AlertVariants.TRANSPARENT]: Info,
};

const mapClassesToVariant = {
  [AlertVariants.INFO]: 'rounded-sm bg-primary-bluelight2',
  [AlertVariants.INFO_REVERSE]: 'rounded-sm bg-secondary-bluedark',
  [AlertVariants.WARNING]: 'rounded-sm bg-secondary-rose',
  [AlertVariants.WARNING_REVERSE]: 'bg-secondary-redwarn',
  [AlertVariants.CONFIRMED]: 'rounded-sm bg-secondary-greenlight2',
  [AlertVariants.CONFIRMED_REVERSE]: 'rounded-sm bg-secondary-green',
  [AlertVariants.HIRING]: 'rounded-sm bg-secondary-greenlight2',
  [AlertVariants.TRANSPARENT]: 'rounded-sm bg-transparent',
};

const mapTextClassesToVariant = {
  [AlertVariants.INFO]: 'text-secondary-bluedark2',
  [AlertVariants.INFO_REVERSE]: 'text-white',
  [AlertVariants.WARNING]: 'text-secondary-redwarn',
  [AlertVariants.WARNING_REVERSE]: 'text-white',
  [AlertVariants.CONFIRMED]: 'text-secondary-greendark',
  [AlertVariants.CONFIRMED_REVERSE]: 'text-white',
  [AlertVariants.HIRING]: 'text-secondary-greendark',
  [AlertVariants.TRANSPARENT]: 'text-secondary-bluedark2',
};

/**
 * ## Použití
 * Bez nadpisu: `<Alert variant="warning"> Mimořádná událost na cestě </Alert/>`
 *
 * S nadpisem: `<Alert variant="warning" headline="Nemáte žádné jízdenky"> Mimořádná událost na cestě </Alert/>`
 */
const Alert: React.FC<AlertProps> = ({
  children,
  className,
  variant = 'info',
  headline,
  contentClasses,
  iconClasses,
  innerContentClasses,
  ...props
}) => {
  // Fallback variant
  if (!(Object.values(AlertVariants) as string[]).includes(variant)) {
    // eslint-disable-next-line no-param-reassign
    variant = AlertVariants.INFO;
  }
  const Icon = mapIconToVariant[variant];
  const textClasses = mapTextClassesToVariant[variant];

  return (
    <div
      className={classNames(
        'flex items-center p-1 text-14',
        mapClassesToVariant[variant],
        className,
      )}
      id={
        [`${AlertVariants.WARNING}`, `${AlertVariants.WARNING_REVERSE}`].includes(variant)
          ? `gtm-alert-warn`
          : undefined
      }
      {...props}
    >
      <Icon
        className={classNames(
          'w-3 sm:w-2.5 h-3 sm:h-2.5 mr-1.5 sm:mr-1 flex-shrink-0 fill-current',
          textClasses,
          iconClasses,
        )}
      />
      <div
        className={classNames(textClasses, contentClasses, 'flex flex-col justify-center w-full')}
      >
        {headline && (
          <div className="text-base font-bold" dangerouslySetInnerHTML={{ __html: headline }} />
        )}

        {typeof children === 'string' ? (
          // String obsahující HTML
          <span className={innerContentClasses} dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          // HTML
          <span className={innerContentClasses}>{children}</span>
        )}
      </div>
    </div>
  );
};

export default Alert;
