import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { ComponentPagePageBanner } from '@/models/types';
import { getComponentFromContent } from '@/utils/dataUtils';
import { MenuDataProps } from '@/utils/pagesUtils';

interface MenuNewsArticlesProps extends Pick<MenuDataProps, 'newsData'> {
  title: string;
  url: string;
}

const MenuNewsArticles: React.FC<MenuNewsArticlesProps> = ({
  newsData,
  title: headerTitle,
  url,
}) => {
  /**
   * Namísto stránek se použijí jen bannery z obsahu stránky
   * Předpokládá se, že první komponenta obsahu je `page.page-banner-row`
   */
  const links = getComponentFromContent<ComponentPagePageBanner[]>(
    newsData?.content,
    'banner',
  )?.slice(0, 3);

  if (!links || !url) {
    return null;
  }

  return (
    <div className="flex flex-col text-14 sm:mt-2">
      {headerTitle && <h4 className="font-bold py-1">{headerTitle}</h4>}
      {links.map(({ id, header, url: generatedUrl }) => (
        <Link key={id} href={generatedUrl}>
          <a className="py-1 hover:text-primary-blue hover:underline">{header}</a>
        </Link>
      ))}
      {url && (
        <Link href={url}>
          <a className="py-1 text-neutral-gray hover:underline">
            <Trans i18nKey="show.more" />
          </a>
        </Link>
      )}
    </div>
  );
};

export default MenuNewsArticles;
