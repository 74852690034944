import { captureMessage } from '@sentry/nextjs';
import { CookiesPreferences } from '@/components/cookiesPreferences/CookiesProvider';

enum GtmState {
  GRANTED = 'granted',
  DENIED = 'denied',
}

interface GtmObject {
  ad_storage: GtmState;
  analytics_storage: GtmState;
  functionality_storage: GtmState;
  personalization_storage: GtmState;
  ad_user_data: GtmState;
  ad_personalization: GtmState;
}

/**
 * Převádí cookiesPreferences na objekt pro GTM
 * Kategorie preferencí jsou podobné, jen jsou v GTM pojmenované jinak
 */
export const getGtmObjectFromPreferences = (cookiesPreferences: CookiesPreferences): GtmObject => {
  const { functional, marketing, performance, required, personalization, userData } =
    cookiesPreferences;

  return {
    ad_storage: marketing ? GtmState.GRANTED : GtmState.DENIED,
    analytics_storage: performance ? GtmState.GRANTED : GtmState.DENIED,
    functionality_storage: required ? GtmState.GRANTED : GtmState.DENIED,
    personalization_storage: functional ? GtmState.GRANTED : GtmState.DENIED,
    ad_user_data: userData ? GtmState.GRANTED : GtmState.DENIED,
    ad_personalization: personalization ? GtmState.GRANTED : GtmState.DENIED,
  };
};

/**
 * Pošle preference do GTM
 */
export const sendCookiesPreferencesToGtm = (
  eventType: 'consentDefault' | 'consentUpdate',
  cookiesPreferences: CookiesPreferences,
): void => {
  const gtmPreferences = getGtmObjectFromPreferences(cookiesPreferences);

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer = (window as any).dataLayer || [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer?.push({ event: eventType, ...gtmPreferences });
  } catch (error) {
    // Chyba při odeslání - posíláme do Sentry a uživateli vypíšeme, že odeslání skončilo chybou
    captureMessage(
      `Poslání cookies do GTM selhalo: ${eventType}, ${JSON.stringify(
        cookiesPreferences,
      )}, ${error}`,
    );
  }
};
