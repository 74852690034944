import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import ClickableDiv from '@/components/atoms/ClickableDiv';

interface HeaderMobileNavbarTogglerProps {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
}

const HeaderMobileNavbarToggler: React.FC<HeaderMobileNavbarTogglerProps> = ({
  toggle,
  setToggle,
}) => (
  <ClickableDiv
    id="menu-btn"
    className={classNames(
      'lg:hidden h-full flex flex-col items-center justify-center px-2 cursor-pointer select-none transition-color',
      toggle && 'bg-neutral-white',
    )}
    onClick={() => setToggle(!toggle)}
  >
    <div className="h-3 w-3 flex items-center justify-center">
      <span
        className={classNames(
          'header-navicon transition ease-out duration-200 relative bg-black w-2.5 h-[2px]',
          toggle && 'is-opened',
        )}
      />
    </div>
    <span className="text-11">
      <Trans i18nKey="topMenu.menu" />
    </span>
  </ClickableDiv>
);

export default HeaderMobileNavbarToggler;
