import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import PreventRouteChangeModal from '@/components/atoms/PreventRouteChangeModal';
import useModal from '@/components/modal/useModal';
import usePreventRouteChange from '@/hooks/usePreventRouteChange';

type PreventRouteChangeProps = React.HTMLProps<HTMLDivElement>;

const PreventRouteChange: React.FC<PreventRouteChangeProps> = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { preventedUrl, setPreventedUrl, setUserConfirmed } = usePreventRouteChange();

  useEffect(() => {
    if (preventedUrl) {
      showModal(
        <PreventRouteChangeModal preventedUrl={preventedUrl} setUserConfirmed={setUserConfirmed} />,
        { size: 'big', title: t('reservation.leave.header'), onClose: () => setPreventedUrl(null) },
      );
    }
  }, [preventedUrl]);

  return null;
};

export default PreventRouteChange;
