import React from 'react';
import { ClickableElementProps, handleClick, handleKeyPress } from '@/utils/clickableUtils';

export type ClickableDivProps = ClickableElementProps<HTMLDivElement>;

const ClickableDiv: React.FC<ClickableDivProps> = ({
  children,
  onClick,
  gtmPlace,
  gtmName,
  isClickable = true,
  ...props
}) =>
  isClickable ? (
    <div
      role="button"
      onClick={handleClick({ onClick, gtmPlace, gtmName })}
      onKeyPress={handleKeyPress({ onClick, gtmPlace, gtmName })}
      tabIndex={0}
      {...props}
    >
      {children}
    </div>
  ) : (
    <div {...props}>{children}</div>
  );

export default ClickableDiv;
