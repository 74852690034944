import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useUser } from '@web/shop-logic';
import Banner from '@/components/atoms/Banner';
import { Button } from '@/components/atoms/Button';
import BenefitList from '@/components/benefitBanner/BenefitList';
import LoginForm from '@/components/login/LoginForm';
import LoginTicketForm from '@/components/login/LoginTicketForm';
import useModal from '@/components/modal/useModal';
import RegistrationBox from '@/components/registration/RegistrationBox';
import { RocketLg } from '@/icons';

export type LoginPage = 'loginOptions' | 'creditTicket' | 'openTicket' | 'lastCreditTicket';

interface LoginBoxProps {
  onLogin?: () => Promise<void>;
  uniqueId?: string;
  creditOnly?: boolean;
}

const LoginBox: React.FC<LoginBoxProps> = ({ onLogin, uniqueId, creditOnly }) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { loginNumbers } = useUser();

  const rightColumnElement = useRef(null);

  const [loginPage, setLoginPage] = useState<LoginPage>('loginOptions');
  const [lastCreditTicket, setLastCreditTicket] = useState<string>();

  const currentLoginPage = ['creditTicket', 'lastCreditTicket'].includes(loginPage);

  const openRegisterModal = () => showModal(<RegistrationBox />, { removePadding: true });

  useEffect(() => {
    /**
     * Po vykreslení nastaví pravému sloupci fixní výšku podle záložky Kreditová,
     * aby se neměnila výška při překliknutí na (nižší) záložku Otevřená
     */
    const currentHeight = rightColumnElement.current.getBoundingClientRect().height;
    if (currentHeight > 0) {
      rightColumnElement.current.style.height = `${currentHeight}px`;
    }
  }, []);

  useEffect(() => setLoginPage('loginOptions'), [uniqueId]);

  return (
    <Banner icon={<RocketLg className="opacity-100" />}>
      <div className="grid grid-cols-2 sm:grid-cols-1">
        <div className="flex flex-col items-start sm:px-2 sm:py-4 p-5">
          <h2 className="h2 mb-1">
            <Trans i18nKey="settings.mojeid.login" />
          </h2>
          {loginPage === 'loginOptions' && (
            <div className="mb-3">
              <Trans i18nKey="loginBox.subtitle" />
            </div>
          )}
          {!!loginNumbers.length && loginPage === 'loginOptions' && (
            <div className="flex flex-col flex-wrap gap-1 w-full sm:overflow-y-auto">
              <span className="font-bold">
                <Trans i18nKey="loginBox.last.credit.tickets.title" />
              </span>
              <div className="flex flex-wrap">
                {loginNumbers.map((loginNumber) => (
                  <div key={loginNumber} className="mb-2 mr-1">
                    <Button
                      key={loginNumber}
                      className="max-w-min"
                      size="small"
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        setLastCreditTicket(loginNumber);
                        setLoginPage('lastCreditTicket');
                      }}
                      aria-label={t('accessibility.loginNumber.button', { loginNumber })}
                    >
                      {loginNumber}
                    </Button>
                  </div>
                ))}
              </div>
              <span className="font-bold">
                <Trans i18nKey="logingBox.credit.open.title" />
              </span>
            </div>
          )}
          {loginPage === 'loginOptions' && (
            <div>
              <Button
                size="big"
                variant="secondary"
                className="w-full mb-1 mt-1.5"
                onClick={() => setLoginPage('creditTicket')}
              >
                <Trans i18nKey="loginBox.tab.credit" />
              </Button>
              <span className="text-14">
                <Trans i18nKey="loginBox.credit.ticket.title" />{' '}
              </span>
              {!creditOnly && (
                <>
                  <Button
                    size="big"
                    variant="secondary"
                    className="w-full sm:mt-2 mt-3 mb-1"
                    onClick={() => setLoginPage('openTicket')}
                  >
                    <Trans i18nKey="loginBox.tab.open" />
                  </Button>
                  <span className="text-14">
                    <Trans i18nKey="loginBox.open.ticket.title" />{' '}
                  </span>
                </>
              )}
            </div>
          )}
          {currentLoginPage && (
            <LoginForm
              onLogin={onLogin}
              backToLoginOptions={() => setLoginPage('loginOptions')}
              lastCreditTicket={lastCreditTicket}
              loginPage={loginPage}
            />
          )}
          {loginPage === 'openTicket' && (
            <LoginTicketForm backToLoginOptions={() => setLoginPage('loginOptions')} />
          )}
        </div>
        <div
          className="flex flex-col items-start sm:px-2 sm:py-4 p-5 bg-primary-yellowlight3 justify-between"
          ref={rightColumnElement}
        >
          <div>
            <h2 className="h2 mb-2 -mt-1">
              <Trans i18nKey="loginBox.registerColumn.title" />
            </h2>
            <p className="lg:w-2/3 mb-2 font-bold">
              <Trans i18nKey="loginBox.registerColumn.paragraph" />
            </p>
            <BenefitList />
          </div>
          <Button size="big" variant="secondary" onClick={openRegisterModal}>
            <Trans i18nKey="loginBox.registerColumn.button" />
          </Button>
        </div>
      </div>
    </Banner>
  );
};

export default LoginBox;
