import { randomBytes, createHash } from 'crypto';

/**
 * Generates a random string of the specified length, encoded in a URL-safe Base64 format.
 */
const generateRandomString = (length: number) => {
  return randomBytes(length)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

/**
 * Generates a code verifier for the PKCE flow, which is a random string.
 */
const generateCodeVerifier = () => {
  return generateRandomString(64);
};

/**
 * Generates a code challenge by hashing the code verifier using SHA-256
 * and encoding it in a URL-safe Base64 format.
 */
const generateCodeChallenge = (codeVerifier: string) => {
  return createHash('sha256')
    .update(codeVerifier)
    .digest('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export { generateCodeVerifier, generateCodeChallenge };
