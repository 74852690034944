import ResponseStateAlert from '../atoms/ResponseStateAlert';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useUser, useUserActions } from '@web/shop-logic';
import Alert from '@/components/atoms/Alert';
import { Button } from '@/components/atoms/Button';
import FormInput from '@/components/atoms/FormInput';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import URLS from '@/constants/url';
import useFieldValidation from '@/hooks/useFieldValidation';

const RequestPasswordResetForm: React.FC = () => {
  const { user } = useUser();
  const { requestPasswordReset } = useUserActions();
  const { push } = useRouter();
  const { t } = useTranslation();
  const { yupField } = useFieldValidation();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (user.user) push(URLS.HOMEPAGE);
  }, [user]);

  return (
    <Formik
      initialValues={{ accountCode: '', email: '' }}
      validationSchema={yup.object().shape({
        accountCode: yupField.accountCode,
        email: yupField.email,
      })}
      onSubmit={async (values) => {
        const result = await requestPasswordReset({ ...values, correlationId: 'web' });
        if (result) setIsSent(true);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <ResponseStateAlert categoryStateKey={12} />
          {isSent ? (
            <Alert variant="confirmed">
              <Trans i18nKey="forgotPassword.submitted" />
            </Alert>
          ) : (
            <>
              <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
                <FormInput name="accountCode" onlyNumbers label={t('loginBox.accountCode')} />
                <FormInput name="email" label={t('registration.email')} />
              </div>
              <div className="flex justify-end">
                <Button type="submit" size="big" disabled={isSubmitting}>
                  <Trans i18nKey="button.next" />
                </Button>
              </div>
            </>
          )}
          <FullScreenLoader showLoader={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default RequestPasswordResetForm;
