import ResponseStateAlert from '../atoms/ResponseStateAlert';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useUserActions } from '@web/shop-logic';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import FormInput from '@/components/atoms/FormInput';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import MessagesContext from '@/components/login/MessagesContext';
import ModalContext from '@/components/modal/ModalContext';
import useCaptchaHeader from '@/hooks/useCaptchaHeader';
import useContextSelector from '@/hooks/useContextSelector';
import useFieldValidation from '@/hooks/useFieldValidation';
import { ArrowBack } from '@/icons';

export interface LoginProps {
  backToLoginOptions: () => void;
}

const LoginTicketForm: React.FC<LoginProps> = ({ backToLoginOptions }) => {
  const { loginTicket } = useUserActions();
  const { applyCaptcha } = useCaptchaHeader();
  const { t } = useTranslation();
  const { yupField } = useFieldValidation();
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);
  const pushToMessages = useContextSelector(MessagesContext, (c) => c.pushToMessages);

  return (
    <Formik
      initialValues={{ accountCode: '' }}
      validationSchema={yup.object().shape({ accountCode: yupField.accountCode })}
      onSubmit={async ({ accountCode }) => {
        await applyCaptcha();
        const res = await loginTicket(accountCode);
        if (res?.user) {
          pushToMessages({ text: t('user.success.login.open.ticket') });
        }
        hideAllModals();
      }}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col items-start w-full">
          <span className="font-bold mb-1.5">
            <Trans i18nKey="login.openTicket" />
          </span>
          <ResponseStateAlert categoryStateKey={12} />
          <div className="mb-2 w-full">
            <FormInput
              name="accountCode"
              onlyNumbers
              className="w-full"
              label={t('loginBox.accountCode')}
            />
          </div>
          <div className="text-14 text-neutral-gray mb-4">
            <Trans i18nKey="loginbox.open.ticket.form.info" />{' '}
          </div>
          <div className="flex self-center lg:justify-between sm:flex-col w-full items-center">
            <Button type="submit" size="big" className="sm:w-full" disabled={isSubmitting}>
              <Trans i18nKey="settings.mojeid.login" />
            </Button>
            <ClickableDiv
              onClick={backToLoginOptions}
              className="text-primary-blue flex wrap sm:mt-4 lg:order-first"
            >
              <ArrowBack className="w-3 h-3 fill-current mr-1" />
              <span>
                <Trans i18nKey="loginBox.backOption" />
              </span>
            </ClickableDiv>
          </div>
          <FullScreenLoader showLoader={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default LoginTicketForm;
