// eslint-disable-next-line @typescript-eslint/no-var-requires
const DOMAINS = require('./domains.js');

const generateDomains = (prefix) =>
  DOMAINS.map(({ domain, locale }) => ({
    domain: `${prefix}${domain}`,
    defaultLocale: locale,
  }));

/**
 * Všechno nastavení, které se použije v runtime
 * Configy pro NextJS (použité při buildu) zůstávají v `.env` souborech
 */
const envs = {
  dev: {
    ENVIRONMENT: 'dev',
    RECAPTCHA_KEY: '6LdauogfAAAAAGzuzXjEeGO704rVJOf03br_Iz8N',
    GOOGLE_MAP_API_KEY: '',
    SENTRY_DSN: '',
    DOVOLENACZ: 'https://brn-dev-dv-shop.sa.cz',
    DOVOLENACZ_MIXER: 'https://mixer.dovolena.cz',
    STRAPI_URL: 'https://brn-web-strapi.sa.cz',
    // STRAPI_URL: 'http://localhost:1337',
    // STRAPI_URL: 'https://brn-dev-web-strapi.sa.cz',
    GTM_ID: null,
    PAYMENT_GATE: 'https://dpl-dev-ares-qa.sa.cz/payment/gate/submit?tid=',
    DOMAINS: generateDomains('dev-newshop.'),
    KEYCLOAK_CLIENT_ID: 'web-qa-regiojetcz',
    KEYCLOAK_ISSUER: 'https://brn-qa-cust-keycloak.sa.cz/realms/customers',
  },
  qa: {
    ENVIRONMENT: 'qa',
    RECAPTCHA_KEY: '6LdauogfAAAAAGzuzXjEeGO704rVJOf03br_Iz8N',
    GOOGLE_MAP_API_KEY: 'AIzaSyCWaUEfrOLnoz0kdRUqjkmkiofF8g0BtrY',
    SENTRY_DSN: '',
    DOVOLENACZ: 'https://brn-qa-dv-shop.sa.cz',
    DOVOLENACZ_MIXER: 'https://mixer.dovolena.cz',
    STRAPI_URL: 'https://brn-web-strapi.sa.cz',
    // STRAPI_URL: 'https://brn-qa-web-strapi.sa.cz',
    GTM_ID: 'GTM-KQFMR7D',
    PAYMENT_GATE: 'https://dpl-dev-ares-qa.sa.cz/payment/gate/submit?tid=',
    DOMAINS: generateDomains('qa-newshop.'),
    KEYCLOAK_CLIENT_ID: 'web-qa-regiojetcz',
    KEYCLOAK_ISSUER: 'https://brn-qa-cust-keycloak.sa.cz/realms/customers',
  },
  prod: {
    ENVIRONMENT: 'prod',
    RECAPTCHA_KEY: '6LdwGLQUAAAAAATEgiQmqj4gwjG8VY-ifFAgoNRj',
    GOOGLE_MAP_API_KEY: 'AIzaSyDii0o4DyY97ATKulSrVNZ5urfDb53UiBo',
    SENTRY_DSN: 'https://14cbb839c2af40fc9fa2fca8e512632e@o574523.ingest.sentry.io/5797314',
    DOVOLENACZ: 'https://dovolena.cz',
    DOVOLENACZ_MIXER: 'https://mixer.dovolena.cz',
    STRAPI_URL: 'https://brn-web-strapi.sa.cz',
    GTM_ID: 'GTM-PZ48HW6',
    PAYMENT_GATE: 'https://platba.sa.cz/submit?tid=',
    DOMAINS: generateDomains(''),
    /* Todo: missing prod (also NEXTAUTH_URL=https://example.com .env.production) */
    KEYCLOAK_CLIENT_ID: '',
    KEYCLOAK_ISSUER: '',
  },
};

const envType = process.env.NEXT_PUBLIC_BUILD_TYPE || 'dev';

module.exports = envs[envType];
