import { useCallback } from 'react';
import ModalContext from '@/components/modal/ModalContext';
import { ShowFn } from '@/components/modal/modalTypes';
import useContext from '@/hooks/useContext';

export default function useModal(): { showModal: ShowFn } {
  const { showModal, ...otherContextProps } = useContext(ModalContext);
  const id = Math.random().toString().slice(2, 7);

  const handleShowModal = useCallback(
    (modalId: string): ShowFn =>
      (component, props) =>
        showModal(component, props, { rootId: modalId }),
    [showModal],
  );

  return {
    showModal: handleShowModal(id),
    ...otherContextProps,
  };
}
