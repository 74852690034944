// eslint-disable-next-line @typescript-eslint/no-var-requires
const env = require('./src/constants/env');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'cs',
    // When modify locales, you need also modify them in next.config.js > ContextReplacementPlugin
    locales: ['cs', 'sk', 'de-AT', 'de', 'en', 'hu', 'uk', 'pl'],
    localeDetection: false,
    domains: env.DOMAINS,
  },
  keySeparator: false,
};
