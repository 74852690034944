import React, { ReactNode } from 'react';
import { webalizeString } from '@/utils/dataUtils';

interface LinkableHeaderProps {
  headlineLevel: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
  id?: string;
}

interface TagProps {
  children: ReactNode;
  id?: string;
  className?: string;
}

const mapHeadlineTag = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
};

const LinkableHeader: React.FC<LinkableHeaderProps> = ({
  headlineLevel = 2,
  className,
  id,
  children,
}) => {
  const createAtrributeId = () => {
    if (id) {
      return { id: webalizeString(id) };
    }
    if (typeof children === 'string') {
      return { id: webalizeString(children) };
    }
    return {};
  };

  const Tag: React.FC<TagProps> = mapHeadlineTag[headlineLevel];

  return (
    <Tag className={className} {...createAtrributeId()}>
      {children}
    </Tag>
  );
};

export default LinkableHeader;
