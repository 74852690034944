import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import StrapiImage from '@/components/content/StrapiImage';
import useMobileWidth from '@/hooks/useMobileWidth';
import {
  RjLogoFull,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  StarHoliday,
  PlaneTicket,
  LanguageSchool,
  IconElement,
  Luggage,
} from '@/icons';
import { FooterDataProps } from '@/utils/pagesUtils';

interface FooterLinkProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
  url: string;
}

const FooterLink: React.FC<FooterLinkProps> = ({ text, url }) => (
  <a href={url} className="block py-0.5 mb-1 lg:mb-0.5 text-14 text-neutral-gray hover:underline">
    {text}
  </a>
);

const CopyLinkLarge: React.FC<FooterLinkProps> = ({ text, url }) => (
  <a href={url} className="block py-0.5 text-neutral-gray hover:underline">
    {text}
  </a>
);

const CopyLinkSmall: React.FC<FooterLinkProps> = ({ text, url, className }) => (
  <a
    href={url}
    className={classNames(
      'inline sm:mr-0.5 py-0.5 text-12 text-neutral-gray hover:underline break-words',
      className,
    )}
  >
    {text}
  </a>
);

interface SocialLinkProps extends React.HTMLProps<HTMLDivElement> {
  Icon: IconElement;
  url: string;
}
export const SocialLink: React.FC<SocialLinkProps> = ({ Icon, url }) => (
  <a href={url} className="hover:text-neutral-black transition-colors" aria-label={Icon.name}>
    <Icon className="w-3 h-3 fill-current" />
  </a>
);

interface FooterProps {
  footerData: FooterDataProps;
}

const Footer: React.FC<FooterProps> = ({ footerData }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileWidth();

  if (!footerData) return null;

  const { images } = footerData.footerMobileappsData;
  const { column, contact, contactTitle, mobileappsImage, mobileappsTitle } =
    footerData.menuFooterData;

  /**
   *   TODO: jakmile nahradíme images v Menu-Footer smažeme footerMobileAppData
   */
  const fallbackPaymentImg = images?.length
    ? { title: t('footer.mobileapp.header'), images }
    : { title: mobileappsTitle, images: mobileappsImage };

  return (
    <footer role="contentinfo">
      {/**
       * Patička: Odkazy na šedém pozadí
       */}
      <div className="py-5 bg-neutral-graylight">
        <div className="page-layout grid grid-cols-1 lg:grid-cols-4 gap-2">
          {/* Sloupec: Důležité odkazy */}
          {(column || []).map(({ title, item, id }) => (
            <div key={id}>
              <h2 className="h4 mb-2">{title}</h2>
              {/* Pokud nemáme title dorovnáváme výšku sloupců */}
              <nav className={classNames(!title && 'mt-5 pt-0.5')}>
                {(item || []).map(({ page_template, page, title: linkTitle, url, id: linkId }) => {
                  const content = page_template?.title || page?.title || linkTitle || '';
                  const hrefUrl = page_template?.generatedUrl || page?.generatedUrl || url || '';
                  return <FooterLink key={linkId} text={content} url={hrefUrl} />;
                })}
              </nav>
            </div>
          ))}
          {/* Sloupec: Aplikace RegioJet */}
          <div>
            {images?.length && (
              <>
                <h2 className="h4 mb-2">{fallbackPaymentImg.title}</h2>

                <div className="mb-5 lg:mb-3 grid lg:grid-cols-2 grid-cols-4 gap-1">
                  {Object.values(fallbackPaymentImg.images).map(({ id, url, image, ariaLabel }) => (
                    <a key={id} href={url} aria-label={ariaLabel}>
                      <StrapiImage
                        className="max-w"
                        alt={ariaLabel || image?.name || ''}
                        image={image}
                        fitCover={false}
                      />
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
          {/* Sloupec: Kontakt */}
          <div>
            <h2 className="h4 mb-2">{contactTitle}</h2>
            <div className="text-14 text-neutral-gray">
              {(contact || []).map(({ about, email, emailInfo, phone, id }) => (
                <React.Fragment key={id}>
                  <div>{about}</div>
                  <a href={`tel:${phone}`} className="lg:hidden">
                    {phone}
                  </a>
                  <span className="sm:hidden">{phone}</span>
                  <div className="my-2">
                    {emailInfo}{' '}
                    <a href={`mailto:${email}`} className="hover:underline">
                      {email}
                    </a>
                  </div>
                </React.Fragment>
              ))}
              <div className="flex gap-3 lg:gap-1.5">
                <SocialLink Icon={Facebook} url="https://www.facebook.com/regiojet" />
                <SocialLink Icon={Twitter} url="https://twitter.com/regiojet" />
                <SocialLink Icon={Instagram} url="https://instagram.com/regiojet" />
                <SocialLink Icon={Linkedin} url="https://cz.linkedin.com/company/regiojet-a-s-" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Patička: Copyright na bílém pozadí */}
      <div className="page-layout my-3">
        {/* Řádek: Logo, odkazy na Letenky apod. */}
        <div className="mb-2 flex items-center lg:gap-4">
          <RjLogoFull className="w-32" />
          {!isMobile && (
            <nav className="flex items-center lg:gap-4">
              <CopyLinkLarge text={t('samenu.11')} url={t('top.menu.href.air.tickets')} />
              <CopyLinkLarge text={t('samenu.13')} url={t('top.menu.href.vacation')} />
              <CopyLinkLarge
                text={t('top.menu.lang.accommodation')}
                url={t('top.menu.href.lang.accommodation')}
              />
              <CopyLinkLarge text={t('regiojet.hotels')} url={t('partyja.href')} />
            </nav>
          )}
        </div>
        {isMobile && (
          <nav className="mb-2 flex items-center justify-between text-neutral-gray2">
            <SocialLink Icon={PlaneTicket} url={t('top.menu.href.air.tickets')} />
            <SocialLink Icon={StarHoliday} url={t('top.menu.href.vacation')} />
            <SocialLink Icon={LanguageSchool} url={t('top.menu.href.lang.accommodation')} />
            <SocialLink Icon={Luggage} url={t('partyja.href')} />
          </nav>
        )}
        {/* Řádek: Copyright text */}
        <div className="sm:mb-1 text-12 text-neutral-gray">
          <Trans i18nKey="footer.copyright" />
        </div>
        {/**
         * Řádek: Odkazy na Obchodní podmínky apod.
         * Víceslovný odkaz se zalamuje na více řádku => display inline
         * Zalamování mezi jednotlivými inline odkatzy => přidána mezera mezi odkazy
         */}
        <nav className="mb-1.5 lg:mb-0.5 lg:flex lg:flex-wrap lg:items-center sm:gap-x-1 lg:gap-4">
          <CopyLinkSmall
            text={t('footer.links.conditions')}
            url={t('ticket.passengerModal.info.transportRules')}
          />{' '}
          <CopyLinkSmall text={t('footer.cookies.header')} url={t('footer.cookies.href')} />{' '}
          <CopyLinkSmall
            text={t('createAccount.personalDataProcessingConsent')}
            url={t('ticket.passengerModal.info.conditionsUrl')}
          />{' '}
          <CopyLinkSmall
            text={t('footer.accessibility.header')}
            url={t('footer.accessibility.href')}
          />
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
