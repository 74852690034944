import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHeadersActions } from '@web/shop-logic';

interface CaptchaHeaderProps {
  applyCaptcha: () => Promise<void>;
}

const useCaptchaHeader = (): CaptchaHeaderProps => {
  const { setCaptcha } = useHeadersActions();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return {
    applyCaptcha: async () => {
      const captchaCode = await executeRecaptcha();
      setCaptcha(captchaCode);
    },
  };
};

export default useCaptchaHeader;
